/*
----------------------------------------------------
* Template Name    : Biolife - Organic Food        *
* Author           : Kutetheme                     *
* Version          : 1.0.0                         *
* Created          : August 2019                   *
* File Description : Main CSS file of the template *
*---------------------------------------------------

--------------- Table of Content -------------------

    1. Header Templates
        -Layout 01
        -Layout 02
        -Layout 03
        -Layout 04
        -Layout 05

    2. Footer Templates
        -Layout 01
        -Layout 02
        -Layout 03

    3. Home Pages
        -Home 01
        -Home 02
        -Home 03
        -Home 03 Green
        -Home 04
        -Home 04 Light
        -Home 05

    4. Blog Post
        -Left Sidebar
        -Right Sidebar

    5. Blog
        -Blog Style 01 Left Sidebar
        -Blog Style 01 Right Sidebar
        -Blog Style 02
        -Blog Style 03 Left Sidebar
        -Blog Style 03 Right Sidebar

    6. Category
        -Grid Style Left Sidebar
        -Grid Style Right Sidebar
        -List Style Left Sidebar
        -List Style Right Sidebar

    7. Single Product
    8. Login Page
    9. Shopping Cart
    10. About Us
    11. Contact Us
    13. Check Out
    14. Newsletter Template Popup
    15. Quick View Template

----------------------------------------------------
*/

/************************
* Global Settings       *
*************************/
body{
    font-family: 'Cairo', sans-serif;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    color: #666666;
    overflow-x: hidden;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}
label{
    font-weight: 400;
    color: #333333;
}
label .required{
    color: #ff2727;
    border-bottom: none;
    text-decoration: none;
}
img{
    max-width: 100%;
    height: auto;
    outline: none;
}
figure{
    max-width: 100%;
}
input:focus{
    outline: none;
}
a:hover, a:focus, a:visited, a:active,
button:hover, button:focus, button:visited, button:active{
    text-decoration: none;
    outline: none;
}
table {
    border: 1px solid #E6E6E6;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: auto;
    width: 100%;
    margin-bottom: 20px;
}
th,td{
    border: 1px solid #e6e6e6;
    padding: 10px 5px;
    font-weight: normal;
    text-align: center;
}
textarea{
    display: block;
}
input[type="text"],
input[type="email"],
textarea,
input[type="password"],
input[type="tel"],
select,
input[type="search"]{
    border: 1px solid #e6e6e6;
    border-radius: 0;
    padding: 7px 20px;
    max-width: 100%;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type="text"]::-ms-input-placeholder,
input[type="email"]::-ms-input-placeholder,
input[type="password"]::-ms-input-placeholder,
input[type="tel"]::-ms-input-placeholder,
input[type="search"]::-ms-input-placeholder{
    color: #888888;
}
input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder{
    color: #888888;
}
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="tel"]::placeholder,
input[type="search"]::placeholder{
    color: #888888;
}
select{
    background: transparent;
    border: none;
    color: #ffffff;
}
select option{
    color: #333333;
}
.nice-select{
    background-color: transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: none;
    padding: 0 14px;
    font-size: 14px;
    line-height: 1;
    min-height: auto;
    height: auto;
}
.nice-select:after{
    content: "\f0d7";
    font-family: 'FontAwesome', sans-serif;
    font-size: 12px;
    color: #ffffff;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    border: none;
    height: auto;
    width: auto;
    right: 1px;
    margin-top: -6px;
    line-height: 1;
    padding: 0;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}
.nice-select.open:after{
    -webkit-transform: rotate( -180deg );
    -ms-transform: rotate( -180deg );
    transform: rotate( -180deg );
}
.nice-select .current{
    color: #ffffff;
    display: inline-block;
    padding: 14px 0;
}
.nice-select .list{
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    border: 1px solid #e4e4e4;
    padding: 3px 23px 10px 15px;
    margin-top: 0;
    z-index: 50;
}
.nice-select .option{
    font-size: 14px;
    line-height: 1;
    color: #888888;
    height: auto;
    min-height: auto;
    margin: 16px 0;
    padding: 0;
}
.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus{
    background-color: transparent;
    font-weight: 400;
}
/* BIOLIFE ICON */
@font-face {
    font-family: 'biolife';
    src: url('../fonts/biolifebbd0.eot?t4lhqt');
    src: url('../fonts/biolifebbd0.eot?t4lhqt#iefix') format('embedded-opentype'),
         url('../fonts/biolifebbd0.ttf?t4lhqt') format('truetype'),
         url('../fonts/biolifebbd0.woff?t4lhqt') format('woff'),
         url('../fonts/biolifebbd0.svg?t4lhqt#biolife') format('svg');
    font-weight: normal;
    font-style: normal;
}

.biolife-icon{
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'biolife', sans-serif !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
    content: "\e900";
}
.icon-arteries-about:before {
    content: "\e901";
}
.icon-capacity-about:before {
    content: "\e902";
}
.icon-cart:before {
    content: "\e903";
}
.icon-cart-mini:before {
    content: "\e904";
}
.icon-close-menu:before {
    content: "\e905";
}
.icon-comment:before {
    content: "\e906";
}
.icon-clock:before {
    content: "\e907";
}
.icon-location:before {
    content: "\e908";
}
.icon-letter:before {
    content: "\e909";
}
.icon-phone:before {
    content: "\e90a";
}
.icon-fresh-drink:before {
    content: "\e90b";
}
.icon-green-safety:before {
    content: "\e90c";
}
.icon-grid:before {
    content: "\e90d";
}
.icon-healthy-about:before {
    content: "\e90e";
}
.icon-heart-bold:before {
    content: "\e90f";
}
.icon-left-arrow:before {
    content: "\e910";
}
.icon-like:before {
    content: "\e911";
}
.icon-list:before {
    content: "\e912";
}
.icon-login:before {
    content: "\e913";
}
.icon-location2:before {
    content: "\e914";
}
.icon-fruits:before {
    content: "\e915";
}
.icon-broccoli-1:before {
    content: "\e916";
}
.icon-beef:before {
    content: "\e917";
}
.icon-fish:before {
    content: "\e918";
}
.icon-fast-food:before {
    content: "\e919";
}
.icon-honey:before {
    content: "\e91a";
}
.icon-grape:before {
    content: "\e91b";
}
.icon-onions:before {
    content: "\e91c";
}
.icon-avocado:before {
    content: "\e91d";
}
.icon-contain:before {
    content: "\e91e";
}
.icon-fresh-juice:before {
    content: "\e91f";
}
.icon-newsletter:before {
    content: "\e920";
}
.icon-organic:before {
    content: "\e921";
}
.icon-beer:before {
    content: "\e922";
}
.icon-car:before {
    content: "\e923";
}
.icon-schedule:before {
    content: "\e924";
}
.icon-conversation:before {
    content: "\e925";
}
.icon-heart-1:before {
    content: "\e926";
}
.icon-title:before {
    content: "\e927";
}
.icon-head-phone:before {
    content: "\e928";
}
.icon-report:before {
    content: "\e929";
}
.icon-search:before {
    content: "\e92a";
}
.icon-suporter:before {
    content: "\e92b";
}
.icon-grape2:before {
    content: "\e92c";
}
.icon-broccoli:before {
    content: "\e92d";
}
.icon-lemon:before {
    content: "\e92e";
}
.icon-orange:before {
    content: "\e92f";
}
.icon-blueberry:before {
    content: "\e930";
}
/******************************************
* Animation Effects                       *
*******************************************/
@-webkit-keyframes move-around-reverse{
    0% { -webkit-transform: translate( 0, -10px); }
    25% { -webkit-transform: translate( -10px, 0); }
    50% { -webkit-transform: translate( 0, 10px); }
    75% { -webkit-transform: translate( 10px, 0); }
    100% { -webkit-transform: translate( 0, -10px); }
}
@keyframes move-around-reverse{
    0% { transform: translate( 0, -10px); }
    25% { transform: translate( -10px, 0); }
    50% { transform: translate( 0, 10px); }
    75% { transform: translate( 10px, 0); }
    100% { transform: translate( 0, -10px); }
}
@-webkit-keyframes move-around{
    0% { -webkit-transform: translate( 0, -10px); }
    25% { -webkit-transform: translate( 10px, 0); }
    50% { -webkit-transform: translate( 0, 10px); }
    75% { -webkit-transform: translate( -10px, 0); }
    100% { -webkit-transform: translate( 0, -10px); }
}
@keyframes move-around{
    0% { transform: translate( 0, -10px); }
    25% { transform: translate( 10px, 0); }
    50% { transform: translate( 0, 10px); }
    75% { transform: translate( -10px, 0); }
    100% { transform: translate( 0, -10px); }
}
@-webkit-keyframes movebounce{
    0% { -webkit-transform: translateY(0); }
    50% { -webkit-transform: translateY(20px); }
    100% { -webkit-transform: translateY(0); }
}
@keyframes movebounce{
    0% { transform: translateY(0); }
    50% { transform: translateY(20px);}
    100% { transform: translateY(0); }
}
@-webkit-keyframes movebounce-x{
    0% { -webkit-transform: translateX(0); }
    50% { -webkit-transform: translateX(20px); }
    100% { -webkit-transform: translateX(0); }
}
@keyframes movebounce-x{
    0% { transform: translateX(0); }
    50% { transform: translateX(20px); }
    100% { transform: translateX(0); }
}
@-webkit-keyframes preload_waiting{
    75% { -webkit-transform: scale(0); }
}
@keyframes preload_waiting{
    75% { transform: scale(0); }
}
/******************************************
* Pre Loading                             *
*******************************************/
body{
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}
#biof-loading{
    background-color: #F2F1EF;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 9999999;
    margin-top: 0;
    top: 0;
}
#biof-loading .biof-loading-center{
    width: 100%;
    height: 100%;
    position: relative;
}
#biof-loading .biof-loading-center-absolute{
    position: absolute;
    left: 50%;
    top: 50%;
    height: 150px;
    width: 150px;
    margin-top: -75px;
    margin-left: -75px;
}
.biof-loading-center-absolute .dot{
    width: 20px;
    height: 20px;
    background-color: #05a503;
    float: left;
    margin-right: 20px;
    margin-top: 65px;
    -moz-border-radius: 50% 50% 50% 50%;
    -webkit-border-radius: 50% 50% 50% 50%;
    border-radius: 50% 50% 50% 50%;
}

.biof-loading-center-absolute .dot-one {
    -webkit-animation: preload_waiting 1.5s infinite;
    animation: preload_waiting 1.5s infinite;
}
.biof-loading-center-absolute .dot-two{
    -webkit-animation: preload_waiting 1.5s infinite;
    animation: preload_waiting 1.5s infinite;
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
}
.biof-loading-center-absolute .dot-three {
    -webkit-animation: preload_waiting 1.5s infinite;
    animation: preload_waiting 1.5s infinite;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}
/*********************/
.btn-scroll-top{
    color: #333333;
    border-radius: 50%;
    cursor: pointer;
    font-size: 50px;
    display: block;
    text-align: center;
    line-height: 50px;
    position: fixed;
    bottom: 60px;
    right: 60px;
    height: 50px;
    width: 50px;
    z-index: 999;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    -webkit-transition:0.4s;
    -o-transition:0.4s;
    transition:0.4s;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}
.btn-scroll-top i{
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    display: block;
}
@media only screen and (max-width:767.98px){
    .btn-scroll-top{
        line-height:40px;
        height:40px;
        width:40px;
        font-size: 40px;
        bottom: 80px;
        right: 30px;
    }
}
.btn-scroll-top.showUp{
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
}
/*********************
* Menu Mobile        *
**********************/
.biolife-clone-wrap{
    position: fixed;
    top: 0;
    left: -340px;
    width: 320px;
    height: 100vh;
    background-color: #fff;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    z-index: 100000;
    -webkit-box-shadow: 3px 5px 14px -3px rgba(0,0,0,0.11);
    -moz-box-shadow: 3px 5px 14px -3px rgba(0,0,0,0.11);
    box-shadow: 3px 5px 14px -3px rgba(0,0,0,0.11);
    overflow-x: hidden;
}
.biolife-clone-wrap .megamenu-item li{
    line-height: 50px;
}
.biolife-clone-wrap .megamenu-item .widget{
    padding: 0 0 30px 0;
}
.biolife-clone-wrap .megamenu-item li.menu-item > a{
    display: inline-block;
    line-height: 50px;
}
.biolife-clone-wrap .megamenu-item li .icon{
    right: -4px;
    top: -9px;
    float: right;
    position: relative;
}
.biolife-clone-wrap .megamenu-item .menu-title{
    font-size: 14px;
    font-weight: 400;
}
.biolife-clone-wrap .megamenu-item .menu-title,
.biolife-clone-wrap .megamenu-item .wrap-media{
    margin-bottom: 0;
    padding-left: 20px;
}
.biolife-panel ul{
    padding:0;
    margin:0;
}
.biolife-panel>ul>li{
    list-style: none;
    position: relative;
    padding:0 20px;
    border-bottom: 1px dashed #dddddd;
}
.biolife-mobile-panels .biolife-prev-panel,
.biolife-panels-actions-wrap .biolife-prev-panel {
    right: inherit;
    left: 0;
    border: none;
    border-right: 1px solid #dddddd;
}
.biolife-mobile-panels .biolife-prev-panel:before,
.biolife-panels-actions-wrap .biolife-prev-panel:before{
    content: "\f104";
    font-family: 'FontAwesome', sans-serif;
}
.biolife-mobile-panels .biolife-current-panel-title,
.biolife-panels-actions-wrap .biolife-current-panel-title{
    display: block;
    text-align: center;
    line-height: 50px;
    padding: 0 50px;
    font-weight: 600;
    color: #333333;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.biolife-clone-wrap .biolife-panels {
    background: inherit;
    border-color: inherit;
    position: absolute;
    margin-top: 50px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    box-sizing: border-box;
}
.biolife-next-panel {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
}
.biolife-next-panel:before {
    content: "\f105";
    font-family: 'FontAwesome', sans-serif;
}
.biolife-panel>ul>li>a{
    display: inline-block;
    line-height: 50px;
    text-transform: capitalize;
    color: #333333;
}
.biolife-panel>ul>li>a>i{
    display: none;
}
.biolife-panel>ul>li>a>.icon{
    right: -4px;
    top: -9px;
    float: right;
    position: relative;
}
.biolife-clone-wrap .biolife-panel {
    border-color: #ffffff;
    bottom: 0;
    box-sizing: border-box;
    display: block;
    left: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: all 0.2s ease 0s;
    z-index: 0;
}
.biolife-clone-wrap .biolife-main-panel,
.biolife-clone-wrap .biolife-panel.biolife-panel-opened {
    transform: translateX(0);
    z-index: 10;
}
.biolife-clone-wrap .biolife-sub-opened {
    z-index: 1;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
}
.biolife-mobile-panels,
.biolife-panels-actions-wrap {
    border-bottom: 1px solid #dddddd;
    height: 50px;
    position: relative;
}
.biolife-mobile-panels .biolife-close-btn,
.biolife-mobile-panels .biolife-prev-panel,
.biolife-panels-actions-wrap .biolife-close-btn,
.biolife-panels-actions-wrap .biolife-prev-panel{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    color: #333333;
}
.biolife-mobile-panels .biolife-close-btn,
.biolife-panels-actions-wrap .biolife-close-btn{
    border-left: 1px solid #dddddd;
}
.biolife-panel .mega-content{
    padding: 25px 0;
    display: inline-block;
    width: 100%;
}
.hero-background{
    background-image: url('../images/hero_bg.jpg');
    height: 194px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.hero-section{
    display: block;
    text-align: center;
    position: relative;
    font-size: 0;
    line-height: 1;
    margin-top: 20px;
}
.hero-section .page-title{
    display: inline-block;
    position: absolute;
    top: 29%;
    left: 0;
    right: 0;
    font-size: 40px;
    color: #ffffff;
    font-weight: 700;
    line-height: 50px;
    margin: 0;
}
.biolife-nav{
    display: block;
    margin-top: 37px;
    margin-bottom: 38px;
}
.biolife-nav ul{
    padding: 0;
    display: block;
    margin: 0;
    font-size: 0;
    line-height: 0;
}
.biolife-nav ul li{
    list-style: none;
    display: inline-block;
}
.biolife-nav ul li + li::before{
    content: '/';
    font-family: inherit;
    color: #666666;
    font-size: 15px;
    line-height: 1;
    display: inline-block;
    margin-left: 13px;
    margin-right: 14px;
}
.biolife-nav ul li span,
.biolife-nav ul li a{
    color: #666666;
    font-size: 15px;
    line-height: 1;
    display: inline-block;
}
.biolife-nav ul li:last-child::before,
.biolife-nav ul li:last-child span{
    color: #222222;
    font-weight: 600;
}
/**************************************/
.biolife-carousel{
    padding: 0;
    margin: 0;
}
.biolife-carousel>li{
    list-style: none;
}
.biolife-carousel>li:not(:first-child){
    display: none;
}
.slick-initialized .slick-slide{
    outline: none;
}
.biolife-carousel .row-item{
    font-size: 0;
    line-height: 0;
}
.biolife-carousel.row-space-29px .row-item+.row-item{
    margin-top: 29px;
}
.slick-initialized .slick-arrow:not(.slick-disabled):hover{
    cursor: pointer;
}
.slick-initialized .slick-arrow:not(.slick-disabled){
    opacity: 0.8;
    filter: alpha(opacity=80);
}
@media (min-width: 769px) {
    .container {
        padding: 0;
    }
}
.header-top .top-bar{
    max-width: 50%;
    float: left;
}
.header-top .right{
    float: right;
    text-align: right;
}
.header-top .top-bar .social-list,
.header-top .top-bar .horizontal-menu{
    padding: 0;
    margin: 0;
    display: inline-block;
    float: left;
}
.header-top .top-bar .social-list>li,
.header-top .top-bar .horizontal-menu>li{
    list-style: none;
    display: inline-block;
}
.header-top .top-bar .horizontal-menu a{
    color: #ffffff;
    font-size: 14px;
}
.header-top .top-bar .horizontal-menu a:hover{
    color: #dddddd;
}
.header-top .left a>i{
    font-size: 15px;
    margin-right: 6px;
}
.header-top .left .horizontal-menu>li{
    padding: 9px 19px;
    border-left: 1px solid rgba(255,255,255, 0.33);
}
.header-top .left .horizontal-menu>li:first-child{
    padding-left: 0;
    border-left: 0;
}
.header-top .right .horizontal-menu{
    font-size: 0;
}
.header-top .right .horizontal-menu>li{
    vertical-align: middle;
    min-width: 140px;
    padding: 0 20px;
    border-right: 1px solid rgba(255,255,255,0.33);
}
.header-top .right .horizontal-menu>li:first-child{
    border-left: 1px solid rgba(255,255,255,0.33);
}
.header-top .right .horizontal-menu>li>a{
    display: inline-block;
    line-height: 42px;
}
.header-top .right .nice-select{
    padding-left: 0;
    display: inline-block;
    float: initial;
}
.header-top .right .nice-select .list{
    margin-left: -14px;
}
.header-top .top-bar .social-list{
    font-size: 0;
    display: inline-block;
    float: left;
    margin-right: 7px;
}
.header-top .top-bar .social-list li{
    font-size: 15px;
    line-height: 42px;
    margin: 0 13px;
}
.header-top .top-bar .social-list li a{
    font-size: 15px;
    line-height: 1;
    display: inline-block;
    color: #ffffff;
}
.header-top .top-bar .social-list li a:hover{
    color: #dddddd;
}
.login-link .biolife-icon{
    font-size: 21px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    margin-top: -5px;
}
.header-area .header-middle{
    padding: 30px 0 21px;
}
.header-area .primary-menu{
    margin-top: 8px;
}
.header-area .primary-menu>ul{
    padding: 0;
    margin: 0;
    font-size: 0;
    position: relative;
}
.header-area .primary-menu>ul>li{
    display: inline-block;
    padding-bottom: 5px;
}
.header-area .primary-menu>ul>li+li{
    margin-left: 31px;
}
.header-area .primary-menu>ul li.has-child{
    position: relative;
}
.header-area .primary-menu>ul>li.has-child>a::after{
    content: "\f0d7";
    font-family: 'FontAwesome', sans-serif;
    font-size: 14px;
    color: #222222;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    margin-left: 4px;
}
.header-area .primary-menu>ul>li>a{
    color: #222222;
    font-size: 17px;
    font-weight: 600;
    display: inline-block;
}
.header-area .primary-menu>ul>li.has-child>a::after,
.header-area .primary-menu>ul>li.has-megamenu>a::after{
    content: '\f0d7';
    font-family: 'FontAwesome', sans-serif;
    font-size: 14px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    margin-left: 4px;
}
.header-area .primary-menu ul li{
    display: inline-block;
}
.vertical-category-block .wrap-menu .wrap-megamenu,
.vertical-category-block .wrap-menu ul.sub-menu,
.header-area .primary-menu ul.sub-menu,
.header-area .primary-menu ul .wrap-megamenu{
    background-color: #ffffff;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    -webkit-transform: translateY( 20px);
    -moz-transform: translateY( 20px);
    -ms-transform: translateY( 20px);
    -o-transform: translateY( 20px);
    transform: translateY( 20px);
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    border: none;
    border-radius: 0;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    z-index: 100;
}
.vertical-category-block .wrap-menu li:hover .wrap-megamenu,
.vertical-category-block .wrap-menu li:hover>ul.sub-menu,
.header-area .primary-menu li:hover>ul.sub-menu,
.header-area .primary-menu ul li:hover .wrap-megamenu{
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
}
.header-area .primary-menu ul.sub-menu{
    width: 225px;
    padding: 10px 0;
    left: 100%;
    top: 0;
}
.header-area .primary-menu>ul>li>ul.sub-menu{
    top: 100%;
    left: 0;
}
.header-area .primary-menu>ul>li li.has-child::after{
    content: '\f105';
    font-family: 'FontAwesome', sans-serif;
    font-size: 17px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    position: absolute;
    top: 8px;
    right: 9px;
}
.header-area .primary-menu>ul>li.has-child .sub-menu li{
    display: block;
    font-size: 14px;
    line-height: 1;
    margin: 0;
    padding: 10px 20px;
}
.header-area .primary-menu>ul>li.has-child .sub-menu a{
    color: #222222;
    display: inline-block;
}
.header-area .header-bottom{
    padding-bottom: 10px;
}
body.open-mobile-search .header-area.layout-02{
    z-index: 150;
}
.header-area.layout-02 .header-top{
    background-color: #ffffff;
}
.header-area.layout-02 .header-top .nice-select .current,
.header-area.layout-02 .header-top .nice-select:after,
.header-area.layout-02 .header-top .top-bar .social-list li a,
.header-area.layout-02 .header-top .top-bar .horizontal-menu a{
    color: #333333;
}
.header-area.layout-02 .header-top .left .horizontal-menu>li,
.header-area.layout-02 .header-top .right .social-list{
    position: relative;
}
.header-area.layout-02 .header-top .right .horizontal-menu>li{
    min-width: auto;
    position: relative;
    border: none !important;
}
.header-area.layout-02 .header-top .right .horizontal-menu>li.item-link{
    padding: 0;
}
.header-area.layout-02 .header-top .left .horizontal-menu>li:not(:last-child)::after,
.header-area.layout-02 .header-top .right .social-list::after,
.header-area.layout-02 .header-top .right .horizontal-menu>li::after{
    content: '';
    display: block;
    position: absolute;
    top: 12px;
    right: -1px;
    z-index: 2;
    height: 18px;
    width: 1px;
    background-color: #e5e5e5;
}
.header-area.layout-02 .header-top .right .dsktp-open-searchbox{
    font-size: 20px;
    padding: 0 20px;
}
.header-area.layout-02{
    position: relative;
    z-index: 15;
}
.header-area.layout-02 .header-middle:not(.pre-sticky){
    background-color: rgba(255,255,255,0.1);
    padding: 24px 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
}
.header-area.layout-02 .header-middle.pre-sticky{
    background-color: #ffb71a;
    visibility: hidden;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}
.header-area.layout-02 .header-middle.is-sticky{
    visibility: visible;
    -webkit-transition: all 1200ms ease;
    -moz-transition: all 1200ms ease;
    -o-transition: all 1200ms ease;
    transition: all 1200ms ease;
}
.header-area.layout-02 .primary-menu>ul>li>a{
    font-size: 15px;
    text-transform: uppercase;
    color: #ffffff;
    position: relative;
}
#biolife-main-panel a.menu-name{
    position: relative;
}
#biolife-main-panel .menu-name .sup-item,
.header-area.layout-02 .primary-menu>ul>li>a .sup-item{
    display: inline-block;
    position: absolute;
    font-size: 0;
    line-height: 0;
    bottom: 100%;
    right: 0;
    margin-bottom: 7px;
}
#biolife-main-panel .menu-name .sup-item{
    right: auto;
    bottom: auto;
    top: 0;
    left: calc(100% - 10px);
    margin-bottom: 0;
}
#biolife-main-panel .menu-name .sup-item>span,
.header-area.layout-02 .primary-menu>ul>li>a span{
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    background-color: #43c172;
    border-color: #43c172;
    border-radius: 3px;
    min-width: 40px;
    text-align: center;
    padding: 0 3px;
    line-height: 18px;
    color: #ffffff;
    position: relative;
    margin: 0;
}
#biolife-main-panel .menu-name .sup-item>span.sale,
.header-area.layout-02 .primary-menu>ul>li>a span.sale{
    background-color: #ffc356;
    border-color: #ffc356;
}
#biolife-main-panel .menu-name .sup-item>span.feature,
.header-area.layout-02 .primary-menu>ul>li>a span.feature{
    background-color: #0059ff;
    border-color: #0059ff;
}
#biolife-main-panel .menu-name span::before,
.header-area.layout-02 .primary-menu>ul>li>a span::before{
    display: block;
    content: '';
    border: 7px solid transparent;
    border-top-color: inherit;
    border-bottom: none;
    position: absolute;
    bottom: -6px;
    right: 50%;
    margin-right: -8px;
}
.header-area.layout-02 .biolife-sticky-object.pre-sticky .primary-menu>ul>li>a .sup-item{
    display: none;
}
#biolife-main-panel .menu-name span::before{
    bottom: -5px;
    right: 100%;
    margin-right: -19px;
}
.header-area .biolife-cart-info .login-item .login-link{
    font-size: 14px;
    line-height: 1;
    color: #ffffff;
    font-weight: 600;
    text-transform: uppercase;
}
.header-area.layout-02 .primary-menu>ul>li.has-child>a::after{
    color: inherit;
}
.header-area.layout-02 .biolife-cart-info .minicart-block .cart-inner{
    border-top-color: #05a503;
}
.header-area.layout-02 .biolife-cart-info .minicart-block .btn-control .view-cart:not(:hover){
    background-color: #05a503;
}
.header-area.layout-02 .mobile-search .open-searchbox .biolife-icon,
.header-area.layout-02 .biolife-cart-info .minicart-contain a>.sub-total,
.header-area.layout-02 .biolife-cart-info .minicart-contain a>.title,
.header-area.layout-02 .biolife-cart-info .icon-qty-combine .biolife-icon{
    color: #ffffff;
}
.biolife-cart-info{
    font-size: 0;
    text-align: right;
    margin-top: 8px;
}
.biolife-cart-info>div{
    display: inline-block;
}
.biolife-cart-info>div+div{
    margin-left: 29px;
}
.biolife-cart-info>div>.link-to{
    display: inline-block;
    line-height: 0;
}
.biolife-cart-info .icon-qty-combine{
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 1;
    margin-right: 7px;
}
.biolife-cart-info .icon-qty-combine .qty{
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    display: inline-block;
    margin: 0;
    min-width: 19px;
    padding: 1px 5px 2px;
    color: #ffffff;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top: 7px;
    z-index: 2;
}
.biolife-cart-info .icon-qty-combine .biolife-icon{
    font-size: 20px;
    color: #333333;
}
.biolife-cart-info .minicart-contain a>.title{
    font-size: 14px;
    line-height: 16px;
    display: inline;
    color: #555555;
    font-weight: 600;
    margin-left: 6px;
}
.biolife-cart-info .minicart-contain a>.sub-total{
    font-size: 15px;
    line-height: 16px;
    display: inline;
    color: #222222;
    font-weight: 700;
}
.biolife-cart-info .minicart-block{
    position: relative;
}
.biolife-cart-info .minicart-block .cart-content{
    position: absolute;
    display: inline-block;
    line-height: 0;
    width: 370px;
    top: 100%;
    right: 0;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: 50;
}
.biolife-cart-info .minicart-block:hover .cart-content{
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
}
.biolife-cart-info .minicart-block .cart-inner{
    border-top: 3px solid #cccccc;
    background-color: #ffffff;
    display: inline-block;
    padding: 16px 10px 30px 20px;
    -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    border-radius: 0;
    line-height: 0;
    width: 370px;
    margin-top: 9px;
}
.biolife-cart-info .minicart-block ul.products{
    padding: 0;
    margin: 0;
    text-align: left;
    border-bottom: 1px dashed #e6e6e6;
    max-height: 371px;
    overflow-y: auto;
}
.biolife-cart-info .minicart-block ul.products li{
    padding-right: 10px;
}
.biolife-cart-info .minicart-block ul.products li+li .minicart-item{
    border-top: 1px dashed #e6e6e6;
    padding-top: 19px;
}
.biolife-cart-info .minicart-block ul.products li+li .minicart-item .action{
    top: 23px;
}
.biolife-cart-info .minicart-block ul.products li:last-child .minicart-item{
    padding-bottom: 30px;
}
.biolife-cart-info .minicart-block .minicart-item{
    display: inline-block;
    padding-bottom: 20px;
    position: relative;
    width: 100%;
}
.biolife-cart-info .minicart-item .thumb{
    display: inline-block;
    width: 90px;
    float: left;
}
.biolife-cart-info .minicart-item .left-info{
    display: inline-block;
    width: calc( 100% - 90px );
    padding-left: 9px;
    padding-right: 40px;
}
.minicart-item .left-info .product-title{
    font-size: 17px;
    line-height: 1;
    display: block;
    margin-top: 8px;
    font-weight: 600;
}
.minicart-item .left-info .product-name{
    color: #333333;
    display: inline-block;
}
.price-contain,
.sumary-product .product-attribute .price,
.contain-product .info .price,
.minicart-item .left-info .price{
    display: block;
    font-size: 0;
    line-height: 1;
    margin-top: 6px;
}
.price-contain .price-amount,
.sumary-product .product-attribute .price .price-amount,
.contain-product .info .price .price-amount,
.minicart-item .left-info .price .price-amount{
    line-height: 24px;
    color: #aaaaaa;
    font-size: 14px;
    display: inline-block;
    font-weight: 600;
    text-decoration: none;
}
.price-contain ins .price-amount,
.sumary-product .product-attribute .price ins .price-amount,
.contain-product .info .price ins .price-amount,
.minicart-item .left-info .price ins .price-amount{
    font-size: 18px;
    color: #111111;
    font-weight: 700;
}
.price-contain ins,
.sumary-product .product-attribute .price ins,
.sumary-product .product-attribute .price del,
.contain-product .info .price ins,
.contain-product .info .price del,
.minicart-item .left-info .price ins,
.minicart-item .left-info .price del{
    display: inline-block;
    text-decoration: none;
}
.price-contain del{
    position: relative;
    margin-left: 6px;
    display: inline-block;
}
.sumary-product .product-attribute .price del,
.contain-product .info .price del,
.minicart-item .left-info .price del{
    position: relative;
    margin-left: 6px;
}
.price-contain del::after,
.sumary-product .product-attribute .price del::after,
.contain-product .info .price del::after,
.minicart-item .left-info .price del::after{
    content: '';
    height: 1px;
    background-color: #aaaaaa;
    position: absolute;
    top: 50%;
    left: -1px;
    right: -1px;
    display: block;
    z-index: 1;
}
.pr-detail-layout .info .price ins .price-amount,
.sumary-product .product-attribute .price ins .price-amount{
    font-size: 22px;
}
.contain-product.style-widget .product-thumb{
    width: 82px;
    display: inline-block;
    float: left;
}
.contain-product.style-widget .info{
    width: calc(100% - 82px);
    display: inline-block;
    text-align: left;
    padding-left: 10px;
}
.contain-product.style-widget .info .price,
.contain-product.style-widget .info .product-title,
.contain-product.style-widget .info .categories{
    text-align: left;
}
.contain-product.style-widget .info .categories{
    margin-top: 3px;
}
.contain-product.style-widget .info .price{
    margin-bottom: 0;
}
.contain-product.layout-default{
    /*padding-bottom: 12px;*/
    padding-bottom: 2px;
 }
.contain-product.layout-default .info .price{
    margin-bottom: 11px;
}
.contain-product__deal-layout .slide-down-box .message,
.contain-product.layout-default .slide-down-box .message{
    font-size: 14px;
    color: #666666;
    display: block;
    line-height: 17px;
    margin: 0 0 19px;
    text-align: center;
    padding: 0 15px;
}
.pr-detail-layout .info .buttons,
.contain-product__deal-layout .slide-down-box .buttons,
.contain-product.layout-default .slide-down-box .buttons{
    padding: 0 11px;
    display: table;
    width: 100%;
}
.pr-detail-layout .info .buttons .btn,
.contain-product__deal-layout .slide-down-box .buttons .btn,
.contain-product.layout-default .slide-down-box .buttons .btn{
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.pr-detail-layout .info .buttons .btn:not(.add-to-cart-btn),
.contain-product__deal-layout .slide-down-box .buttons .btn:not(.add-to-cart-btn),
.contain-product.layout-default .slide-down-box .buttons .btn:not(.add-to-cart-btn){
    width: 32px;
    font-size: 16px;
    color: #666666;
}
.pr-detail-layout .info .buttons .add-to-cart-btn,
.contain-product__deal-layout .slide-down-box .buttons .add-to-cart-btn,
.contain-product.layout-default .slide-down-box .buttons .add-to-cart-btn{
    font-size: 14px;
    color: #ffffff;
    background-color: #888888;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    border-radius: 9999px;
    line-height: 1;
    padding: 15px 10px 16px;
}
.pr-detail-layout .info .buttons .add-to-cart-btn:hover,
.contain-product__deal-layout .slide-down-box .buttons .add-to-cart-btn:hover,
.contain-product.layout-default .slide-down-box .buttons .add-to-cart-btn:hover{
    background-color: #444444;
}
.contain-product.layout-02{
    display: inline-block;
    width: 100%;
}
.advance-product-box_countdown{
    border: 2px solid #e6e6e6;
}
.advance-product-box_countdown .contain-product .product-thumb{
    padding-left: 8px;
    padding-right: 8px;
}
.advance-product-box_countdown .contain-product .info{
    padding-bottom: 26px;
}
.advance-product-box_countdown .biolife-countdown{
    margin: 13px 0 0;
}
.advance-product-box_countdown .biolife-countdown>span{
    padding: 5px 5px 7px;
    min-width: 47px;
}
.advance-product-box_countdown .biolife-countdown .text{
    font-size: 12px;
    margin-top: 1px;
}
.shipping-info{
    display: block;
    font-size: 0;
    line-height: 0;
}
.shipping-info p{
    font-size: 14px;
    line-height: 1;
    text-align: center;
    margin: 0;
    color: #7faf51;
    font-weight: 600;
    padding-top: 7px;
}
.shipping-info p.for-today{
    color: #666666;
    padding-top: 1px;
    margin-bottom: 26px;
}
.minicart-item .left-info .qty{
    display: block;
    font-size: 0;
    line-height: 0;
    margin-top: 3px;
}
.minicart-item .left-info .qty .input-qty,
.minicart-item .left-info .qty>label{
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    font-weight: 600;
    margin: 0;
}
.minicart-item .left-info .qty .input-qty{
    border: none;
    padding: 0;
    margin-left: 4px;
    width: 100px;
    background-color: #f5f5f5;
}
.minicart-item .left-info .qty .input-qty:disabled{
    background-color: #ffffff;
}
.minicart-item .action{
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}
.minicart-item .action a{
    font-size: 16px;
    line-height: 24px;
    color: #999999;
    display: inline-block;
}
.minicart-item .action a+a{
    margin-left: 12px;
}
.minicart-item.editing .action a.edit,
.minicart-item .action a:hover{
    color: #f44242;
}
.minicart-item.editing .action a.edit i::before{
    content: '\f00c';
}
.minicart-block .cart-inner .minicart-empty{
    font-size: 14px;
    line-height: 1;
    display: block;
    text-align: center;
    color: #888888;
    margin-top: 34px;
    margin-bottom: 22px;
}
.biolife-cart-info .minicart-block .btn-control{
    display: inline-block;
    margin: 0;
    width: 100%;
    font-size: 0;
    line-height: 0;
}
.biolife-cart-info .minicart-block .btn-control .btn{
    display: inline-block;
    width: calc( 50% - 8px );
    padding: 16px 10px 17px;
    float: left;
    font-size: 14px;
    color: #666666;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #eeeeee;
    border: none;
    border-radius: 99999999px;
    margin-top: 20px;
}
.biolife-cart-info .minicart-block .btn-control .view-cart{
    margin-right: 16px;
    color: #ffffff;
}
.biolife-cart-info .minicart-block .btn-control .btn:hover{
    background-color: #333333 !important;
    color: #ffffff !important;
}
.minicart-block.layout-02 .icon-contain{
    display: inline-block;
    font-size: 0;
    line-height: 0;
}
.minicart-block.layout-02 .icon-contain .span-index{
    border: 2px solid #e6e6e6;
    padding-left: 20px;
    border-right: 0;
    display: inline-block;
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
}
.minicart-block.layout-02 .icon-contain .biolife-icon{
    font-size: 21px;
    line-height: 41px;
    display: inline-block;
    vertical-align: middle;
}
.minicart-block.layout-02 .icon-contain .sub-total{
    font-size: 14px;
    color: #555555;
    display: inline-block;
    line-height: 1;
    margin: 0;
    font-weight: 600;
    vertical-align: middle;
    padding-left: 9px;
    padding-right: 14px;
}
.minicart-block.layout-02 .icon-contain .btn-to-cart{
    font-size: 15px;
    line-height: 1;
    color: #ffffff;
    display: inline-block;
    margin: 0;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    min-width: 61px;
    padding: 15px 10px;
    vertical-align: middle;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
}
.biolife-brand .menu-title,
.biolife-products-block .menu-title,
.block-posts .menu-title,
.wrap-custom-menu .menu-title{
    display: block;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    margin: 0 0 13px 0;
    line-height: 24px;
}
.wrap-custom-menu ul.menu{
    display: block;
    margin: 0;
    padding: 0;
    line-height: 0;
    font-size: 0;
}
.wrap-custom-menu ul.menu>li{
    display: block;
    list-style: none;
    font-size: 14px;
    line-height: 26px;
    text-align: left;
}
.wrap-custom-menu ul.menu>li+li{
    margin-top: 10px;
}
.wrap-custom-menu ul.menu>li a{
    color: #555555;
}
.wrap-custom-menu ul.menu>li sup{
    font-size: 12px;
}
.block-posts .menu-title{
    margin-bottom: 17px;
}
.block-posts ul.posts{
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 0 0 1px 0;
    margin: 0;
}
.block-posts ul.posts li{
    display: inline-block;
    clear: both;
    padding-bottom: 20px;
    width: 100%;
}
.block-posts ul.posts li+li{
    padding-top: 20px;
    border-top: 1px solid #e6e6e6;
}
.block-posts ul.posts li:last-child{
    padding-bottom: 0;
}
.block-posts .block-post-item .thumb{
    display: inline-block;
    width: 100px;
    border: 0;
    float: left;
}
.block-posts .block-post-item .left-info{
    display: inline-block;
    width: calc( 100% - 100px);
    padding-left: 10px;
}
.block-posts .block-post-item .post-name{
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    display: block;
    margin: 0;
    padding: 1px 0 0;
    text-align: left;
}
.block-posts .block-post-item .post-name a{
    color: #333333;
    display: inline-block;
}
.block-posts .block-post-item .p-date,
.block-posts .block-post-item .p-comment{
    font-size: 13px;
    color: #666666;
    display: inline-block;
    margin: 7px 0 0;
    padding: 0;
    line-height: 19px;
}
.block-posts .block-post-item .p-comment{
    margin-left: 19px;
}
.vertical-category-block{
    display: block;
    position: relative;
}
.vertical-category-block .block-title{
    display: block;
    padding: 13px 30px 13px 17px;
    border-top-right-radius: 11px;
    border-top-left-radius: 11px;
    position: relative;
}
.vertical-category-block .menu-icon{
    display: inline-block;
    font-size: 0;
    line-height: 0;
    padding-top: 5px;
    vertical-align: top;
}
.vertical-category-block .menu-icon span{
    background-color: #ffffff;
    width: 17px;
    height: 2px;
    border: 0;
    display: inline-block;
    float: left;
    clear: left;
    margin: 0 3px 3px 9px;
}
.vertical-category-block .menu-icon span::before{
    content: '';
    display: inline-block;
    width: 3px;
    height: 2px;
    margin-left: -6px;
    background-color: #ffffff;
}
.vertical-category-block .block-title .menu-title{
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
    font-weight: 700;
    display: inline-block;
    padding-left: 14px;
}
.vertical-category-block .block-title .angle{
    position: absolute;
    top: 17px;
    right: 20px;
    font-size: 18px;
    color: #ffffff;
    line-height: 18px;
    display: inline-block;
    max-width: 18px;
    -webkit-transition: all 320ms ease;
    -moz-transition: all 320ms ease;
    -o-transition: all 320ms ease;
    transition: all 320ms ease;
}
.vertical-category-block .black-color .menu-icon span::before,
.vertical-category-block .black-color .menu-icon span{
    background-color: #333333;
}
.vertical-category-block .black-color .angle,
.vertical-category-block .black-color .menu-title{
    color: #333333;
}
.vertical-category-block>.wrap-menu{
    display: inline-block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #ffffff;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    z-index: 30;
}
.vertical-category-block>.wrap-menu>.menu{
    padding: 9px 0 18px;
    font-size: 0;
    line-height: 0;
    position: relative;
}
.vertical-category-block>.wrap-menu>.menu>li{
    font-size: 14px;
    line-height: 45px;
    display: block;
    padding: 0 20px;
}
.vertical-category-block>.wrap-menu>.menu>li>a{
    color: #333333;
    display: inline-block;
    width: 100%;
}
.vertical-category-block>.wrap-menu>.menu li.has-megamenu>a,
.vertical-category-block>.wrap-menu .menu li.has-child>a{
    position: relative;
    padding-right: 5px;
}
.vertical-category-block .wrap-menu .menu li.has-child{
    position: relative;
}
.vertical-category-block .wrap-menu .menu li.has-megamenu>a:after,
.vertical-category-block .wrap-menu .menu li.has-child>a:after{
    font-family: 'FontAwesome', sans-serif;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: '\f105';
    display: inline-block;
    font-size: 14px;
    line-height: 45px;
    position: absolute;
    top: 0;
    right: 0;
    color: #333333;
}
.vertical-category-block>.wrap-menu>.menu>li+li>a{
    border-top: 1px dashed #d2d2d2;
}
.vertical-category-block>.wrap-menu>.menu>li>a .biolife-icon{
    font-size: 26px;
    width: 40px;
    text-align: left;
    display: inline-block;
    float: left;
    line-height: 45px;
}
.vertical-category-block .wrap-menu ul.sub-menu{
    display: inline-block;
    width: 250px;
    padding: 0;
    left: 100%;
    top: 0;
}
.vertical-category-block .wrap-menu ul.sub-menu li{
    list-style: none;
    display: block;
    font-size: 14px;
    margin: 0;
    padding: 0 20px;
}
.vertical-category-block .wrap-menu ul.sub-menu li a{
    display: block;
    line-height: inherit;
    color: #333333;
}
.vertical-category-block .wrap-menu ul.sub-menu li+li>a{
    border-top: 1px dashed #d2d2d2;
}
.vertical-category-block .wrap-menu .wrap-megamenu{
    top: 0;
    left: 100%;
    padding: 22px 30px 0;
}
.vertical-category-block:not(.always) .block-title{
    cursor: pointer;
}
.vertical-category-block:not(.always) .wrap-menu{
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transition: all 320ms ease;
    -moz-transition: all 320ms ease;
    -o-transition: all 320ms ease;
    transition: all 320ms ease;
}
.vertical-category-block:not(.always).open-menu .wrap-menu{
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
}
.vertical-category-block:not(.open-menu) .block-title .angle{
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.biolife-vertical-menu.height-auto .vertical-category-block>.wrap-menu{
    position: static;
    width: 100%;
}
.biolife-products-block .products-list{
    padding: 0;
    margin: 0;
}
.biolife-products-block .products-list .product-item{
    list-style: none;
}
.biolife-carousel.nav-center-02 .slick-arrow,
.biolife-carousel.nav-center-bold .slick-arrow,
.biolife-carousel.nav-center .slick-arrow{
    position: absolute;
    display: block;
    top: 50%;
    margin-top: -17px;
    color: rgba(102,102,102,0.2);
    z-index: 10;
    margin-bottom: 0;
    font-size: 33px;
    cursor: pointer;
    -webkit-transition: all 350ms ease;
    -moz-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    transition: all 350ms ease;
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
}
.biolife-carousel.nav-center-bold .slick-arrow{
    font-size: 44px;
}
.products-list.biolife-carousel.nav-center-02 .slick-arrow{
    -webkit-transition: opacity 1.2s ease;
    -moz-transition: opacity 1.2s ease;
    -o-transition: opacity 1.2s ease;
    transition: opacity 1.2s ease;
}
.biolife-carousel.nav-center-bold .slick-arrow.prev,
.biolife-carousel.nav-center .slick-arrow.prev{
    left: -40px;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
}
.biolife-carousel.nav-center-bold .slick-arrow.next,
.biolife-carousel.nav-center .slick-arrow.next{
    right: -40px;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
}
.biolife-carousel.nav-center-bold .slick-arrow.prev{
    left: -44px;
}
.biolife-carousel.nav-center-bold .slick-arrow.next{
    right: -44px;
}
.biolife-carousel.nav-center-02 .slick-arrow{
    font-size: 30px
}
.biolife-carousel.nav-center-02 .slick-arrow.prev{
    left: -9px;
    padding-right: 14px;
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
}
.biolife-carousel.nav-center-02 .slick-arrow.next{
    right: -9px;
    padding-left: 14px;
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px);
}
.biolife-carousel.nav-center-02 .slick-arrow::before{
    content: "\f104";
    font-family: 'FontAwesome', sans-serif;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.biolife-carousel.nav-center-02 .slick-arrow.next::before{
    content: "\f105";
}
.biolife-carousel.nav-center-02:hover .slick-arrow.prev,
.biolife-carousel.nav-center-02:hover .slick-arrow.next,
.biolife-carousel.nav-center-bold:hover .slick-arrow.prev,
.biolife-carousel.nav-center-bold:hover .slick-arrow.next,
.biolife-carousel.nav-center:hover .slick-arrow.prev,
.biolife-carousel.nav-center:hover .slick-arrow.next{
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
}
.biolife-carousel.nav-center-02 .slick-arrow:hover{
    color: #444444;
}
.contain-product .product-thumb{
    display: block;
    font-size: 0;
    line-height: 0;
    position: relative;
    /*overflow: hidden;*/
}
.contain-product .product-thumb .lookup{
    display: inline-block;
    font-size: 22px;
    line-height: 0;
    color: #666666;
    position: absolute;
    z-index: 2;
    bottom: 5px;
    right: 15px;
    padding: 5px;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}
.contain-product:hover .product-thumb .lookup{
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: transform 330ms ease 100ms;
    -moz-transition: transform 330ms ease 100ms;
    -o-transition: transform 330ms ease 100ms;
    transition: transform 330ms ease 100ms;
}
.contain-product .product-thumb .labels{
    display: inline-block;
    position: absolute;
    top: 20px;
    left: 20px;
}
.contain-product .product-thumb .labels>span{
    display: inline-block;
    float: left;
    clear: left;
    min-width: 54px;
    border-radius: 3px;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 5px;
    background-color: #fa3535;
    line-height: 22px;
    padding: 0 5px;
    font-weight: 700;
}
.contain-product .product-thumb .labels .new-label{
    background-color: #32de04;
}
.contain-product .product-thumb .labels .feature-label{
    background-color: #036dc7;
}
.products-list.biolife-carousel.slick-initialized .contain-product .product-thumb img,
.products-list:not(.biolife-carousel) .contain-product .product-thumb img{
    width: 100%;
}
.contain-product .info .categories{
    display: block;
    font-size: 15px;
    color: #888888;
    text-align: center;
    font-weight: 400;
    line-height: 1;
    margin-top: 13px;
}
.contain-product .info .product-title{
    display: block;
    font-size: 17px;
    font-weight: 600;
    margin: 7px 0 0;
    text-align: center;
}
.contain-product .info .product-title a{
    color: #222222;
    display: inline-block;
}
.contain-product .info .price{
    text-align: center;
    margin-bottom: 12px;
}
.pr-detail-layout{
    display: inline-block;
    width: 100%;
    position: relative;
    padding-bottom: 40px;
}
.pr-detail-layout .product-thumb,
.pr-detail-layout .info,
.pr-detail-layout .advance-info{
    display: inline-block;
    vertical-align: top;
}
.pr-detail-layout .product-thumb{
    width: 31.036%;
    float: left;
}
.pr-detail-layout .info{
    width: 47.009%;
    padding-left: 20px;
    padding-right: 10px;
    float: left;
}
.pr-detail-layout .advance-info{
    width: 21.955%;
    border-left: 1px solid #e6e6e6;
    padding-left: 20px;
    margin-top: 31px;
    padding-top: 3px;
    padding-bottom: 15px;
    min-height: 167px;
}
.pr-detail-layout .info .categories{
    text-align: left;
    margin-top: 14px;
    max-width: 356px;
    display: block !important;
}
.pr-detail-layout .info .product-title{
    text-align: left;
    max-width: 356px;
}
.pr-detail-layout .info .excerpt{
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    margin: 29px 0 0;
    max-width: 356px;
}
.pr-detail-layout .info .price{
    text-align: left;
    margin: 23px 0 0;
    max-width: 356px;
}
.pr-detail-layout .info .buttons{
    padding: 0;
    display: block;
    margin-top: 20px;
    margin-bottom: 15px;
    max-width: 356px;
}
.pr-detail-layout .info .buttons .btn:not(.add-to-cart-btn){
    width: 32px;
    font-size: 16px;
    color: #666666;
}
.pr-detail-layout .info .buttons .wishlist-btn{
    position: absolute;
    top: 5px;
    left: 5px;
    display: block;
    padding: 4px 0;
    border-radius: 50%;
    background: rgba(255,255,255,0.8);
}
.pr-detail-layout .info .buttons .add-to-cart-btn{
    padding: 14px;
    display: inline-block;
    min-width: 159px;
}
.pr-detail-layout .info .buttons .compare-btn{
    padding: 5px 5px 4px;
    display: inline-block;
    position: relative;
    margin-left: 10px;
}
.pr-detail-layout .info .buttons .compare-btn::before{
    content: '';
    display: block;
    width: 1px;
    height: 25px;
    background-color: #aaaaaa;
    position: absolute;
    left: -4px;
    top: 4px;
}
.pr-detail-layout .advance-info .list{
    padding: 0;
    display: block;
}
.pr-detail-layout .advance-info .list li{
    font-size: 14px;
    line-height: 24px;
    color: #666666;
    padding-left: 13px;
    position: relative;
}
.pr-detail-layout .advance-info .list li::before{
    content: '';
    width: 7px;
    height: 7px;
    display: block;
    border-radius: 50%;
    background-color: #666666;
    position: absolute;
    left: 0;
    top: 9px;
}
.pr-detail-layout .advance-info .shipping-info{
    margin-top: 18px;
    padding-left: 13px;
}
.pr-detail-layout .advance-info .shipping-info p{
    text-align: left;
    margin: 0;
    padding: 0;
}
.pr-detail-layout .advance-info p.for-today{
    margin-top: 1px;
}
.biolife-products-block .menu-title{
    margin-bottom: 24px;
}
.biolife-brand ul.brands{
    display: block;
    padding: 0;
    font-size: 0;
    line-height: 0;
    border-top: 1px solid #e6e6e6;
}
.biolife-brand ul.brands li{
    list-style: none;
    display: inline-block;
    width: 25%;
    float: left;
    text-align: center;
}
.biolife-brand.vertical ul.brands{
    border: none;
    padding-bottom: 14px;
    display: inline-block;
}
.biolife-brand.vertical ul.brands li{
    display: block;
    width: auto;
    clear: left;
    text-align: left;
    margin: 17px 0;
}
.biolife-banner.layout-01{
    display: block;
    width: 176px;
    text-align: center;
    font-size: 0;
    line-height: 1;
    border: 1px solid transparent;
    position: relative;
}
.biolife-banner.layout-01::before{
    content: '';
    display: block;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    width: 19px;
    background: transparent;
}
.biolife-banner.layout-01::after{
    content: '';
    display: block;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    width: 19px;
    background: transparent;
}
.biolife-banner.layout-01 .top-title{
    font-size: 26px;
    font-weight: 700;
    color: #333333;
    margin: 0;
    line-height: 1;
    display: inline-block;
    position: relative;
    top: -15px;
    padding: 0 7px;
}
.biolife-banner.layout-01 .content{
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    text-align: center;
    margin: -7px 0 0;
    padding: 0 20px;
}
.biolife-banner.layout-01 .bottomm-title{
    font-size: 20px;
    color: #333333;
    font-family: 'Playfair Display', sans-serif;
    font-weight: 700;
    font-style: italic;
    line-height: 1;
    position: relative;
    bottom: -9px;
    padding: 0 7px;
}
.header-search-bar.layout-01 {
    display: inline-block;
    position: relative;
    float: left;
}
.header-search-bar.layout-01 .input-text{
    font-size: 14px;
    color: #222222;
    border: 2px solid #dddddd;
    width: 500px;
    display: inline-block;
    border-radius: 99999px;
    padding: 10px 240px 10px 20px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .header-search-bar.layout-01 .input-text {
        line-height: 1;
        padding: 9px 240px 9px 20px;
    }
}
.header-search-bar.layout-01 .btn-submit{
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    border-left: 1px solid #e6e6e6;
    color: #333333;
    line-height: 0;
    font-size: 20px;
    display: inline-block;
    padding: 9px;
    width: 89px;
    text-align: center;
    position: absolute;
    top: 5px;
    right: 0;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
}
.header-search-bar.layout-01 .input-text::-ms-input-placeholder,
.header-search-bar.layout-01 .input-text:-ms-input-placeholder,
.header-search-bar.layout-01 .input-text::placeholder{
    color: #888888;
}
.header-search-bar.layout-01 select{
    display: none;
}
.header-search-bar.layout-01 .nice-select{
    position: absolute;
    top: 3px;
    right: 118px;
    padding-left: 0;
}
.header-search-bar.layout-01 .nice-select:after,
.header-search-bar.layout-01 .nice-select .current{
    color: #888888;
}
.header-search-bar.layout-01 .nice-select .current{
    width: 103px;
    text-align: right;
}
.header-search-bar.layout-01.no-product-cat .btn-submit{
    width: auto;
    padding: 12px 24px 12px 16px;
    top: 2px;
    right: 0;
    border-left: none;
}
.header-search-bar.layout-01.no-product-cat .input-text{
    padding-right: 60px;
}
.header-area .live-info {
    display: inline-block;
    max-width: calc( 100% - 500px );
    float: right;
}
.header-area .live-info .telephone{
    font-size: 20px;
    line-height: 20px;
    color: #333333;
    display: block;
    margin: 0;
    padding-left: 6px;
}
.header-area .live-info .telephone i{
    font-size: 26px;
    position: relative;
    bottom: -5px;
    right: 6px;
}
.header-area .live-info .working-time{
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    display: block;
    margin: 1px 0 0;
    padding-left: 28px;
}
.padding-top-2px{
    padding-top: 2px;
}
.header-area.layout-05 .header-top .top-bar .social-list li a,
.header-area.layout-05 .header-top .nice-select .current,
.header-area.layout-05 .header-top .nice-select:after,
.header-area.layout-05 .header-top .top-bar .horizontal-menu a{
    color: #333333;
}
.header-area.layout-05 .header-top{
    border-bottom: 1px solid #e3e3e3;
}
.header-area.layout-05 .header-top .horizontal-menu>li+li{
    border-left: 1px solid #e3e3e3;
}
.header-area.layout-05 .header-top .top-bar .social-list{
    border-right: 1px solid #e3e3e3;
}
.header-area.layout-05 .header-middle{
    box-shadow: 5px 5px 5px rgba(204, 204, 204,0.2);
    -webkit-box-shadow: 5px 5px 5px rgba(204, 204, 204,0.2);
}
    /***********************
    * Newsletter Block     *
    ***********************/
.newsletter-block.layout-01{
    margin-top: 40px;
}
.newsletter-block .title{
    font-size: 20px;
    line-height: 20px;
    color: #333333;
    font-weight: 700;
    display: block;
    margin: 0 0 17px;
}
.newsletter-block .form-content{
    font-size: 0;
    line-height: 0;
    display: block;
    position: relative;
}
.newsletter-block .form-content .input-text{
    font-size: 14px;
    line-height: 1;
    color: #333333;
    display: inline-block;
    width: 100%;
    border: 1px solid #e6e6e6;
    -webkit-border-radius: 9999px;
    -moz-border-radius: 9999px;
    border-radius: 9999px;
    padding: 9px 125px 9px 18px;
    height: 46px;
}
.newsletter-block .form-content .bnt-submit{
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    background-color: #333333;
    color: #ffffff;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    min-width: 123px;
    padding: 13px;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
}
.newsletter-block .form-content .bnt-submit:hover{
    background-color: #444444;
}
.newsletter-block .form-content .input-text::-webkit-input-placeholder{
    line-height: 24px;
}
.newsletter-block .form-content .input-text::-moz-placeholder {
    line-height: 24px;
}
.newsletter-block .form-content .input-text:-ms-input-placeholder {
    line-height: 24px;
}
.newsletter-block .form-content .input-text:-moz-placeholder {
    line-height: 24px;
}
@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)
{ @media {
    /*
      Define here the CSS styles applied only to Safari browsers
      (any version and any device)
     */
}}
.newsletter-block.layout-03{
    display: block;
    font-size: 0;
    line-height: 0;
    background-image: url('../images/home-02/bgrd-newsletter.jpg');
    height: 315px;
    padding-top: 86px;
    background-repeat: no-repeat;
    background-position: center;
}
.newsletter-block.layout-03 .form-content{
    display: block;
    text-align: center;
    color: #ffffff;
}
.newsletter-block.layout-03 .form-content .newslt-title{
    font-size: 35px;
    line-height: 1;
    display: block;
    font-weight: 700;
    margin: 0;
}
.newsletter-block.layout-03 .form-content .sub-title{
    font-size: 16px;
    line-height: 20px;
    display: block;
    margin: 4px 0 0;
    font-weight: 400;
}
.newsletter-block.layout-03 .form-content b{
    font-size: 20px;
    font-weight: 700;
}
.newsletter-block.layout-03 .form-content .input-text{
    max-width: 508px;
    font-size: 14px;
    line-height: 1;
    color: #444444;
    padding: 10px 130px 10px 21px;
    background-color: #ffffff;
    border: none;
    height: 46px;
}
.newsletter-block.layout-03 .form-content .input-text::-webkit-input-placeholder{
    color: #666666;
    line-height: 26px;
}
.newsletter-block.layout-03 .form-content .input-text::-moz-placeholder {
    color: #666666;
    line-height: 26px;
}
.newsletter-block.layout-03 .form-content .input-text:-ms-input-placeholder {
    color: #666666;
    line-height: 26px;
}
.newsletter-block.layout-03 .form-content .input-text:-moz-placeholder {
    color: #666666;
    line-height: 26px;
}
.newsletter-block .form-content .bnt-submit .form-content .bnt-submit{
    min-width: 130px;
}
.newsletter-block.layout-03 .form-content form{
    display: inline-block;
    position: relative;
    max-width: 508px;
    width: 100%;
    margin-top: 23px;
}
.newsletter-block.layout-03 .form-content .bnt-submit{
    border-radius: 999px;
}
.newsletter-block.layout-04{
    font-size: 0;
    line-height: 0;
    padding-left: 94px;
}
.newsletter-block.layout-04 .form-content,
.newsletter-block.layout-04 .wrap-title{
    display: inline-block;
}
.newsletter-block.layout-04 .wrap-title{
    margin-right: 40px;
}
.newsletter-block.layout-04 .wrap-title .biolife-icon{
    font-size: 65px;
    line-height: 1;
    display: inline-block;
    color: #c8c8c8;
    vertical-align: middle;
}
.newsletter-block.layout-04 .wrap-title .texts{
    display: inline-block;
    vertical-align: middle;
    padding-left: 17px;
}
.newsletter-block.layout-04 .wrap-title .newslt-title{
    font-size: 20px;
    line-height: 20px;
    color: #333333;
    font-weight: 700;
    display: block;
    margin: 0;
}
.newsletter-block.layout-04 .wrap-title .sub-title{
    display: block;
    margin: 10px 0 0;
    font-size: 15px;
    color: #666666;
    line-height: 1;
}
.newsletter-block.layout-04 .form-content{
    max-width: 550px;
    width: 100%;
}
.newsletter-block_popup-layout{
    background-image: url('../images/bg-newsletter.jpg');
    display: block;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: top left;
    padding: 63px 59px 73px;
}
.newsletter-block_popup-layout .inner-content{
    display: block;
    text-align: center;
    max-width: 360px;
}
.newsletter-block_popup-layout .wrap-title .sup-title{
    display: block;
    margin: 0;
    font-size: 20px;
    line-height: 1;
    font-weight: 600;
    color: #222222;
    text-transform: uppercase;
}
.newsletter-block_popup-layout .wrap-title .newslt-title{
    display: block;
    margin: 0;
    line-height: 1;
    font-size: 80px;
    color: #222222;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: -2px;
}
.newsletter-block_popup-layout .wrap-title .newslt-title sup{
    font-size: 60%;
}
.newsletter-block_popup-layout .form-content{
    margin-top: 22px;
}
.newsletter-block_popup-layout .form-content .input-text{
    font-size: 14px;
    text-align: center;
    color: #333333;
    background-color: #ffffff;
    border: 0;
    line-height: 1;
    padding: 10px 10px 11px;
    height: 46px;
}
.newsletter-block_popup-layout .form-content .input-text::-webkit-input-placeholder {
    color: #999999;
    line-height: 26px;
}
.newsletter-block_popup-layout .form-content .input-text:-ms-input-placeholder {
    color: #999999;
    line-height: 26px;
}
.newsletter-block_popup-layout .form-content .input-text::placeholder{
    color: #999999;
    line-height: 26px;
}
.newsletter-block_popup-layout .form-content .bnt-submit{
    position: static;
    width: 100%;
    border-radius: 9999px;
    padding: 16px;
    margin-top: 11px;
}
.newsletter-block_popup-layout .form-content .dismiss-newsletter{
    display: inline-block;
    font-size: 14px;
    margin: 0;
    color: #666666;
    text-shadow: none;
    font-weight: 400;
    opacity: 1;
    filter: alpha(opacity=100);
    float: none;
    margin-top: 17px;
    position: relative;
}
.newsletter-block_popup-layout .form-content .dismiss-newsletter::before{
    content: '';
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    height: 2px;
    border-top: 1px solid #afaeae;
    background-color: #cdcccc;
    z-index: 1;
}
.newsletter-block_popup-layout .form-content .dismiss-newsletter:hover::before{
    background-color: transparent !important;
}
.product-category .products-list{
    padding: 0;
    display: block;
    margin: 0;
}
.product-category .products-list li{
    display: block;
    list-style: none;
}
/***********************
* wrap-custom-menu      *
***********************/
.wrap-custom-menu.vertical-menu-2 ul li{
    font-size: 14px;
    line-height: 33px;
}
.wrap-custom-menu.vertical-menu-2.bigger ul li{
    font-size: 15px;
    line-height: 35px;
}
.wrap-custom-menu.vertical-menu-2 ul li+li{
    margin-top: 1px;
}
.wrap-custom-menu.vertical-menu-2 ul li a{
    color: #666666;
}
.wrap-custom-menu.horizontal-menu-v2 ul.menu{
    display: block;
    font-size: 0;
    line-height: 0;
    text-align: center;
}
.wrap-custom-menu.horizontal-menu-v2 ul.menu>li{
    margin: 0;
    display: inline-block;
    font-size: 15px;
    line-height: 1;
    padding: 0 15px;
}
.wrap-custom-menu.horizontal-menu-v2 ul.menu>li+li{
    border-left: 1px solid #e6e6e6;
}
.wrap-custom-menu.horizontal-menu-v2 ul.menu>li a{
    color: #666666;
    display: inline-block;
}
/***********************
* Biolife Social list *
***********************/
.biolife-social{
    margin-top: 25px;
}
.biolife-social ul{
    padding: 0;
    margin: 0;
    font-size: 0;
    line-height: 0;
    text-align: left;
}
.biolife-social ul li{
    list-style: none;
    display: inline-block;
}
.biolife-social ul li+li{
    margin-left: 30px;
}
.biolife-social ul li a{
    font-size: 18px;
    color: #666666;
}
.biolife-social.circle-hover{
    margin-top: 24px;
}
.biolife-social.circle-hover ul li a{
    color: #555555;
    display: block;
    padding: 0 5px;
    line-height: 34px;
    min-width: 34px;
    text-align: center;
    border-radius: 50%;
    background-color: transparent;
}
.biolife-social.circle-hover ul li a:hover{
    color: #ffffff;
}
.biolife-social.circle-hover ul li+li{
    margin-left: 10px;
}
.biolife-social.add-title{
    margin-top: 8px;
}
.biolife-social.add-title .fr-title{
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    margin-right: 22px;
}
.biolife-social.add-title .fr-title+.socials{
    display: inline-block;
    vertical-align: middle;
}
.biolife-social.add-title ul li a{
    color: #aaaaaa;
}
.biolife-social.add-title ul li+li{
    margin-left: 21px;
}
/***********************
* Contact Block     *
***********************/
.contact-info-block.footer-layout ul{
    padding-top: 3px;
}
.contact-info-block.footer-layout ul{
    padding: 0;
    display: block;
}
.contact-info-block.footer-layout ul li{
    display: block;
    list-style: none;
}
.contact-info-block.footer-layout ul li+li{
    margin-top: 10px;
}
.contact-info-block.footer-layout li p.info-item{
    display: block;
    margin: 0;
}
.contact-info-block.footer-layout li .biolife-icon{
    font-size: 22px;
    color: #525252;
    display: inline-block;
    float: left;
    width: 36px;
    text-align: left;
}
.contact-info-block.footer-layout li .icon-letter{
    font-size: 12px;
    color: #6a6a6a;
    line-height: 20px;
    padding-top: 2px;
}
.contact-info-block.footer-layout li .desc{
    font-size: 15px;
    line-height: 20px;
    color: #666666;
    display: inline-block;
    font-weight: 400;
    width: calc( 100% - 36px);
}
.contact-info-block.simple-info{
    margin-top: 24px;
}
.contact-info-block.simple-info .title{
    font-size: 18px;
    display: block;
    margin: 0;
    color: #222222;
    line-height: 1;
    font-weight: 600;
}
.contact-info-block.simple-info .info-item{
    display: table;
    width: 100%;
    margin-top: 17px;
    max-width: 300px;
}
.contact-info-block.simple-info .info-item .biolife-icon,
.contact-info-block.simple-info .info-item .desc{
    display: table-cell;
    vertical-align: top;
    font-size: 15px;
    line-height: 20px;
    color: #666666;
    padding-left: 16px;
    margin: 0;
}
.contact-info-block.simple-info .info-item .icon{
    display: inline-block;
    width: 33px;
    padding: 0;
    margin: 4px 0 0;
    font-size: 0;
    line-height: 0;
}
.footer-item .section-title{
    font-size: 24px;
    line-height: 1;
    color: #222222;
    font-weight: 700;
    display: block;
    margin: 0 0 24px;
}
.footer-phone-info{
    margin-top: 40px;
}
.footer-phone-info .biolife-icon{
    color: #666666;
    font-size: 40px;
    line-height: 1;
    display: inline-block;
    float: left;
    width: 40px;
    padding-top: 9px;
}
.footer-phone-info .r-info{
    font-size: 18px;
    line-height: 24px;
    color: #222222;
    display: inline-block;
    width: calc(100% - 40px);
    font-weight: 600;
    margin: 0;
    padding-left: 20px;
}
.footer-phone-info .r-info span{
    display: block;
}
.footer-phone-info.mode-03,
.footer-phone-info.mode-02{
    margin-top: 31px;
}
.footer-phone-info.mode-02 .r-info .number{
    font-size: 17px;
    color: #666666;
}
.footer-phone-info.mode-03 .biolife-icon,
.footer-phone-info.mode-02 .biolife-icon{
    padding-top: 6px;
}
.footer-phone-info.mode-03 .r-info .number{
    color: #222222;
}
.border-top-type-1{
    border-top: 1px solid #e6e6e6;
}
.copy-right-text{
    margin-top: 37px;
}
.copy-right-text p{
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    color: #222222;
    font-weight: 400;
}
.copy-right-text p b{
    font-weight: 600;
}
.footer.layout-02 .copy-right-text{
    margin-top: 22px;
}
.footer.layout-02 .copy-right-text p{
    color: #ffffff;
}
.copy-right-text.center-align{
    margin-top: 33px;
    margin-bottom: 31px;
}
.copy-right-text.center-align>p{
    text-align: center;
}
.payment-methods ul{
    padding: 0;
    display: block;
    text-align: right;
    margin: 0;
}
.payment-methods ul li{
    list-style: none;
    display: inline-block;
    padding: 26px 0 29px;
}
.payment-methods ul li+li{
    margin-left: 19px;
}
.payment-methods.layout-02 .title{
    font-size: 18px;
    color: #222222;
    font-weight: 600;
    display: block;
    line-height: 1;
    margin: 0 0 15px;
}
.payment-methods.layout-02 .payments{
    text-align: left;
    font-size: 0;
    line-height: 0;
}
.payment-methods.layout-02 .payments li{
    padding: 0;
    margin-bottom: 20px;
}
.payment-methods.layout-02 .payments li+li{
    margin-left: 0;
}
.payment-methods.layout-02 .payments li:not(:last-child){
    margin-right: 20px;
}
.footer.layout-02 .payment-methods ul li{
    padding-top: 15px;
    padding-bottom: 14px;
}
.footer.layout-01 .copy-rights-contain{
    background-color: #ffffff;
}
.footer.layout-01 .payment-methods ul li{
    padding: 29px 0;
}
.footer.layout-01 .copy-right-text{
    margin-top: 39px;
}
.separator{
    display: block;
    border: none;
    height: 1px;
    background-color: #e6e6e6;
}
.footer-content{
    padding-top: 50px;
}
.footer.layout-02 .footer-content{
    padding-top: 80px;
}
.header-area .primary-menu ul .wrap-megamenu{
    padding: 22px 30px 30px;
}
.header-area .primary-menu .mega-content{
    margin-left: -15px;
    margin-right: -15px;
}
.header-area.layout-01{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-image: url('../images/home-01/bg_header-01.png');
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-transition: all 350ms ease;
    -moz-transition: all 350ms ease;
    -ms-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    transition: all 350ms ease;
}
.header-area.layout-01 .nice-select:after,
.header-area.layout-01 .nice-select .current,
.header-area.layout-01 .header-top .top-bar .social-list li a,
.header-area.layout-01 .header-top .top-bar .horizontal-menu a{
    color: #333333;
}
.header-area.layout-01 .header-top .right .horizontal-menu>li:first-child{
    border: none;
}
.header-area.layout-01 .left .horizontal-menu>li{
    padding: 0;
}
.header-area.layout-01 .top-bar.left>ul+ul{
    margin-left: 34px;
}
.header-area.layout-01 .header-top .right .horizontal-menu>li{
    min-width: auto;
    padding-left: 0;
    padding-right: 34px;
    border: none;
}
.header-area.layout-01 .header-top .right .horizontal-menu>li:last-child{
    padding-right: 0;
    border: none;
}
.header-area.layout-01 .top-bar.left .horizontal-menu li{
    line-height: 42px;
}
.header-area.layout-01 .header-top{
    margin-top: 36px;
}
.header-area.layout-01 .header-bottom:not(.pre-sticky){
    padding-bottom: 42px;
}
.header-top .top-bar .social-list.circle-layout{
    margin: 0;
    line-height: 0;
    font-size: 0;
    float: none;
}
.header-top .top-bar .social-list.circle-layout li{
    line-height: 0;
    font-size: 0;
    margin: 0;
}
.header-top .top-bar .social-list.circle-layout li+li{
    margin-left: 11px;
}
.header-top .top-bar .social-list.circle-layout a>i{
    margin: 0;
}
.header-area.layout-01 .top-bar .social-list.circle-layout a{
    color: #aaaaaa;
}
.header-top .top-bar .social-list.circle-layout a{
    background-color: #dddddd;
    padding: 0 5px;
    min-width: 27px;
    display: inline-block;
    line-height: 27px;
    text-align: center;
    color: #aaaaaa;
    border-radius: 50%;
}
.header-top .top-bar .social-list.circle-layout a:hover{
    color: #ffffff !important;
}
body:not(.open-overlay) .header-area.layout-01{
    z-index: 20;
}
.background-mega-01{
    background: url("../images/megamenu/vegetable-mega-bg.png");
}
.background-mega-02{
    background: url("../images/megamenu/megamenu-bg-02.jpg");
}
.max-width-270{
    max-width: 270px;
}
.mobile-footer{
    display: none;
    background-color: #ededed;
    padding: 5px 0 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}
.mobile-footer .mobile-footer-inner{
    display: table;
    width: 100%;
    margin: 0;
}
.mobile-footer .mobile-footer-inner>div{
    display: table-cell;
    text-align: center;
}
.mobile-footer .mobile-block>a{
    display: block;
    color: #333333;
    padding: 5px;
    font-size: 0;
    line-height: 0;
}
.mobile-footer .mobile-block>a .text{
    font-size: 13px;
    line-height: 1;
    display: block;
    font-weight: 600;
}
.mobile-footer .mobile-block>a>.fa{
    display: block;
    font-size: 15px;
    margin-bottom: 2px;
}
.mobile-search-content,
.mobile-search .open-searchbox{
    display: none;
}
.mobile-search{
    font-size: 0;
    line-height: 0;
}
.mobile-search .open-searchbox .biolife-icon{
    font-size: 21px;
    color: #333333;
}
.mobile-search .mobile-search-content{
    display: none;
    position: fixed;
    width: 700px;
    top: 40%;
    left: 50%;
    margin-left: -350px;
    z-index: 120;
    border-top: 2px solid #666666;
    -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    background-color: #ffffff;
    padding: 40px;
    text-align: left;
}
.mobile-search .mobile-search-content .form-search{
    position: relative;
}
.mobile-search .mobile-search-content .input-text{
    display: inline-block;
    width: calc( 100% - 160px);
    font-size: 14px;
    line-height: 1;
    padding: 8px 15px;
    border-radius: 2px;
    border-color: #e6e6e6;
    color: #444444;
    height: 44px;
}
.mobile-search .mobile-search-content .input-text::-webkit-input-placeholder { /* Edge */
    line-height: 25px;
}
.mobile-search .mobile-search-content .input-text:-ms-input-placeholder { /* Internet Explorer 10-11 */
    line-height: 25px;
}
.mobile-search .mobile-search-content .input-text::placeholder {
    line-height: 25px;
}
.mobile-search .mobile-search-content .nice-select .current{
    width: 100%;
    color: #666666;
}
.mobile-search .mobile-search-content .nice-select{
    display: inline-block;
    float: left;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    width: 160px;
    clear: none;
    margin-left: 0;
    border-right: none;
}
.mobile-search .mobile-search-content .btn-submit{
    font-size: 16px;
    color: #ffffff;
    background: #555555;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 2px;
    padding: 12px 17px 12px;
    line-height: 20px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
}
#overlay{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.5);
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 250ms ease;
    -moz-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    transition: all 250ms ease;
}
body.open-overlay #overlay{
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
}
.recently-products-cat ul{
    padding: 0;
}
.recently-products-cat ul li{
    list-style: none;
}
.sidebar .widget{
    display: block;
    clear: both;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 37px;
}
.sidebar .widget:last-child{
    border-bottom: none;
}
.sidebar .widget .wgt-title{
    color: #222222;
    font-size: 17px;
    font-weight: 600;
    text-align: left;
    display: block;
    margin: 0;
    position: relative;
}
.sidebar .widget .wgt-title::after{
    content: "\f0d8";
    font-family: 'FontAwesome', sans-serif;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
    position: absolute;
    top: 2px;
    right: 0;
    display: none;
}
.sidebar .widget .wgt-content{
    display: block;
}
.sidebar .wgt-content .color-list,
.sidebar .wgt-content .check-list,
.sidebar .wgt-content .cat-list{
    padding: 0;
    display: block;
    font-size: 0;
    line-height: 0;
    margin: 15px 0 22px;
}
.sidebar .wgt-content .color-list li,
.sidebar .wgt-content .color-list li,
.sidebar .wgt-content .check-list li,
.sidebar .wgt-content .cat-list li{
    list-style: none;
    display: block;
    font-size: 14px;
    line-height: 34px;
    padding: 0;
    color: #444444;
}
.sidebar .wgt-content .check-list.bold li{
    font-size: 15px;
    font-weight: 600;
}
.sidebar .wgt-content .check-list li a,
.sidebar .wgt-content .cat-list li a{
    color: inherit;
}
.sidebar .wgt-content .check-list li a{
    padding-left: 30px;
    position: relative;
    display: inline-block;
}
.sidebar .wgt-content .check-list li a::before{
    content: '';
    width: 15px;
    height: 15px;
    display: block;
    border: 2px solid #eeeeee;
    border-radius: 2px ;
    position: absolute;
    top: 10px;
    left: 0;
}
.sidebar .wgt-content .check-list li a::after{
    content: "\f00c";
    font-family: 'FontAwesome', sans-serif;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    display: block;
    font-size: 12px;
    position: absolute;
    top: 12px;
    left: 2px;
    color: #eeeeee;
    line-height: 1;
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
}
.sidebar .wgt-content .check-list li.selected a::after,
.sidebar .wgt-content .check-list li a:hover::after{
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
}
.sidebar .wgt-content .color-list{
    margin-top: 20px;
    margin-bottom: 24px;
}
.sidebar .wgt-content .color-list li{
    display: inline-block;
    margin-right: 18px;
    margin-bottom: 2px;
}
.sidebar .wgt-content .color-list li a{
    display: inline-block;
    color: inherit;
    line-height: 27px;
    position: relative;
    padding-left: 37px
}
.sidebar .wgt-content .color-list li a .symbol{
    display: inline-block;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
}
.sidebar .wgt-content .color-list li a .hex-code{
    border: 2px solid #e6e6e6;
}
.product-atts .atts-item .img-color,
.sidebar .wgt-content .color-list li a .img-color{
    background: url('../images/color_bg1.jpg');
    background-size: cover;
}
.sumary-product .product-attribute .product-atts .atts-item ul.color-list span.color-02,
.sidebar .wgt-content .color-list li a .color-02{
    background-color: #ffbc53;
}
.sumary-product .product-attribute .product-atts .atts-item ul.color-list span.color-01,
.sidebar .wgt-content .color-list li a .color-01{
    background-color: #ef2727;
}
.sumary-product .product-attribute .product-atts .atts-item ul.color-list span.color-03{
    background-color: #ffffff;
}
.sidebar .price-filter .frm-contain{
    display: inline-block;
    font-size: 0;
    line-height: 0;
    margin-top: 31px;
    padding-bottom: 1px;
}
.sidebar .price-filter .frm-contain .f-item{
    display: inline-block;
    margin: 0 4px 0 0;
    font-size: 16px;
    color: #222222;
    float: left;
}
.sidebar .price-filter .frm-contain .f-item:last-child{
    margin-left: 0;
    margin-right: 9px;
}
.sidebar .price-filter .frm-contain .f-item label{
    margin: 0;
}
.sidebar .price-filter .frm-contain .f-item .input-number{
    border: 2px solid #e6e6e6;
    border-radius: 9999px;
    width: 59px;
    height: 33px;
    text-align: center;
}
.sidebar .price-filter .frm-contain .f-item .btn-submit{
    font-size: 15px;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    outline: none;
    line-height: 1;
    padding: 9px 5px;
    min-width: 59px;
    text-align: center;
    border-radius: 9999px;
}
.sidebar .price-filter .frm-contain .f-item .btn-submit:hover{
    background-color: #444444;
}
.sidebar .wgt-content .products{
    padding: 0;
    display: inline-block;
    width: 100%;
    margin: 26px 0 30px;
}
.sidebar .wgt-content .products li{
    list-style: none;
    display: block;
    clear: both;
    overflow: hidden;
}
.sidebar .wgt-content .products li+li{
    margin-top: 15px;
}
.sidebar .wgt-content .tag-cloud{
    padding: 0;
    display: block;
    margin: 27px 0 20px;
    font-size: 0;
    line-height: 0;
}
.sidebar .wgt-content .tag-cloud li{
    list-style: none;
    display: inline-block;
}
.sidebar .wgt-content .tag-cloud li a{
    display: inline-block;
    color: #888888;
    font-size: 14px;
    line-height: 1;
    border: 2px solid #f0f0f0;
    border-radius: 9999px;
    text-align: center;
    padding: 5px 12px;
    margin-right: 8px;
    margin-bottom: 10px;
}
.sidebar .wgt-content .tag-cloud li a:hover{
    color: #111111;
}
#sidebar .biolife-mobile-panels{
    display: none;
}
.blog-sidebar .widget .wgt-title::after{
    content: none;
}
.search-widget input[type=text]{
    font-size: 13px;
    line-height: 1;
    border: 2px solid #e6e6e6;
    border-radius: 9999px;
    padding: 10px 40px 11px 20px;
    width: 100%;
    height: 49px;
}
.search-widget input[type=text]::-webkit-input-placeholder { /* Edge */
    font-weight: 700;
    text-transform: uppercase;
    line-height: 25px;
}
.search-widget input[type=text]:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-weight: 700;
    text-transform: uppercase;
    line-height: 25px;
}
.search-widget input[type=text]::placeholder {
    font-weight: 700;
    text-transform: uppercase;
    line-height: 25px;
}
.search-widget form{
    display: inline-block;
    width: 100%;
    position: relative;
    font-size: 0;
    line-height: 0;
    border-radius: 9999px;
    overflow: hidden;
}
.search-widget button[type=submit]{
    border: none;
    font-size: 21px;
    padding: 14px 17px 14px 2px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
}
.search-widget .wgt-content{
    font-size: 0;
    line-height: 0;
    padding-bottom: 40px;
}
.posts-widget .wgt-content{
    line-height: 0;
    font-size: 0;
    margin-top: 27px;
    padding-bottom: 30px;
}
.comment-widget .wgt-content ul,
.twitter-widget .wgt-content ul,
.posts-widget .wgt-content ul{
    padding: 0;
    margin: 0;
}
.comment-widget .wgt-content ul li,
.twitter-widget .wgt-content ul li,
.posts-widget .wgt-content ul li{
    list-style: none;
}
.posts-widget .wgt-content ul li+li{
    margin-top: 25px;
}
.twitter-widget .wgt-content ul{
    margin: 38px 0 28px;
}
.twitter-widget .wgt-content ul li+li{
    margin-top: 23px;
}
.comment-widget .wgt-content ul li+li{
    margin-top: 26px;
}
.comment-widget .wgt-content{
    margin-top: 25px;
}
.wgt-twitter-item,
.wgt-post-item{
    display: table;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 0;
    line-height: 1;
}
.wgt-twitter-item .author,
.wgt-twitter-item .detail,
.wgt-post-item .detail,
.wgt-post-item .thumb{
    display: table-cell;
    vertical-align: top;
}
.wgt-post-item .thumb{
    width: 80px;
}
.wgt-twitter-item .detail,
.wgt-post-item .detail{
    padding-left: 10px;
}
.wgt-post-item .detail .post-name{
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: #222222;
    display: block;
    margin: -3px 0 0;
}
.wgt-post-item .detail .post-name a{
    color: inherit;
    font-weight: 700;
}
.wgt-post-item .detail .post-archive{
    display: block;
    font-size: 13px;
    color: #888888;
    margin: 10px 0 0;
    line-height: 13px;
}
.wgt-post-item .detail .post-archive .comment{
    margin-left: 17px;
}
.wgt-twitter-item .author{
    width: 38px;
}
.wgt-twitter-item .detail .account-info{
    display: block;
    margin: 0;
    font-size: 0;
}
.wgt-twitter-item .detail .account-info .ath-name,
.wgt-twitter-item .detail .account-info .ath-taglink{
    display: inline-block;
    font-size: 15px;
    line-height: 15px;
    color: #cccccc;
    font-weight: 600;
}
.wgt-twitter-item .detail .account-info .ath-name{
    color: #222222;
    margin-right: 7px;
}
.wgt-twitter-item .detail .tweet-content{
    font-size: 15px;
    line-height: 24px;
    color: #666666;
    margin: 7px 0 0;
}
.wgt-twitter-item .detail .tweet-content a:hover{
    color: #333333;
}
.wgt-twitter-item .detail .tweet-count{
    margin-top: 2px;
}
.wgt-twitter-item .detail .tweet-count .btn{
    display: inline-block;
    font-size: 13px;
    line-height: 24px;
    color: #666666;
    padding: 0;
    margin-right: 37px;
}
.wgt-twitter-item .detail .tweet-count .btn i{
    margin-right: 6px;
}
.wgt-twitter-item .detail .viewall{
    display: block;
    margin: 21px 0 5px;
    font-size: 0;
    line-height: 0;
}
.wgt-twitter-item .detail .viewall a{
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    color: #cccccc;
    position: relative;
    font-weight: 700;
    text-transform: uppercase;
}
.wgt-twitter-item .detail .viewall a::before{
    content: '';
    display: block;
    height: 2px;
    background-color: #cccccc;
    bottom: -3px;
    left: 0;
    position: absolute;
    right: -1px;
}
.cmt-item{
    display: block;
    font-size: 0;
    line-height: 0;
    color: #666666;
    margin: 0;
}
.cmt-item a{
    display: inline-block;
    font-size: 15px;
    line-height: 16px;
    color: inherit;
    font-weight: 400;
}
.cmt-item .link-post{
    color: #333333;
    font-weight: 600;
    margin-left: 4px;
}
.cmt-item a .biolife-icon{
    margin-right: 7px;
}
.top-functions-area + div{
    clear: both;
}
.top-functions-area{
    display: block;
    width: 100%;
    border-bottom: 2px solid #e6e6e6;
    padding-bottom: 13px;
    margin-bottom: 30px;
}
.top-functions-area .flt-item,
.top-functions-area .wrap-selectors{
    display: inline-block;
}
.top-functions-area .flt-item.to-right{
    float: right;
}
.top-functions-area .flt-item .icon-for-mobile{
    display: none;
    font-size: 0;
    line-height: 0;
    vertical-align: bottom;
    padding: 7px 13px;
    background-color: #f0f0f0;
}
.top-functions-area .flt-item .btn-for-mobile{
    display: none;
    width: 100%;
    margin: 0;
    padding-top: 20px;
}
.top-functions-area .flt-item .btn-for-mobile button{
    font-size: 16px;
    color: #ffffff;
    background: #555555;
    border: none;
    padding: 12px 17px 12px;
    line-height: 20px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
}
.top-functions-area .flt-item .icon-for-mobile span{
    width: 3px;
    height: 3px;
    display: inline-block;
    float: left;
    clear: both;
    margin: 1px 0;
    background-color: #888888;
}
.top-functions-area .flt-item .title-for-mobile{
    display: none;
    width: 100%;
    font-size: 17px;
    color: #333333;
    font-weight: 700;
    text-align: center;
}
.top-functions-area .flt-item .flt-title{
    display: inline-block;
    font-size: 15px;
    color: #333333;
    float: left;
    margin-right: 10px;
    margin-top: 3px;
    font-weight: 600;
}
.top-functions-area .flt-item .nice-select{
    display: inline-block;
    border: 2px solid #e6e6e6;
    border-radius: 9999px;
    background-color: #ffffff;
    padding: 0 30px 0 20px;
}
.top-functions-area .flt-item .nice-select:after{
    color: #9b9b9b;
    right: 15px;
    margin-top: -7px;
}
.top-functions-area .flt-item .nice-select .current{
    font-weight: 600;
    font-size: 15px;
    color: #222222;
    padding: 5px 0 9px;
}
.top-functions-area .flt-item .selector-item{
    display: inline-block;
    float: left;
}
.top-functions-area .flt-item .selector-item + .selector-item{
    margin-left: 9px;
}
.top-functions-area .viewmode-selector{
    display: inline-block;
    font-size: 0;
    line-height: 0;
}
.top-functions-area .viewmode-selector .viewmode{
    color: #888888;
    font-size: 20px;
    display: inline-block;
    float: left;
    margin-left: 24px;
    margin-top: 5px;
}
.top-functions-area .viewmode-selector a.detail-mode{
    font-size: 17px;
    margin-top: 7px;
    margin-left: 12px;
}
.biolife-panigations-block{
    display: block;
    clear: both;
    border-top: 1px solid #e6e6e6;
    padding-top: 39px;
    margin-top: 20px;
    margin-bottom: 80px;
}
.biolife-panigations-block ul{
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 0;
    line-height: 0;
}
.biolife-panigations-block ul li{
    list-style: none;
    display: inline-block;
    vertical-align: middle;
}
.biolife-panigations-block ul li+li{
    margin-left: 5px;
}
.biolife-panigations-block ul li a,
.biolife-panigations-block ul li span{
    font-size: 14px;
    line-height: 31px;
    color: #666666;
    min-width: 41px;
    border: 1px solid #e6e6e6;
    display: inline-block;
    border-radius: 9999px;
}
.biolife-panigations-block ul li a.next,
.biolife-panigations-block ul li a.prev{
    border-width: 2px;
    font-size: 18px;
    line-height: 29px;
    width: 55px;
}
.biolife-panigations-block ul li a.next{
    margin-left: 20px;
}
.biolife-panigations-block ul li a.prev{
    margin-right: 15px;
}
.biolife-panigations-block ul li a:hover,
.biolife-panigations-block ul li .current-page{
    color: #ffffff;
}
.biolife-panigations-block ul li .sep{
    border-color: transparent;
    width: auto;
    min-width: auto;
    padding-right: 12px;
    margin-left: -3px;
}
.biolife-panigations-block.version-2{
    margin: 0;
    border: none;
    padding-top: 49px;
    padding-bottom: 7px;
}
.biolife-panigations-block.version-2 ul{
    text-align: left;
    display: inline-block;
}
.biolife-panigations-block.version-2 ul li{
    text-align: center;
}
.biolife-panigations-block.version-2 .result-count{
    display: inline-block;
    float: right;
    font-size: 0;
}
.biolife-panigations-block.version-2 .result-count .txt-count{
    font-size: 14px;
    line-height: 34px;
    color: #333333;
    display: inline-block;
    margin: 0 41px 0 0;
}
.biolife-panigations-block.version-2 .result-count .txt-count b{
    font-size: 15px;
    font-weight: 700;
}
.biolife-panigations-block.version-2 .result-count a{
    font-size: 15px;
    line-height: 34px;
    color: #333333;
    display: inline-block;
    font-weight: 600;
}
.biolife-panigations-block.version-2 .result-count a i{
    margin-left: 6px;
    display: inline-block;
}
.background-footer-03{
    background-color: #fafafa;
}
.mobile-block-global{
    display: none;
    position: fixed;
    top: 0;
    left: -340px;
    bottom: 0;
    width: 290px;
    background-color: #ffffff;
    z-index: 999;
    -webkit-box-shadow: 3px 5px 14px -3px rgba(0,0,0,0.11);
    -moz-box-shadow: 3px 5px 14px -3px rgba(0,0,0,0.11);
    box-shadow: 3px 5px 14px -3px rgba(0,0,0,0.11);
    -webkit-transition: all 320ms ease;
    -moz-transition: all 320ms ease;
    -o-transition: all 320ms ease;
    transition: all 320ms ease;
}
.mobile-block-global .block-global-contain{
    display: block;
    height: calc(100% - 50px );
    overflow-y: auto;
    padding: 0 15px;
}
.mobile-block-global .glb-item{
    display: block;
    padding-top: 17px;
}
.mobile-block-global .glb-item + .glb-item{
    border-top: 1px solid #e6e6e6;
}
.mobile-block-global .glb-item .title{
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 10px;
}
.mobile-block-global .glb-item ul{
    padding: 0;
    margin: 0 0 20px;
    display: block;
}
.mobile-block-global .glb-item ul:not(.inline) li{
    list-style: none;
    display: block;
    padding: 5px 0;
}
.mobile-block-global .glb-item ul.inline{
    display: inline-block;
    width: 100%;
    font-size: 0;
    line-height: 0;
    margin-top: 11px;
}
.mobile-block-global .glb-item ul.inline li{
    list-style: none;
    display: inline-block;
    float: left;
}
.mobile-block-global .glb-item ul.inline li:not(:last-child){
    margin-right: 10px;
}
.mobile-block-global .glb-item ul li a{
    color: #888888;
    display: inline-block;
}
.sumary-product .media{
    display: inline-block;
    float: left;
    width: 38.462%;
}
.sumary-product .media ul{
    padding: 0;
    margin: 0;
}
.sumary-product .media ul li{
    list-style: none;
}
.sumary-product .media .slider-nav{
    margin-top: 10px;
    max-width: 390px;
}
.sumary-product .media .slider-nav li img{
    display: inline-block;
    width: 90px;
    border: 1px solid #f0f0f0;
    -webkit-transition:all 350ms ease;
    -moz-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    transition: all 350ms ease;
}
.sumary-product .media .slider-nav li.slick-current img{
    border-width: 2px;
}
.sumary-product .product-attribute{
    display: inline-block;
    float: left;
    width: calc( 100% - 38.462% - 270px );
    padding-left: 20px;
    padding-top: 13px;
}
.sumary-product .product-attribute .title{
    font-size: 20px;
    line-height: 24px;
    color: #222222;
    font-weight: 600;
    display: block;
    margin: 0;
    max-width: 329px;
}
.sumary-product .product-attribute .rating{
    display: block;
    line-height: 0;
    margin-top: 10px;
}
.sumary-product .product-attribute .category,
.sumary-product .product-attribute .qa-text,
.sumary-product .product-attribute .review-count{
    display: inline-block;
    font-size: 14px;
    color: #555555;
    line-height: 24px;
    vertical-align: top;
    margin-left: 1px;
}
.sumary-product .product-attribute .qa-text{
    font-size: 15px;
    color: #666666;
    margin-left: 17px;
}
.sumary-product .product-attribute .category{
    font-size: 15px;
    color: #222222;
    font-weight: 400;
    margin-left: 17px;
}
.sumary-product .product-attribute .sku{
    font-size: 15px;
    color: #666666;
    display: inline-block;
    margin: 13px 0 0;
    line-height: 1;
}
.sumary-product .product-attribute .excerpt{
    display: block;
    color: #666666;
    font-size: 14px;
    line-height: 20px;
    margin: 15px 0 0;
    max-width: 352px;
}
.sumary-product .product-attribute .price{
    margin-top: 23px;
}
.sumary-product .product-attribute .product-atts{
    display: inline-block;
    border-top: 1px solid #e6e6e6;
    margin-top: 23px;
}
.sumary-product .product-attribute .product-atts .atts-item{
    display: table;
    width: 100%;
    max-width: 368px;
    border-bottom: 1px solid #e6e6e6;
    margin-top: 15px;
    padding-bottom: 12px;
}
.sumary-product .product-attribute .product-atts .atts-item .title{
    display: table-cell;
    font-size: 18px;
    color: #333333;
    font-weight: 600;
}
.sumary-product .product-attribute .product-atts .atts-item ul{
    padding: 0  0 0 10px;
    margin: 0;
    font-size: 0;
    line-height: 0;
    display: table-cell;
}
.sumary-product .product-attribute .product-atts .atts-item ul.color-list a{
    display: inline-block;
    color: inherit;
    position: relative;
    padding-left: 37px;
}
.sumary-product .product-attribute .product-atts .atts-item ul.color-list .hex-code{
    border: 2px solid #e6e6e6;
}
.sumary-product .product-attribute .product-atts .atts-item ul.color-list .symbol{
    display: inline-block;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: 0;
    background-color: #ededed;
}
.sumary-product .product-attribute .product-atts .atts-item ul li{
    list-style: none;
    display: inline-block;
    font-size: 14px;
    line-height: 34px;
    color: #444444;
    margin-right: 18px;
    margin-bottom: 2px;
}
.sumary-product .product-attribute .product-atts .atts-item ul .inline-list li{
    padding: 0 5px;
}
.sumary-product .product-attribute .product-atts .atts-item ul li a{
    color: inherit;
}
.sumary-product .shipping-info{
    margin-top: 10px;
    padding-bottom: 5px;
}
.sumary-product .shipping-info p{
    text-align: left;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}
.sumary-product .shipping-info p.for-today{
    color: #666666;
    margin-top: 2px;
}
.sumary-product .action-form{
    display: inline-block;
    width: 270px;
    background-color: #f3f3f3;
    padding: 11px 20px 34px;
}
.sumary-product .action-form .title{
    font-size: 15px;
    color: #222222;
    font-weight: 600;
    display: block;
    margin-bottom: 3px;
}
.quantity-box .qty-input,
.sumary-product .action-form .qty-input{
    display: inline-block;
    font-size: 0;
    line-height: 0;
    position: relative;
}
.quantity-box .qty-input input,
.sumary-product .action-form .qty-input input{
    border: none;
    background-color: #ffffff;
    font-size: 16px;
    width: 119px;
    padding: 7px 33px 6px 10px;
    color: #000000;
    text-align: center;
    border-radius: 9999px;
    height: 43px;
}
.quantity-box .qty-input .qty-btn,
.sumary-product .action-form .qty-input .qty-btn{
    display: inline-block;
    color: #888888;
    font-size: 16px;
    line-height: 1;
    position: absolute;
    right: 16px;
    top: 4px;
    width: 16px;
    text-align: center;
}
.quantity-box .qty-input .qty-btn.btn-down,
.sumary-product .action-form .qty-input .qty-btn.btn-down{
    top: 23px;
}
.sumary-product .action-form .total-price-contain{
    display: block;
    margin-top: 14px;
}
.sumary-product .action-form .total-price-contain .title{
    text-transform: uppercase;
    color: #444444;
}
.sumary-product .action-form .total-price-contain .price{
    font-size: 26px;
    color: #222222;
    text-transform: uppercase;
    display: block;
    margin: 11px 0 0;
    font-weight: 700;
}
.sumary-product .action-form .buttons{
    display: block;
    text-align: center;
    border-top: 2px solid #e6e6e6;
    margin-top: 17px;
    padding-top: 20px;
}
.sumary-product .action-form .buttons .add-to-cart-btn{
    font-size: 15px;
    line-height: 24px;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    border-radius: 999px;
    border: none;
    padding: 12px 10px 11px;
}
.sumary-product .action-form .buttons .add-to-cart-btn:hover{
    background-color: #444444;
}
.sumary-product .action-form .buttons .pull-row{
    display: block;
    text-align: center;
    font-size: 0;
    line-height: 0;
    margin-top: 9px;
    margin-bottom: 0;
}
.sumary-product .action-form .buttons .pull-row .btn{
    display: inline-block;
    font-size: 13px;
    text-align: center;
    color: #666666;
    line-height: 24px;
    position: relative;
    padding: 0 0 0 18px;
    border-radius: 0;
}
.sumary-product .action-form .buttons .pull-row .btn+.btn{
    margin-left: 55px;
}
.sumary-product .action-form .buttons .pull-row .btn:first-letter{
    text-transform: uppercase;
}
.sumary-product .action-form .buttons .pull-row .btn::before{
    content: '';
    background-color: #666666;
    width: 10px;
    height: 2px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -1px;
}
.sumary-product .action-form .buttons .pull-row .btn::after{
    content: '';
    background-color: #666666;
    width: 2px;
    height: 10px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 4px;
    margin-top: -5px;
}
.sumary-product .action-form .social-media,
.sumary-product .action-form .location-shipping-to{
    display: inline-block;
    width: 100%;
}
.sumary-product .action-form .location-shipping-to{
    margin-top: 20px;
}
.sumary-product .action-form .location-shipping-to .nice-select{
    width: 100%;
    background-color: #ffffff;
    padding-left: 20px;
    padding-right: 32px;
    border-radius: 9999px;
}
.sumary-product .action-form .location-shipping-to .nice-select .current{
    font-size: 13px;
    color: #888888;
    padding: 15px 0;
}
.sumary-product .action-form .location-shipping-to .nice-select:after{
    color: #888888;
    right: 19px;
}
.sumary-product .action-form .acepted-payment-methods ul,
.sumary-product .action-form .social-media ul{
    padding: 0;
    margin: 0;
    font-size: 0;
    line-height: 0;
}
.sumary-product .action-form .social-media{
    margin-top: 13px;
}
.sumary-product .action-form .social-media ul li{
    list-style: none;
    display: inline-block;
    font-size: 20px;
    line-height: 14px;
    color: #aaaaaa;
    padding: 6px;
}
.sumary-product .action-form .social-media ul li:not(:last-child){
    margin-right: 16px;
}
.sumary-product .action-form .social-media ul li a{
    color: inherit;
}
.sumary-product .action-form .acepted-payment-methods{
    border-top: 1px solid #e6e6e6;
    padding-top: 15px;
    margin-top: 10px;
}
.sumary-product .action-form .acepted-payment-methods ul li{
    font-size: 0;
    line-height: 0;
    list-style: none;
    display: inline-block;
    width: 38px;
}
.sumary-product .action-form .acepted-payment-methods ul li:not(:last-child){
    margin-right: 14px;
}
.single-layout.product-related-box,
.single-layout.product-tabs{
    display: block;
    clear: both;
}
.single-layout.product-tabs{
    margin-top: 191px;
}
.product-tabs.single-layout .tab-head .tabs{
    display: block;
    padding: 9px 0;
    margin: 0;
    font-size: 0;
    line-height: 0;
    border-bottom: 2px  solid #e6e6e6;
}
.product-tabs.single-layout .tab-head .tabs li{
    display: inline-block;
    list-style: none;
}
.product-tabs.single-layout .tab-head .tabs li:not(:last-child){
    margin-right: 43px;
}
.product-tabs.single-layout .tab-head .tabs li a{
    color: #bbbbbb;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    -webkit-transition: all 350ms ease;
    -moz-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    transition: all 350ms ease;
    position: relative;
}
.product-tabs.single-layout .tab-head .tabs li a::before{
    content: '';
    position: absolute;
    height: 3px;
    width: 0;
    display: block;
    bottom: -4px;
    left: 0;
    -webkit-transition: all 550ms ease;
    -moz-transition: all 550ms ease;
    -o-transition: all 550ms ease;
    transition: all 550ms ease;
}
.product-tabs.single-layout .tab-head .tabs li a:hover{
    color: #222222;
}
.product-tabs.single-layout .tab-head .tabs li.active a{
    font-weight: 700;
    color: #222222;
}
.product-tabs.single-layout .tab-head .tabs li.active a::before{
    width: 127px;
}
.product-tabs.single-layout .tab-content{
    position: relative;
    display: inline-block;
    width: 100%;
}
.product-tabs.single-layout .tab-content .tab-contain{
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    border-color: #ffffff;
    padding-top: 28px;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 52px;
}
.product-tabs.single-layout .tab-content .tab-contain.review-tab{
    padding-top: 33px;
}
.product-tabs.single-layout .tab-content .tab-contain.active{
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    position: static;
}
.product-tabs .tab-content p{
    font-size: 15px;
    line-height: 24px;
    color: #666666;
}
.product-tabs .tab-content .desc-expand{
    display: block;
    margin-top: 26px;
}
.product-tabs .tab-content .desc-expand .title{
    font-size: 15px;
    line-height: 24px;
    color: #333333;
    margin: 0;
    font-weight: 600;
}
.product-tabs .tab-content .desc-expand ul{
    padding: 0;
    margin: 16px 0 0;
    font-size: 0;
    line-height: 0;
    display: block;
}
.product-tabs .tab-content .desc-expand ul li{
    list-style: none;
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
    padding-left: 13px;
    position: relative;
}
.product-tabs .tab-content .desc-expand ul li::before{
    content: "\f111";
    font-family: 'FontAwesome', sans-serif;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-size: 7px;
    line-height: 1;
    color: #666666;
    position: absolute;
    top: 9px;
    left: 0
}
.product-tabs .addtional-info-tab table p{
    margin: 0;
}
.product-tabs .review-form-wrapper{
    display: inline-block;
    width: 100%;
    padding-top: 17px;
}
.product-tabs .review-form-wrapper .title{
    font-size: 14px;
    color: #222222;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
}
.accodition-tab>ul{
    padding: 0;
}
.accodition-tab>ul>li{
    list-style: none;
    display: block;
    padding-left: 20px;
    margin-bottom: 10px;
}
.accodition-tab>ul>li>.content{
    padding: 10px 10px 15px;
    display: none;
}
.accodition-tab>ul>li>.title{
    color: #333;
    font-weight: 600;
    font-size: 16px;
    position: relative;
    cursor: help;
}
.accodition-tab>ul>li>.title::before{
    content: '';
    width: 10px;
    height: 2px;
    background-color: #444444;
    position: absolute;
    display: inline-block;
    top: 14px;
    left: -15px;
}
.accodition-tab>ul>li>.title::after{
    content: '';
    height: 10px;
    width: 2px;
    background-color: #444444;
    position: absolute;
    display: inline-block;
    top: 10px;
    left: -11px;
}
.accodition-tab>ul>li.active>.title::after{
    content: none;
}
.comment-form-rating{
    display: block;
    margin: 7px 0 10px;
}
.comment-form-rating label{
    font-size: 14px;
    line-height: 30px;
    color: #222222;
    display: inline-block;
    margin: 0;
}
.comment-form-rating .stars span,
.comment-form-rating .stars{
    display: inline-block;
    margin: 0;
    font-size: 0;
    line-height: 0;
}
.comment-form-rating .stars{
    margin-left: 5px;
}
.comment-form-rating .stars span a{
    font-size: 15px;
    line-height: 1;
    color: #666666;
    display: inline-block;
    padding: 1px;
    -webkit-transition: color 350ms ease;
    -moz-transition: color 350ms ease;
    -o-transition: color 350ms ease;
    transition: color 350ms ease;
}
.comment-form-rating .stars span.rated a{
    color: #ffbc53;
}
.comment-form-rating .stars span a.selected ~ a{
    color: #666666;
}
.comment-form-rating .stars span:hover a{
    color: #ffbc53 !important;
}
.comment-form-rating .stars span a:hover ~ a{
    color: #666666 !important;
}
.review-form-wrapper .form-row{
    display: inline-block;
    float: left;
    width: 100%;
    margin-bottom: 20px;
}
.review-form-wrapper .wide-half{
    width: calc( 50% - 15px );
}
.review-form-wrapper .wide-half:nth-last-child(odd){
    margin-left: 30px;
}
.review-form-wrapper .form-row textarea,
.review-form-wrapper .form-row input{
    font-size: 14px;
    display: inline-block;
    width: 100%;
    border: 1px solid #e6e6e6;
    color: #444444;
    padding: 9px 14px 8px;
    line-height: 1;
    background-color: #ffffff;
}
.review-form-wrapper .form-row input{
    height: 45px;
}
.review-form-wrapper .form-row textarea{
    height: 140px;
}
.review-form-wrapper .form-row button[type=submit]{
    font-size: 14px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 700;
    border: none;
    outline: none;
    border-radius: 9999px;
    min-width: 162px;
    text-align: center;
    padding: 10px 20px;
}
.review-form-wrapper .form-row button[type=submit]:hover{
    background-color: #444444;
}
.review-tab #comments{
    display: block;
    clear: both;
    border-top: 2px solid #f1f1f1;
    margin-top: 39px;
}
.review-tab #comments .commentlist{
    padding: 0;
}
.review-tab #comments .commentlist>li{
    list-style: none;
    display: block;
    border-bottom: 1px solid #f1f1f1;
    padding-top: 20px;
    padding-bottom: 26px;
}
.review-tab #comments .comment-content .comment-in{
    display: block;
    margin: 1px 0 0;
}
.review-tab #comments .comment-content .comment-in .post-name{
    font-size: 18px;
    line-height: 30px;
    color: #222222;
    font-weight: 600;
    display: inline-block;
}
.review-tab #comments .comment-content .comment-in .post-date{
    font-size: 15px;
    line-height: 30px;
    color: #666666;
    display: inline-block;
    float: right;
}
.review-tab #comments .comment-content .author{
    font-size: 16px;
    line-height: 1;
    color: #666666;
    margin: 2px 0 25px;
}
.review-tab #comments .comment-content .author b{
    color: #333333;
    font-weight: 600;
}
.review-tab #comments .comment-content .comment-text{
    font-size: 15px;
    color: #666666;
    line-height: 18px;
}
.review-tab #comments .comment-review-form .title{
    font-size: 15px;
    line-height: 30px;
    color: #333333;
    font-weight: 600;
    display: block;
    margin: 0;
}
.review-tab #comments .comment-review-form .actions{
    padding: 0;
    font-size: 0;
    line-height: 0;
    margin-top: 17px;
}
.review-tab #comments .comment-review-form .actions li{
    list-style: none;
    display: block;
}
.review-tab #comments .comment-review-form .actions li a{
    font-size: 14px;
    line-height: 30px;
    display: inline-block;
    color: #666666;
}
.review-tab #comments .comment-review-form .actions li a i{
    margin-right: 7px;
}
.rating-info{
    max-width: 370px;
}
.product-tabs .rating-info .index{
    display: block;
    font-size: 14px;
    color: #666666;
    margin-bottom: 4px;
}
.rating-info .index .rating{
    color: #222222;
    font-size: 40px;
    font-weight: 600;
    line-height: 1;
    display: inline-block;
    margin-right: 6px;
}
.rating-info .see-all{
    font-size: 15px;
    color: #666666;
    display: block;
    margin: 0;
}
.rating-info .options{
    padding: 0;
    font-size: 0;
    line-height: 0;
    margin: 13px 0 0;
}
.rating-info .options li{
    list-style: none;
    display: block;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
}
.rating-info .options li .detail-for{
    display: table;
    width: 100%;
}
.rating-info .options li .detail-for .option-name,
.rating-info .options li .detail-for .progres,
.rating-info .options li .detail-for .number{
    display: table-cell;
    vertical-align: middle;
}
.rating-info .options li .detail-for .option-name{
    width: 49px;
    padding-left: 2px;
    text-align: left;
}
.rating-info .options li .detail-for .number{
    width: 39px;
    text-align: center;
}
.rating-info .options .detail-for .line-100percent{
    display: inline-block;
    width: 100%;
    font-size: 0;
    line-height: 0;
    border-radius: 9999px;
    background-color: #f5f5f5;
    overflow: hidden;
}
.rating-info .options .detail-for .line-100percent .percent{
    height: 9px;
    display: inline-block;
    background-color: #ffdd5a;
    border-radius: 9999px;
}
.rating-info .rating .star-rating > span::before,
.rating-info .rating .star-rating::before{
    font-size: 20px;
    letter-spacing: 4px;
}
.single-layout.product-related-box{
    margin-top: 60px;
    margin-bottom: 50px;
}
.product-related-box ul{
    padding: 0;
    margin: 0;
    clear: both;
}
.product-related-box .biolife-title-box{
    margin-bottom: 31px;
}
.biolife-title-box{
    text-align: center;
}
.biolife-title-box .biolife-icon{
    display: inline-block;
    margin: 0;
    font-size: 35px;
}
.biolife-title-box .subtitle{
    display: block;
    margin: 6px 0 0;
    font-size: 15px;
    color: #666666;
    line-height: 1;
    font-family: 'Playfair Display', serif;
    font-style: italic;
}
.biolife-title-box .main-title{
    font-size: 30px;
    font-weight: 700;
    color: #222222;
    display: block;
    margin: 12px 0 0;
    line-height: 1;
}
.biolife-title-box.style-02 .subtitle{
    font-family: 'Cairo', sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    margin: 0;
    line-height: 1;
    font-style: normal;
}
.biolife-title-box.style-02 .main-title{
    font-weight: 700;
    line-height: 1;
    display: block;
    margin: 4px 0 0;
    font-size: 40px;
    color: #222222;
}
.biolife-title-box.style-02 .desc{
    font-size: 15px;
    color: #666666;
    font-family: 'Playfair Display', sans-serif;
    font-style: italic;
    display: inline-block;
    line-height: 18px;
    margin: 12px 0 0;
    max-width: 415px;
}
.biolife-title-box.link-all{
    text-align: left;
}
.biolife-title-box.link-all .main-title{
    font-size: 40px;
    color: #222222;
    display: inline-block;
    line-height: 1;
    margin: 0;
}
.biolife-title-box.link-all .blog-link{
    display: inline-block;
    float: right;
    font-size: 14px;
    color: #666666;
    line-height: 1;
    margin: 17px 0 0;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
}
.biolife-title-box.link-all .blog-link::after{
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: -3px;
    height: 2px;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #bcbcbc;
}
.biolife-title-box.slim-item .subtitle{
    margin: 0;
}
.biolife-title-box.slim-item .main-title{
    margin-top: 6px;
}
.biolife-title-box_in-countdown{
    text-align: left;
    padding: 13px 18px 6px;
}
.biolife-title-box_in-countdown .title{
    display: block;
    margin: 0;
    font-size: 20px;
    color: #000000;
    font-weight: 700;
    line-height: 1;
}
.rating .star-rating{
    display: inline-block;
    margin: 0;
    overflow: hidden;
    line-height: 24px;
    position: relative;
}
.rating .star-rating > span::before,
.rating .star-rating::before{
    display: inline-block;
    float: left;
    font-family: FontAwesome, sans-serif;
    content: "\f006\f006\f006\f006\f006";
    font-size: 15px;
    color: #666666;
    letter-spacing: 3px
}
.rating .star-rating > span{
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    white-space: nowrap;
    bottom: 0;
}
.rating .star-rating > span::before{
    color: #ffbc53;
}
.background-top-banner-for-shopping{
    background: url(../images/top_banner_shopping_cart.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.top-banner{
    color: #ffffff;
    text-align: center;
    margin-top: -9px;
    padding-top: 57px;
    padding-bottom: 35px;
    margin-bottom: 34px;
}
.top-banner .title{
    font-size: 18px;
    color: #ffffff;
    font-weight: 700;
    display: block;
    margin: 0;
}
.top-banner .subtitle{
    font-size: 15px;
    display: block;
    margin: 7px 0 0;
}
.top-banner ul{
    padding: 0;
    display: inline-block;
    margin: 12px 0 0;
}
.top-banner ul li{
    list-style: none;
    display: block;
    text-align: left;
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
    padding: 0 61px;
}
.top-banner ul li:last-child{
    border-top: 1px solid rgba(255,255,255,0.55);
}
.top-banner ul li .cost{
    float: right;
    font-weight: 600;
    margin-left: 35px;
}
.top-banner .btns{
    margin: 29px 0 0;
    display: block;
    font-size: 0;
    line-height: 0;
}
.top-banner .btns .btn{
    background-color: #ffffff;
    color: #444444;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    border: none;
    border-radius: 9999px;
    text-transform: uppercase;
    padding: 9px 23px;
}
.top-banner .btns .btn+.btn{
    margin-left: 10px;
}
.top-banner .btns .btn:hover{
    color: #ffffff;
    background-color: #666666;
    -webkit-transition: background-color 150ms ease;
    -moz-transition: background-color 150ms ease;
    -o-transition: background-color 150ms ease;
    transition: background-color 150ms ease;
}
.shopping-cart-container .box-title{
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #222222;
    margin: 0 0 12px;
}
.shopping-cart-container table{
    text-align: center;
    border: none;
    margin: 0;
}
.shopping-cart-container table tr{
    border-top: 1px solid #e6e6e6;
}
.shopping-cart-container table td{
    border: none;
    padding: 20px 0 30px 0;
}
.shopping-cart-container table th{
    font-size: 14px;
    color: #222222;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    border: 2px solid #e6e6e6;
    padding: 13px 10px 12px;
}
.shopping-cart-container table th.product-name{
    width: 346px;
}
.shopping-cart-container table td.product-thumbnail{
    text-align: left;
    position: relative;
    font-size: 0;
    line-height: 0;
}
.shopping-cart-container table td.product-thumbnail .prd-thumb{
    display: inline-block;
}
.shopping-cart-container table td.product-thumbnail .prd-name{
    font-size: 17px;
    font-weight: 600;
    color: #222222;
    display: inline-block;
    width: calc(100% - 115px);
    padding-left: 10px;
    line-height: 1;
    vertical-align: middle;
}
.shopping-cart-container table td.product-thumbnail .action{
    display: inline-block;
    background-color: rgba(255,255,255,0.7);
    position: absolute;
    bottom: 30px;
    left: 113px;
    padding-left: 5px;
    z-index: 5;
}
.shopping-cart-container table td.product-thumbnail .action a{
    font-size: 16px;
    display: inline-block;
    color: #444444;
    line-height: 16px;
    padding: 0 5px;
}
.shopping-cart-container table td.product-thumbnail .action a:hover{
    color: #e73918;
}
.shopping-cart-container table td.wrap-btn-control{
    padding: 29px 0 0;
    font-size: 0;
    line-height: 0;
    text-align: right;
}
.shopping-cart-container table td.wrap-btn-control .btn{
    display: inline-block;
    background-color: #eeeeee;
    border: none;
    border-radius: 9999px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    padding: 16px 10px 15px;
    min-width: 154px;
    text-align: center;
}
.shopping-cart-container table td.wrap-btn-control a.back-to-shop{
    float: left;
    min-width: 184px;
}
.shopping-cart-container table td.wrap-btn-control .btn-clear{
    margin-left: 20px;
    color: #888888 !important;
    background-color: #e2e2e2 !important;
}
.shopping-cart-container table td.wrap-btn-control .btn:not(:disabled):hover{
    background-color: #444444 !important;
    color: #ffffff !important;
}
.shopping-cart-container table td.wrap-btn-control button:disabled{
    color: #888888 !important;
    background-color: #e2e2e2 !important;
    opacity: 1;
    filter: alpha(opacity=100);
}
.shpcart-subtotal-block{
    background-color: #fafafa;
    padding: 19px 15px 52px;
    margin-top: 13px;
}
.order-summary .subtotal-line,
.shpcart-subtotal-block .btn-checkout,
.shpcart-subtotal-block .tax-fee,
.shpcart-subtotal-block .subtotal-line{
    display: inline-block;
    font-size: 0;
    line-height: 0;
    width: 100%;
}
.shpcart-subtotal-block .subtotal-line + .subtotal-line{
    margin-top: 20px;
}
.order-summary .subtotal-line .stt-name,
.shpcart-subtotal-block .subtotal-line .stt-name{
    display: inline-block;
    font-size: 14px;
    line-height: 30px;
    color: #222222;
    font-weight: 700;
    text-transform: uppercase;
}
.order-summary .subtotal-line .stt-name .sub,
.shpcart-subtotal-block .subtotal-line .stt-name .sub{
    font-size: 15px;
    color: #666666;
    text-transform: initial;
    font-weight: 400;
}
.order-summary .subtotal-line .stt-price,
.shpcart-subtotal-block .subtotal-line .stt-price{
    font-size: 18px;
    line-height: 30px;
    color: #111111;
    font-weight: 700;
    display: inline-block;
    float: right;
}
.shpcart-subtotal-block .tax-fee{
    border-bottom: 1px solid #e6e6e6;
    margin-top: 29px;
    padding-bottom: 33px;
}
.shpcart-subtotal-block .tax-fee p{
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #222222;
    margin: 0;
}
.shpcart-subtotal-block .tax-fee .title{
    text-transform: uppercase;
}
.shpcart-subtotal-block .btn-checkout{
    margin-top: 40px;
}
.shpcart-subtotal-block .btn-checkout .btn{
    border: none;
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    clear: both;
    border-radius: 9999px;
    background-color: #ababab;
    padding: 13px 15px 12px;
    font-weight: 700;
}
.shpcart-subtotal-block .btn-checkout .btn:hover{
    background-color: #444444 !important;
}
.shpcart-subtotal-block .biolife-progress-bar{
    margin-top: 39px;
}
.biolife-progress-bar table,
.biolife-progress-bar table tr,
.biolife-progress-bar table td{
    border: none;
    padding: 0;
}
.biolife-progress-bar table td.mid-position{
    width: 100%;
    padding-left: 5px;
    padding-right: 7px;
}
.biolife-progress-bar .progress{
    margin: 0;
    background-color: #dadada;
    border-radius: 9999999px;
    height: auto;
    overflow: hidden;
}
.biolife-progress-bar .progress .progress-bar{
    height: 9px;
    line-height: 0;
    font-size: 0;
    box-shadow: none;
    border-top-right-radius: 9999999px;
    border-bottom-right-radius: 9999999px;
    position: relative;
}
.biolife-progress-bar .progress .progress-bar::before{
    content: '';
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    border-radius: 9999999px;
    z-index: 5;
    position: absolute;
}
.biolife-progress-bar .progress .progress-bar::after{
    content: '';
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    right: -2px;
    background-color: #ffffff;
    border-radius: 9999999px;
    z-index: 2;
    position: absolute;
}
.biolife-progress-bar .index{
    font-size: 14px;
    line-height: 24px;
    color: #222222;
    font-weight: 700;
}
.shpcart-subtotal-block .pickup-info{
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
    margin: 22px 0 0;
}
.shpcart-subtotal-block .pickup-info b{
    font-weight: 400;
    color: #222222;
}
.checkout-progress-wrap{
    max-width: 600px;
}
.checkout-progress-wrap .steps{
    padding: 0;
    display: block;
}
.checkout-progress-wrap .steps li{
    list-style: none;
    display: block;
    padding: 40px 0 38px;
}
.checkout-progress-wrap .steps li+li{
    border-top: 2px solid #e6e6e6;
    margin-top: 2px;
}
.checkout-progress-wrap .checkout-act{
    display: block;
}
.checkout-progress-wrap .checkout-act .title-box{
    display: inline-block;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin: 0;
    color: #222222;
}
.checkout-progress-wrap .checkout-act.active .title-box .number{
    color: #ffffff;
}
.checkout-progress-wrap .checkout-act .title-box .number{
    display: inline-block;
    border: 0;
    background-color: #dddddd;
    color: #333333;
    font-size: 18px;
    line-height: inherit;
    border-radius: 50%;
    vertical-align: middle;
    min-width: 41px;
    padding: 6px 10px 5px;
    text-align: center;
    margin-right: 29px;
}
.checkout-progress-wrap .checkout-act .box-content{
    display: block;
    padding-left: 70px;
}
.checkout-progress-wrap .checkout-act .box-content .txt-desc{
    font-size: 15px;
    line-height: 30px;
    color: #222222;
    margin-top: 5px;
    margin-bottom: 21px;
}
.checkout-progress-wrap .checkout-act .box-content .txt-desc a{
    font-weight: 600;
    color: #222222;
}
.login-on-checkout .form-row{
    display: block;
    margin: 0 0 7px;
}
.login-on-checkout .form-row label{
    font-size: 15px;
    line-height: 30px;
    color: #222222;
    font-weight: 600;
    display: block;
    margin: 0 0 5px;
}
.login-on-checkout .form-row input[type=email]{
    font-size: 15px;
    color: #222222;
    line-height: 30px;
    max-width: 320px;
    width: 100%;
    display: inline-block;
    padding: 7px 14px 6px;
    margin-right: 25px;
}
.login-on-checkout .form-row button{
    display: inline-block;
    border: none;
    border-radius: 9999px;
    color: #ffffff;
    text-transform: uppercase;
    background-color: #aaaaaa;
    font-weight: 700;
    padding: 12px 10px;
    min-width: 180px;
    text-align: center;
}
.login-on-checkout .form-row button:hover{
    background-color: #444444;
}
.login-on-checkout .form-row input[type=checkbox]{
    display: none;
}
.login-on-checkout .form-row input[type=checkbox]+label{
    font-size: 14px;
    color: #666666;
    font-weight: 400;
    display: inline-block;
    position: relative;
    padding-left: 25px;
    line-height: 18px;
    cursor: pointer;
}
.login-on-checkout .form-row input[type=checkbox]+label::before{
    content: '';
    display: inline-block;
    position: absolute;
    width: 15px;
    height: 15px;
    border: 2px solid #eeeeee;
    border-radius: 2px;
    top: 2px;
    left: 0;
}
.login-on-checkout .form-row input[type=checkbox]+label::after{
    content: "\f00c";
    font-family: 'FontAwesome', sans-serif;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-size: 12px;
    color: #444444;
    position: absolute;
    top: 4px;
    left: 2px;
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    line-height: 1;
}
.login-on-checkout .form-row input[type=checkbox]:checked+label::after{
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
}
.login-on-checkout .msg{
    font-size: 14px;
    color: #333333;
    display: block;
    line-height: 1;
    padding: 0;
    margin: 21px 0 0;
}
.login-on-checkout .msg a:hover{
    color: #000000;
}
.order-summary{
    background-color: #fafafa;
    padding: 20px;
}
.order-summary .title-block{
    display: inline-block;
    line-height: 0;
    font-size: 0;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 21px;
}
.order-summary .title-block .title{
    display: inline-block;
    margin: 0;
    font-size: 18px;
    color: #222222;
    font-weight: 600;
    text-align: left;
    line-height: 30px;
}
.order-summary .title-block a{
    display: inline-block;
    float: right;
    font-size: 14px;
    color: #666666;
    font-weight: 400;
    line-height: 30px;
}
.order-summary ul{
    padding: 0;
    display: block;
    margin: 0;
}
.order-summary ul li{
    list-style: none;
    display: block;
    margin: 0;
}
.order-summary .cart-list-box .number{
    font-size: 15px;
    line-height: 30px;
    color: #222222;
    display: block;
    margin-top: 21px;
    margin-bottom: 10px;
}
.order-summary .cart-list{
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 30px;
    margin-bottom: 23px;
}
.order-summary .cart-list>li+li{
    margin-top: 20px;
}
.order-summary .cart-list .cart-item{
    display: table;
    width: 100%;
}
.order-summary .cart-list .cart-item .info,
.order-summary .cart-list .cart-item .price,
.order-summary .cart-list .cart-item .product-thumb{
    display: table-cell;
    text-align: left;
}
.order-summary .cart-list .cart-item .product-thumb{
    width: 113px;
    font-size: 0;
    line-height: 0;
}
.order-summary .cart-list .cart-item .info{
    font-size: 0;
    line-height: 0;
    vertical-align: top;
    padding-top: 21px;
    padding-left: 11px;
}
.order-summary .cart-list .cart-item .info *{
    display: block;
    font-size: 17px;
    color: #222222;
    margin: 0;
    line-height: 20px;
    font-weight: 600;
}
.order-summary .cart-list .cart-item .price {
    text-align: right;
}
.order-summary .subtotal .link-forward{
    font-size: 14px;
    line-height: 30px;
    display: inline-block;
    margin-top: 9px;
    margin-bottom: 20px;
}
.order-summary .subtotal .subtotal-line a.link-forward:hover{
    color: #444444 ;
}
.order-summary .subtotal li:last-child{
    border-top: 1px solid #e6e6e6;
    padding-top: 22px;
    padding-bottom: 9px;
}

.quantity-box.type1 .qty-input input{
    font-family: 'Ubuntu', sans-serif;
    font-size: 18px;
    color: #888888;
    text-align: left;
    background-color: #f3f3f3;
    line-height: 1;
    width: 106px;
    padding: 9px 33px 8px 22px;
    height: 38px;
}
.quantity-box.type1 .qty-input .qty-btn{
    right: 17px;
    top: 2px;
}
.quantity-box.type1 .qty-input .qty-btn.btn-down{
    top: 19px;
}
.contact-info-container .box-title{
    font-size: 30px;
    font-weight: 700;
    color: #222222;
    display: block;
    margin: 0;
}
.contact-info-container .frst-desc{
    font-size: 15px;
    color: #666666;
    display: block;
    line-height: 18px;
    margin: 48px 0 0;
    max-width: 480px;
}
.contact-info-container .addr-info{
    padding: 0 0 8px;
    display: block;
    margin: 54px 0 0;
    font-size: 0;
    line-height: 0;
}
.contact-info-container .addr-info li{
    list-style: none;
    display: inline-block;
    width: 100%;
}
.contact-info-container .addr-info li+li{
    margin-top: 6px;
}
.contact-info-container .addr-info .if-item .tie{
    display: inline-block;
    font-size: 15px;
    line-height: 20px;
    color: #222222;
    float: left;
    margin-right: 3px;
}
.contact-info-container .addr-info .if-item .dsc{
    display: flex;
    font-size: 15px;
    line-height: 20px;
    color: #222222;
}
.contact-form-container{
    margin-bottom: 80px;
}
.contact-form-container .form-row{
    display: block;
    margin: 0 0 20px;
    font-size: 0;
    line-height: 0;
}
.contact-form-container .form-row textarea,
.contact-form-container .form-row input{
    font-size: 14px;
    line-height: 1;
    color: #333333;
    display: inline-block;
    width: 100%;
    padding: 8px 15px 9px;
}
.contact-form-container .form-row input{
    height: 45px;
}
.contact-form-container .form-row input::-webkit-input-placeholder { /* Edge */
    line-height: 25px;
}
.contact-form-container .form-row input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    line-height: 25px;
}
.contact-form-container .form-row input::placeholder {
    line-height: 25px;
}
.contact-form-container .form-row textarea{
    height: 145px;
}
.contact-form-container .form-row .btn-submit{
    min-width: 162px;
    text-align: center;
    border: none;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    border-radius: 999px;
    text-transform: uppercase;
    padding: 12px 10px;
    margin-top: 10px;
}
.contact-form-container .form-row .btn-submit:hover{
    background-color: #444444;
}
#map-block{
    width: 100%;
    line-height: 0;
}
.signin-container{
    margin-top: 77px;
}
.signin-container .form-row{
    margin-bottom: 13px;
}
.signin-container .form-row label{
    display: block;
    font-size: 14px;
    color: #333333;
    margin: 0 0 3px;
}
.signin-container .form-row input{
    padding: 10px 10px 9px;
    color: #444444;
    width: 100%;
}
.signin-container .form-row .btn-submit{
    min-width: 132px;
    margin-top: 7px;
}
.signin-container .form-row .link-to-help{
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    margin-left: 16px;
    vertical-align: middle;
}
.signin-container .form-row .link-to-help:hover{
    color: #444444;
}
.btn.btn-thin{
    font-size: 14px;
    color: #333333;
    text-transform: uppercase;
    border-radius: 9999px;
    text-align: center;
    font-weight: 700;
    padding: 10px;
    border: 2px solid #ffffff;
}
.btn.btn-thin:hover{
    color: #ffffff;
}
.btn.btn-bold{
    font-size: 14px;
    color: #ffffff;
    text-transform: uppercase;
    border: none;
    border-radius: 9999px;
    text-align: center;
    font-weight: 700;
    padding: 12px;
}
.btn.btn-bold:hover{
    background-color: #444444;
}
.register-in-container{
    margin-top: 73px;
    margin-bottom: 80px;
}
.register-in-container .intro{
    border: 2px solid #e6e6e6;
    padding: 20px;
}
.register-in-container .box-title{
    font-size: 30px;
    margin: 4px 0 0;
    display: block;
    color: #222222;
    font-weight: 600;
    line-height: 1;
}
.register-in-container .sub-title{
    font-size: 15px;
    color: #222222;
    line-height: 1;
    display: block;
    margin: 10px 0 0;
}
.register-in-container .intro .lis{
    padding: 0;
    font-size: 0;
    line-height: 0;
    display: block;
    margin: 37px 0 0;
}
.register-in-container .intro .lis li{
    font-size: 15px;
    line-height: 30px;
    color: #444444;
    display: block;
    list-style: none;
    position: relative;
    padding-left: 16px;
}
.register-in-container .intro .lis li::before{
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #666666;
    top: 12px;
    left: 0;
    position: absolute;
}
.register-in-container .intro .btn-bold{
    min-width: 182px;
    margin-top: 20px;
    margin-bottom: 23px;
}
.welcome-us-block,
.testimonial-block,
.why-choose-us-block{
    display: block;
    clear: both;
}
.welcome-us-block{
    background: url("../images/about-us/bn01.jpg");
    background-position: center bottom;
    /*height: 499px;*/
    height: 555px;
}
.why-choose-us-block .box-title,
.testimonial-block .box-title,
.welcome-us-block .title{
    font-size: 40px;
    line-height: 1;
    font-weight: 700;
    color: #222222;
    display: block;
    text-align: center;
    margin: 15px 0 0;
}
.welcome-us-block .text-info{
    float: right;
    clear: both;
    text-align: left;
    font-size: 15px;
    line-height: 24px;
    color: #555555;
    display: inline-block;
    max-width: 503px;
    margin: 180px 0 0;
}
.welcome-us-block .qt-text{
    font-family: 'Ubuntu', sans-serif;
    font-style: italic;
    font-size: 15px;
    line-height: 24px;
    color: #444444;
    display: inline-block;
    max-width: 503px;
    float: right;
    clear: both;
    text-align: left;
    padding-left: 18px;
    margin: 25px 0 0;
}
.why-choose-us-block{
    background-color: #fafafa;
    font-size: 0;
    line-height: 0;
}
.why-choose-us-block .box-title{
    margin: 49px 0 0;
}
.why-choose-us-block .subtitle{
    font-family: 'Playfair Display', serif;
    font-style: italic;
    color: #666666;
    font-size: 15px;
    line-height: 1;
    display: block;
    text-align: center;
    margin: 9px 0 0;
    font-weight: 400;
}
.why-choose-us-block .showcase{
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    padding-bottom: 19px;
    position: relative;
}
.why-choose-us-block .sc-list{
    padding: 0;
    margin: 0;
    font-size: 0;
    line-height: 0;

}
.sc-left-position,
.sc-right-position{
    display: inline-block;
    max-width: 270px;
    float: left;
    padding-top: 68px;
}
.sc-center-position{
    width: calc( 100% - 540px);
    display: inline-block;
    float: left;
    text-align: center;
}
.sc-right-position{
    direction: rtl;
}
.why-choose-us-block .sc-list li{
    list-style: none;
    display: block;
}
.why-choose-us-block .sc-list li+li{
    margin-top: 42px;
}
.why-choose-us-block .sc-element{
    color: #95cb34;
}
.why-choose-us-block .sc-element.color-02{ color: #ff4f63;}
.why-choose-us-block .sc-element.color-03{ color: #3b7f17;}
.why-choose-us-block .sc-element.color-04{ color: #cec316;}
.why-choose-us-block .sc-element.color-05{ color: #ffbc53;}
.why-choose-us-block .sc-element.color-06{ color: #51142e;}
.why-choose-us-block .sc-element .biolife-icon{
    display: inline-block;
    width: 60px;
    font-size: 46px;
    line-height: 1;
    padding: 0;
    margin: 9px 0 0;
    vertical-align: top;
}
.why-choose-us-block .sc-element .icon-healthy-about{
    font-size: 37px;
}
.why-choose-us-block .sc-element .txt-content{
    display: inline-block;
    width: calc(100% - 60px);
    padding: 0;
    margin: 0;
}
.why-choose-us-block .sc-element .txt-content .number{
    font-size: 50px;
    line-height: 1;
    font-weight: 700;
    display: block;
    margin: 0;
}
.why-choose-us-block .sc-element .txt-content .title{
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    display: block;
    margin: 7px 0 0;
    font-family: 'Playfair Display', serif;
    font-style: italic;
}
.why-choose-us-block .sc-element .txt-content .desc{
    display: block;
    font-size: 14px;
    color: #666666;
    line-height: 17px;
    margin: 12px 0 0;
}
.testimonial-block{
    text-align: center;
}
.testimonial-block .box-title{
    margin: 68px 0 84px;
    position: relative;
    display: inline-block;
}
.testimonial-block .box-title::after,
.testimonial-block .box-title::before{
    display: inline-block;
    font-family: FontAwesome, sans-serif;
    content: "\f006\f006\f006";
    font-size: 20px;
    color: #fcc01e;
    letter-spacing: 5px;
    position: absolute;
    top: 18px;
    left: -80px;
    z-index: 2;
    font-weight: 100;
 }
.testimonial-block .box-title::after{
    left: auto;
    right: -82px;
}
.testimonial-block ul{
    padding: 0;
}
.testimonial-block .testimonial-list{
    padding: 0 0 80px;
    display: block;
}
.testimonial-block .testimonial-list li{
    list-style: none;
    display: inline-block;
    font-size: 0;
    line-height: 0;
    text-align: center;
}
.testimonial-block .testml-elem{
    font-size: 15px;
    line-height: 24px;
    color: #444444;
}
.testimonial-block .testml-elem .avata img{
    display: inline-block;
}
.testimonial-block .testml-elem .desc{
    display: block;
    margin: 55px 0 0;
    position: relative;
}
.testimonial-block .testml-elem .desc::before{
    content: url("../images/about-us/double-quotes.png");
    width: 20px;
    height: 16px;
    font-size: 0;
    line-height: 0;
    display: block;
    position: absolute;
    top: -40px;
    left: 50%;
    margin-left: -10px;
}
.testimonial-block .testml-elem .name{
    display: block;
    font-weight: 700;
    margin: 23px 0 0;
}
.testimonial-block .testml-elem .title{
    text-transform: uppercase;
    display: block;
    margin: 2px 0 0;
    font-weight: 600;
}
.testimonial-block .testml-elem .rating{
    line-height: 0;
    margin-top: 8px;
}
.testimonial-block:not(.tsm-no-margin-type) .biolife-carousel>.slick-list{
    margin-bottom: 52px;
}
.testml-elem-2{
    font-size: 0;
    line-height: 1;
    display: block;
    margin: 46px 0 0;
    text-align: center;
    padding: 0 30px 50px;
    border-radius: 3px;
    min-height: 310px;
}
.testml-elem-2 .avata{
    display: inline-block;
    border: 4px solid #e6e6e6;
    border-radius: 50%;
    overflow: hidden;
    margin-top: -46px;
}
.testml-elem-2 .name{
    display: block;
    margin: 12px 0 0;
    font-size: 16px;
    color: #333333;
}
.testml-elem-2 .title{
    font-size: 14px;
    color: #666666;
    display: block;
    margin: 6px 0 0;
}
.testml-elem-2 .comment{
    display: block;
    margin: 48px 0 0;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    font-style: italic;
}
.biolife-carousel .slick-dots{
    padding: 0;
    display: block;
    font-size: 0;
    line-height: 0;
    text-align: center;
}
.biolife-carousel .slick-dots li{
    display: inline-block;
    margin: 0 2px;
}
.biolife-carousel .slick-dots li button{
    display: inline-block;
    border: none;
    background-color: #d7d7d7;
    width: 13px;
    height: 9px;
    border-radius: 99px;
    -webkit-transition: all 350ms ease;
    -moz-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    transition: all 350ms ease;
}
.biolife-carousel .slick-dots li.slick-active button{
    width: 33px;
}
.single-post-contain{
    display: block;
    clear: both;
}
.single-post-contain .post-head{
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 13px;
    margin-bottom: 14px;
}
.single-post-contain .post-head .thumbnail{
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;
    font-size: 0;
    line-height: 0;
}
.single-post-contain .post-head .post-name{
    display: block;
    margin: 30px 0 0;
    font-size: 34px;
    color: #222222;
    line-height: 1;
    font-weight: 700;
}
.post-item .post-content .post-archive,
.single-post-contain .post-head .post-archive{
    display: block;
    margin: 9px 0 0;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
    font-weight: 400;
}
.post-item .post-content .post-archive .post-cat,
.single-post-contain .post-head .post-archive .post-cat{
    font-weight: 600;
    color: #222222;
}
.post-item .post-content .post-archive .author,
.single-post-contain .post-head .post-archive .author{
    margin-left: 6px;
}
.single-post-contain .post-content{
    display: block;
    font-size: 0;
    line-height: 0;
    padding-bottom: 44px;
}
.single-post-contain .post-content *{
    font-size: 15px;
    line-height: 24px;
    color: #666666;
    margin-bottom: 24px;
}
.single-post-contain .post-content blockquote{
    padding: 0;
    border-left: 0;
    display: inline-block;
    position: relative;
    margin: 0 0 30px;
}
.single-post-contain .post-content blockquote::before{
    content: url(../images/blogpost/double-quote.png);
    display: block;
    opacity: 0.1;
    filter: alpha(opacity=10);
    font-size: 0;
    line-height: 0;
    margin-top: -1px;
    margin-bottom: 6px;
}
.single-post-contain .post-content blockquote p{
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    color: #333333;
}
.single-post-contain .post-content blockquote address{
    display: block;
    margin: 31px 0 0;
    font-size: 13px;
    line-height: 1;
}
.single-post-contain .post-content blockquote address a{
    font-size: 16px;
    color: #222222;
    font-weight: 600;
    line-height: 1;
    display: inline-block;
    margin: 0;
}
.single-post-contain .post-content blockquote address span{
    display: block;
    margin: -1px 0 0;
}
.single-post-contain .post-foot .post-tags{
    font-size: 0;
    line-height: 0;
}
.single-post-contain .post-foot .post-tags .tag-title{
    font-size: 16px;
    color: #666666;
    display: inline-block;
    line-height: 24px;
    margin-right: 26px;
}
.single-post-contain .post-foot .post-tags .tags{
    padding: 0;
    margin: 0;
    display: inline-block;
}
.single-post-contain .post-foot .post-tags .tags li{
    display: inline-block;
    list-style: none;
    margin-right: 10px;
}
.single-post-contain .post-foot .post-tags .tags li a{
    display: inline-block;
    color: #888888;
    border: 2px solid #f1f1f1;
    border-radius: 9999px;
    padding: 0 9px;
    font-size: 14px;
    line-height: 24px;
}
.single-post-contain .post-foot .post-tags .tags li a:hover{
    color: #111111;
}
.single-post-contain .post-foot .auth-info{
    display: inline-block;
    width: 100%;
    border-top: 2px solid #e6e6e6;
    border-bottom: 2px solid #e6e6e6;
    padding: 18px 0;
    margin-top: 30px;
}
.single-post-contain .post-foot .auth-info .ath{
    display: inline-block;
    float: left;
    font-size: 15px;
    line-height: 30px;
}
.single-post-contain .post-foot .auth-info .avata{
    color: #333333;
    font-weight: 700;
    margin: 0 32px 0 0;
    display: inline-block;
    padding: 1px 0;
    height: 33px;
}
.single-post-contain .post-foot .auth-info .avata img{
    margin-right: 6px;
}
.single-post-contain .post-foot .auth-info .count-item{
    display: inline-block;
    color: #aaaaaa;
    margin-left: 15px;
}
.single-post-contain .post-foot .auth-info .count-item i{
    margin-right: 4px;
}
.single-post-contain .post-foot .socials-connection{
    display: inline-block;
    float: right;
    font-size: 0;
    line-height: 1;
}
.single-post-contain .post-foot .socials-connection .title{
    font-size: 18px;
    color: #666666;
    display: inline-block;
    padding: 8px 0 7px;
    vertical-align: top;
}
.single-post-contain .post-foot .socials-connection .social-list{
    display: inline-block;
    margin: 0 0 0 15px;
    padding: 0;
}
.single-post-contain .post-foot .socials-connection .social-list li{
    list-style: none;
    display: inline-block;
}
.single-post-contain .post-foot .socials-connection .social-list li:not(:last-child){
    margin-right: 10px;
}
.single-post-contain .post-foot .socials-connection .social-list li a{
    font-size: 18px;
    color: #666666;
    border: 2px solid #e6e6e6;
    border-radius: 50%;
    background-color: #ffffff;
    line-height: 1;
    display: inline-block;
    width: 33px;
    text-align: center;
    padding: 7px 5px 4px;
}
.single-post-contain .post-foot .socials-connection .social-list li a:hover{
    color: #ffffff;
}
.post-comments{
    clear: both;
    display: block;
}
.post-comments .cmt-title{
    font-size: 23px;
    line-height: 1;
    font-weight: 700;
    color: #222222;
    margin: 48px 0 0;
}
.post-comments .cmt-title sup{
    font-size: 60%;
    color: #aaaaaa;
    font-weight: 600;
}
.post-comments .comment-form{
    margin-top: 46px;
}
.post-comments .comment-form .form-row{
    margin: 0;
    display: block;
    position: relative;
    font-size: 0;
    line-height: 0;
}
.post-comments .comment-form .form-row+.form-row:not(.last-btns){
    margin-top: 20px;
}
.post-comments .comment-form .form-row input,
.post-comments .comment-form .form-row textarea{
    display: inline-block;
    width: 100%;
    border: 2px solid #e6e6e6;
    font-size: 14px;
    padding: 11px 13px;
    line-height: 24px;
}
.post-comments .comment-form .form-row textarea{
    padding: 39px 13px 11px 84px;
    height: 188px;
}
.post-comments .comment-form .form-row .current-author{
    font-size: 0;
    line-height: 0;
    display: inline-block;
    position: absolute;
    top: 32px;
    left: 31px;
    z-index: 2;
}
.post-comments .form-row.last-btns{
    direction: rtl;
    border-bottom: 2px solid #e6e6e6;
    border-left: 2px solid #e6e6e6;
    border-right: 2px solid #e6e6e6;
    padding: 17px 19px 18px;
}
.post-comments .form-row.last-btns .btn{
    display: inline-block;
    border: none;
    font-size: 18px;
    line-height: 24px;
    color: #888888;
    outline: none;
    box-shadow: none;
}
.post-comments .form-row.last-btns .btn-sumit{
    font-size: 14px;
    color: #ffffff;
    border-radius: 9999px;
    font-weight: 700;
    min-width: 180px;
    text-align: center;
    padding: 10px;
    text-transform: uppercase;
}
.post-comments .form-row.last-btns .btn-sumit:hover{
    background-color: #444444;
}
.post-comments  .comment-list{
    display: block;
    clear: both;
    margin-top: 50px;
}
.post-comments  .comment-list ol{
    padding: 0;
    margin: 0;
}
.post-comments  .comment-list ol li{
    display: block;
    list-style: none;
}
.post-comments  .wrap-post-comment .author-contact{
    font-size: 15px;
    font-weight: 700;
    color: #333333;
    line-height: 30px;
}
.post-comments  .wrap-post-comment .author-contact img{
    margin-right: 6px;
    display: inline-block;
    float: left;
}
.post-comments  .wrap-post-comment .cmt-time{
    display: inline-block;
    font-size: 14px;
    line-height: 30px;
    color: #aaaaaa;
    margin-left: 23px;
}
.post-comments  .wrap-post-comment .cmt-content{
    margin-top: 12px;
}
.post-comments  .wrap-post-comment .cmt-content p{
    font-size: 15px;
    line-height: 24px;
    color: #666666;
    display: block;
    margin: 0;
}
.post-comments  .wrap-post-comment .cmt-fooot{
    display: block;
    font-size: 0;
    line-height: 0;
    margin: 16px 0 0;
}
.post-comments  .wrap-post-comment .cmt-fooot .btn{
    display: inline-block;
    color: #aaaaaa;
    padding: 0;
    border-radius: 0;
    border: none;
    font-size: 14px;
    line-height: 30px;
    margin-right: 19px;
}
.post-comments  .wrap-post-comment .cmt-fooot .btn i{
    font-size: 18px;
    margin-right: 9px;
}
.post-comments .wrap-post-comment .cmt-inner{
    margin-top: 40px;
    padding-bottom: 22px;
}
.post-comments.lever-0>li>.wrap-post-comment>.cmt-inner{
    border-bottom: 2px solid #e6e6e6;
}
.post-comments .comment-list ol.lever-1{
    padding-left: 59px;
}
.post-comments .comment-list .biolife-panigations-block{
    margin-top: 31px;
}
.blog-page.page-contain{
    padding-top: 40px;
}
.blog-page .post-item .post-content .post-name a{
    font-weight: 700;
}
.hero-section .biolife-nav ul li:last-child::before,
.hero-section .biolife-nav ul li:last-child span,
.hero-section .biolife-nav ul li span,
.hero-section .biolife-nav ul li a{
    color: #ffffff;
}
.hero-section .biolife-nav{
    margin: 0;
    display: inline-block;
    position: absolute;
    top: calc(29% + 54px);
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 5;
}
.main-content>.row{
    font-size: 0;
    line-height: 0;
}
.main-content .main-post-list{
    display: inline-block;
    width: 100%;
    margin: -10px 0 0 0;
    padding: 0;
}
.main-content>.posts-list{
    padding: 0;
    margin: 0 0 60px 0;
}
.main-content .main-post-list li{
    list-style: none;
    display: block;
    margin-bottom: 40px;
    margin-top: 10px;
}
.main-content>.posts-list li{
    list-style: none;
    display: block;
}
.main-content>.posts-list li+li{
    margin-top: 50px;
}
.posts-list .post-item .thumbnail{
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;
    font-size: 0;
    line-height: 0;
}
.post-item .post-content .post-name{
    font-size: 23px;
    font-weight: 600;
    color: #333333;
    display: block;
    margin: 0;
    line-height: 1;
}
.post-item .post-content .post-name a{
    display: inline-block;
    color: inherit;
}
.post-item .post-content .post-archive{
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 13px;
}
.post-item .post-content .excerpt{
    font-size: 15px;
    line-height: 24px;
    color: #666666;
    margin: 14px 0 0;
}
.post-item .post-content .group-buttons{
    display: inline-block;
    width: 100%;
    font-size: 0;
    line-height: 0;
    margin-top: 19px;
}
.post-item .group-buttons .btn{
    display: inline-block;
    outline: none;
    box-shadow: none;
}
.post-item .group-buttons .btn.readmore{
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    font-weight: 700;
    display: inline-block;
    background-color: #ffffff;
    border: 2px solid #e6e6e6;
    border-radius: 9999px;
    text-transform: uppercase;
    padding: 8px 10px;
    min-width: 155px;
    margin-right: 9px;
}
.post-item .group-buttons .btn.readmore:hover{
    color: #ffffff;
}
.style-bottom-info.layout-03 .post-meta .count-number,
.post-item .group-buttons .count-number{
    font-size: 17px;
    padding: 0 0 0 11px;
    color: #666666;
    position: relative;
    line-height: 1;
    margin-right: 23px;
    border-radius: 0;
}
.style-bottom-info.layout-03 .post-meta .count-number .number,
.post-item .group-buttons .count-number .number{
    font-size: 10px;
    line-height: 1;
    color: #ffffff;
    font-weight: 600;
    background-color: #888888;
    min-width: 19px;
    text-align: center;
    padding: 5px 3px 4px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: -7px;
    right: -15px;
    z-index: 2;
}
.post-item .group-buttons .commented .number{
    right: -7px;
}
.style-bottom-info.layout-03 .post-meta .count-number + .count-number,
.post-item .group-buttons .count-number + .count-number{
    border-left: 1px solid #e6e6e6 !important;
}
.post-item.style-left-info{
    display: table;
    width: 100%;
}
.post-item.style-left-info .thumbnail,
.post-item.style-left-info .post-content{
    display: table-cell;
    vertical-align: middle;
}
.post-item.style-left-info .thumbnail{
    width: 370px;
}
.post-item.style-left-info .post-content{
    padding-left: 30px;
}
.post-item.style-left-info .post-content>*{
    max-width: 329px;
    text-align: center;
}
.post-item.style-left-info .post-content .post-name{
    font-size: 23px;
    color: #000000;
}
.post-item.style-bottom-info{
    text-align: center;
}
.post-item.style-bottom-info .post-content{
    padding-left: 20px;
    padding-right: 20px;
}
.post-item.style-wide .post-content .post-name{
    margin-top: 16px;
    font-weight: 700;
    font-size: 26px;
}
.post-item.style-bottom-info .post-content .post-name{
    margin-top: 16px;
}
.main-slide{
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
    display: block;
    position: relative;
    overflow: hidden;
    clear: both;
}
.main-slide ul{
    padding: 0;
    margin: 0;
}
.main-slide li{
    list-style: none;
}
.main-slide .slick-arrow{
    font-size: 44px;
    color: #e6e6e6;
    position: absolute;
    top: 50%;
    margin-top: -22px;
    left: 60px;
    z-index: 5;
}
.main-slide .slick-arrow.next{
    left: auto;
    right: 60px;
}
.main-slide.nav-change .slick-arrow{
    -webkit-transition: all 450ms ease;
    -moz-transition: all 450ms ease;
    -ms-transition: all 450ms ease;
    -o-transition: all 450ms ease;
    transition: all 450ms ease;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
}
.main-slide.nav-change.type02 .slick-arrow{
    color: #d7d7d7;
    left: 10px;
}
.main-slide.nav-change.type02 .slick-arrow.next{
    right: 10px;
    left: auto;
}
.main-slide.nav-change.type02 .slick-arrow:hover{
    color: #ffffff;
}
.main-slide.nav-change .slick-arrow.next{
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
}
.main-slide.nav-change:hover .slick-arrow{
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
}
.slider-opt04__layout01,
.slider-opt03__layout02,
.slider-opt03__layout01{
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
    display: block;
    position: relative;
    overflow: hidden;
}
.slider-opt03__layout01 .media{
    z-index: 10;
    background-image: url('../images/home-03/slide-01.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 660px;
}
.slider-opt03__layout01 .media .child-elememt{
    display: inline-block;
    position: absolute;
    top: 4%;
    right: 35%;
    z-index: 5;
    margin-top: 10px;
    margin-right: -2px;
    -webkit-transition: transform 1s ease 0.5s;
    -moz-transition: transform 1s ease 0.5s;
    -o-transition: transform 1s ease 0.5s;
    transition: transform 1s ease 0.5s;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}
.slider-opt03__layout01 .text-content{
    display: inline-block;
    position: absolute;
    top: 18%;
    right: 13%;
    margin: 0 5px 0 0;
    z-index: 20;
    -webkit-transition: transform 0.7s ease 0.7s;
    -moz-transition: transform 0.7s ease 0.7s;
    -o-transition: transform 0.7s ease 0.7s;
    transition: transform 0.7s ease 0.7s;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    background-color: rgba(255, 255, 255, 0.8);
    color: rgb(255, 255, 255);
    border-color: rgb(102, 102, 102);
    padding: 20px 50px 40px;
}
.slick-slide.slick-active .slider-opt03__layout01 .text-content,
.slick-slide.slick-active .slider-opt03__layout01 .media .child-elememt{
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
.slider-opt03__layout01 .text-content .first-line{
    font-family: 'Playfair Display', sans-serif;
    font-size: 30px;
    line-height: 1;
    color: #000000;
    display: block;
    margin: 0;
}
.slider-opt03__layout01 .text-content .second-line{
    font-size: 60px;
    line-height: 60px;
    color: #333333;
    font-weight: 700;
    display: block;
    margin: 0;
    max-width: 365px;
}
.slider-opt03__layout01 .text-content .third-line{
    font-size: 22px;
    line-height: 1;
    color: #000000;
    display: block;
    margin: 13px 0 0;
}
.slider-opt03__layout01 .buttons{
    font-size: 0;
    display: block;
    line-height: 0;
    margin: 36px 0 0;
}
.slider-opt03__layout01 .buttons .btn{
    font-size: 14px;
    border-radius: 9999px;
    display: inline-block;
    line-height: 1;
    min-width: 147px;
    text-align: center;
    padding: 17px 10px 16px;
    outline: none;
    box-shadow: none;
}
.slider-opt03__layout01 .buttons .btn+.btn{
    margin-left: 19px;
}
.slider-opt03__layout01 .buttons .btn-thin{
    padding: 15px 10px 14px;
    min-width: 177px;
}
.slider-opt03__layout01 .buttons .btn:hover{
    background-color: #444444 !important;
    border-color: #444444 !important;
    color: #ffffff !important;
}
.slider-opt03__layout01.mode-02 .media{
    background-image: url('../images/home-02/main-slide-01.jpg');
    height: 955px;
}
.slider-opt03__layout01.mode-02.slide-bgr-02 .media{
    background-image: url('../images/home-02/main-slide-02.jpg');
}
.slider-opt03__layout01.mode-02.slide-bgr-03 .media{
    background-image: url('../images/home-02/main-slide-03.jpg');
}
.slider-opt03__layout01.mode-02 .buttons .btn-thin{
    color: #ffffff;
}
.slider-opt03__layout01.mode-03 .media{
    height: 1072px;
}
.slider-opt03__layout01.slide-bgr-mode03-01 .media{
     background-image: url('../images/home-01/main-slide-01.jpg');
 }
.slider-opt03__layout01.slide-bgr-mode03-02 .media{
    background-image: url('../images/home-01/main-slide-02.jpg');
}
.slider-opt03__layout01.slide-bgr-mode03-03 .media{
    background-image: url('../images/home-01/main-slide-03.jpg');
}
.slider-opt03__layout02 .media{
    z-index: 10;
    background-image: url('../images/home-03/green-slide-01.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 560px;
}
.slider-opt03__layout02 .media.background-geen-02{
    background-image: url('../images/home-03/green-slide-02.jpg');
}
.slider-opt03__layout02 .text-content{
    display: inline-block;
    max-width: 400px;
    position: absolute;
    top: 127px;
    left: 35%;
    z-index: 2;
    margin-top: -4px;
    margin-left: 3px;
}
.slider-opt04__layout01 .text-content .first-line,
.slider-opt03__layout02 .text-content .first-line{
    font-size: 30px;
    line-height: 1;
    color: #666666;
    display: block;
    margin: 0;
    font-family: 'Playfair Display', sans-serif;
}
.slider-opt04__layout01 .text-content .second-line,
.slider-opt03__layout02 .text-content .second-line{
    font-size: 60px;
    font-weight: 700;
    color: #222222;
    display: block;
    margin: 1px 0 0;
    line-height: 60px;
}
.slider-opt04__layout01 .text-content .third-line,
.slider-opt03__layout02 .text-content .third-line{
    font-size: 18px;
    color: #444444;
    display: block;
    margin: 14px 0 0;
    line-height: 1;
}
.slider-opt04__layout01 .buttons,
.slider-opt03__layout02 .buttons{
    margin: 37px 0 0;
}
.slider-opt04__layout01 .buttons .btn,
.slider-opt03__layout02 .buttons .btn{
    padding: 14px 10px 13px;
    min-width: 147px;
}
.slider-opt04__layout01 .buttons .btn-thin,
.slider-opt03__layout02 .buttons .btn-thin{
    border: 2px solid #e6e6e6;
    min-width: 177px;
    padding: 12px 10px 11px;
    margin-left: 19px;
}
.slider-opt04__layout01 .buttons .btn-thin:hover,
.slider-opt03__layout02 .buttons .btn-thin:hover{
    background-color: #444444;
    border-color: #444444;
    color: #ffffff;
}
.none-box-shadow .vertical-category-block>.wrap-menu{
    box-shadow: none;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}
.slider-opt04__layout01 .media{
    z-index: 10;
    background-image: url('../images/home-04/main_slide.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 578px;
}
.slider-opt04__layout01 .text-content{
    display: inline-block;
    max-width: 400px;
    position: absolute;
    top: 132px;
    left: 70px;
    z-index: 2;
}
.slider-opt04__layout01 .buttons .btn-thin,
.slider-opt04__layout01 .text-content .first-line,
.slider-opt04__layout01 .text-content .second-line,
.slider-opt04__layout01 .text-content .third-line{
    color: #ffffff;
}
li.slick-current .slider-opt04__layout01 .buttons .btn,
li.slick-current .slider-opt04__layout01 .text-content .first-line,
li.slick-current .slider-opt04__layout01 .text-content .second-line,
li.slick-current .slider-opt04__layout01 .text-content .third-line{
    animation-duration: 1.6s;
    -webkit-animation-duration: 1.6s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    animation-name: bounceInRight;
    -webkit-animation-name: bounceInRight;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}
li.slick-current .slider-opt04__layout01 .text-content .second-line{
    animation-name: bounceInLeft;
    -webkit-animation-name: bounceInLeft;
}
li.slick-current .slider-opt04__layout01 .text-content .third-line{
    animation-name: bounceIn;
    -webkit-animation-name: bounceIn;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}
li.slick-current .slider-opt04__layout01 .buttons .btn{
    animation-name: zoomIn;
    -webkit-animation-name: zoomIn;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}
.slider-opt04__layout01 .buttons .btn.btn-bold:not(:hover){
    background-color: #87be00;
}
.slider-opt04__layout01.light-version .buttons .btn-thin:not(:hover){
    color: #333333;
}
.slider-opt04__layout01.first-slide .media{
    background-image: url('../images/home-04/light-main_slide_01.jpg');
}
.slider-opt04__layout01.second-slide .media{
    background-image: url('../images/home-04/light-main_slide_02.jpg');
}
.slider-opt04__layout01.third-slide .media{
    background-image: url('../images/home-04/light-main_slide_03.jpg');
}
.slider-opt04__layout01.light-version .text-content .first-line{
    color: #666666;
}
.slider-opt04__layout01.light-version .text-content .second-line{
    color: #222222;
}
.slider-opt04__layout01.light-version .text-content .third-line{
    color: #444444;
}
.slider-opt05{
    position: relative;
    display: block;
    overflow: hidden;
    font-size: 0;
    line-height: 1;
    background: url("../images/home-05/main-slide.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 474px;
}
.slider-opt05 .text-content{
    display: inline-block;
    max-width: 350px;
    padding-top: 94px;
    padding-left: 39px;
    position: relative;
    z-index: 10;
}
.slider-opt05 .text-content .first-line{
    display: block;
    margin: 0;
    font-size: 26px;
    color: #666666;
    font-family: 'Playfair Display', sans-serif;
}
.slider-opt05 .text-content .second-line{
    display: block;
    margin: 5px 0 0;
    font-size: 50px;
    line-height: 60px;
    color: #222222;
    font-weight: 700;
}
.slider-opt05 .text-content .third-line{
    display: block;
    margin: 10px 0 0;
    font-size: 18px;
    color: #444444;
    margin-left: 8px;
}
.slider-opt05 .text-content .buttons{
    margin: 37px 0 0;
}
.slider-opt05 .text-content .btn.btn-bold{
    min-width: 147px;
}
.slider-opt05 .text-content .btn.btn-bold:not(:hover){
    background-color: #87be00;
}
.slider-opt05 .media{
    display: inline-block;
    position: absolute;
    z-index: 5;
    top: 51px;
    right: 0;
}
.slick-active .slider-opt05 .text-content .btn,
.slick-active .slider-opt05 .text-content .third-line,
.slick-active .slider-opt05 .text-content .second-line,
.slick-active .slider-opt05 .text-content .first-line{
    animation-duration: 1.2s;
    -webkit-animation-duration: 1.2s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    animation-name: fadeInRight;
    -webkit-animation-name: fadeInRight;
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}
.slick-active .slider-opt05 .text-content .second-line{
    animation-duration: 1.4s;
    -webkit-animation-duration: 1.4s;
}
.slick-active .slider-opt05 .text-content .third-line{
    animation-duration: 1.6s;
    -webkit-animation-duration: 1.6s;
}
.slick-active .slider-opt05 .text-content .btn{
    animation-duration: 1.6s;
    -webkit-animation-duration: 1.6s;
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
}
.slick-active .slide_animation .buttons,
.slick-active .slide_animation .third-line,
.slick-active .slide_animation .first-line,
.slick-active .slide_animation .second-line{
    animation-duration: 1.2s;
    -webkit-animation-duration: 1.2s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    animation-name: zoomIn;
    -webkit-animation-name: zoomIn;
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}
.slick-active .slide_animation.type_02 .second-line{
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
}
.slick-active .slide_animation.type_02 .third-line{
    animation-duration: 1.2s;
    -webkit-animation-duration: 1.2s;
    animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s;
}
.slick-active .slide_animation.type_02 .buttons{
    animation-duration: 1.2s;
    -webkit-animation-duration: 1.2s;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}
.biolife-banner__special{
    display: inline-block;
    width: 100%;
    clear: both;
    font-size: 0;
    line-height: 0;
    padding-top: 86px;
}
.biolife-banner__special .media{
    display: inline-block;
    width: 57.265%;
    padding-left: 25px;
}
.biolife-banner__special .text-content{
    display: inline-block;
    vertical-align: top;
    width: 42.735%;
    line-height: 1;
    text-align: center;
    padding-top: 41px;
}
.biolife-banner__special .text-content .first-line{
    font-size: 50px;
    font-weight: 700;
    color: #000000;
    display: block;
    margin: 0;
}
.biolife-banner__special .text-content .second-line{
    font-size: 35px;
    line-height: 1;
    color: #666666;
    display: block;
    font-weight: 600;
    margin: 17px 0 0;
}
.biolife-banner__special .text-content .third-line{
    font-size: 35px;
    color: #666666;
    display: block;
    font-weight: 600;
    line-height: 1;
    margin: 1px 0 0;
}
.biolife-banner__special .text-content .third-line i{
    font-weight: 700;
    color: #222222;
    font-family: 'Playfair Display', sans-serif;
    font-size: 40px;
}
.biolife-banner__special .product-detail{
    display: block;
    margin-top: 65px;
}
.biolife-banner__special .product-detail .product-name{
    display: block;
    margin: 0;
    font-size: 17px;
    line-height: 24px;
    color: #222222;
    font-weight: 600;
}
.biolife-banner__special .product-detail .price-contain ins .price-amount{
    font-size: 30px;
    line-height: 24px;
}
.biolife-banner__special .product-detail .price-contain .price-amount{
    font-size: 20px;
}
.biolife-banner__special .product-detail .buttons{
    display: block;
    margin-top: 27px;
}
.biolife-banner__special .product-detail .add-to-cart-btn{
    font-size: 14px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 9999px;
    border: none;
    padding: 17px 10px 16px;
    line-height: 1;
    min-width: 197px;
    outline: none;
    box-shadow: none;
}
.biolife-banner__special .product-detail .add-to-cart-btn:hover{
    background-color: #444444;
}
.biolife-banner__special-02{
    text-align: right;
    position: relative;
    overflow: hidden;
    clear: both;
}
.biolife-banner__special-02 .banner-contain{
    font-size: 0;
    line-height: 0;
    display: inline-block;
    text-align: left;
    width: 100%;
    position: relative;
}
.biolife-banner__special-02 .banner-contain .thumb{
    display: inline-block;
}
.biolife-banner__special-02 .text-content{
    display: inline-block;
    text-align: center;
    position: absolute;
    right: 0;
    top: 14%;
    margin-top: -1px;
}
.biolife-banner__special-02 .text-content .text01{
    font-size: 70px;
    line-height: 1;
    display: block;
    margin: 0;
    color: #da840f;
    font-family: 'Playfair Display', sans-serif;
    opacity: 0.11;
    filter: alpha(opacity=11);
    margin-bottom: -31px;
}
.biolife-banner__special-02 .text-content .text02{
    font-size: 50px;
    display: block;
    margin: 0;
    color: #000000;
    line-height: 1;
}
.biolife-banner__special-02 .text-content .text04,
.biolife-banner__special-02 .text-content .text03{
    font-size: 35px;
    line-height: 1;
    color: #666666;
    display: block;
    margin: 0;
    font-weight: 600;
}
.biolife-banner__special-02 .text-content .text03{
    margin: 17px 0 -1px;
}
.biolife-banner__special-02 .text-content .text04 i{
    font-family: 'Playfair Display', sans-serif;
    font-weight: 700;
    color: #222222;
    font-size: 40px;
}
.biolife-banner__special-02 .product-detail{
    margin-top: 50px;
}
.biolife-banner__special-02 .product-detail .price-contain{
    margin-top: 8px;
}
.biolife-banner__special-02 .product-detail .price-contain .price-amount{
    font-size: 30px;
}
.biolife-banner__special-02 .product-detail .product-name{
    font-size: 17px;
    color: #222222;
    font-weight: 600;
    display: block;
    line-height: 1;
    margin: 0;
}
.biolife-banner__special-02 .product-detail .measure{
    display: block;
    line-height: 1;
    font-size: 15px;
    color: #444444;
    margin: 10px 0 0;
    font-weight: 600;
}
.biolife-banner__special-02 .product-detail .buttons{
    display: block;
    text-align: center;
    margin-top: 25px;
}
.biolife-banner__special-02 .product-detail .add-to-cart-btn{
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    text-transform: uppercase;
    color: #ffffff;
    border-radius: 9999px;
    min-width: 197px;
    padding: 13px 10px 12px;
}
.biolife-banner__special-02 .product-detail .add-to-cart-btn:hover{
    background-color: #444444;
}
.biolife-banner__special-03 .banner-contain{
    display: block;
    position: relative;
    font-size: 0;
    line-height: 0;
    clear: both;
    background-image: url(../images/home-01/bn-special-03-sample.jpg);
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    padding-bottom: 47px
}
.biolife-carousel.dots_ring_style_2,
.biolife-carousel.dots_ring_style{
    position: relative;
}
.biolife-carousel.dots_ring_style .slick-dots{
    position: absolute;
    bottom: 41px;
    right: 214px;
}
.biolife-carousel.dots_ring_style_2 .slick-dots li button,
.biolife-carousel.dots_ring_style .slick-dots li button{
    padding: 0;
    width: 11px;
    height: 11px;
    display: inline-block;
    border: 0 solid #999999;
    background-color: #bbbbbb;
    border-radius: 50%;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}
.biolife-carousel.dots_ring_style .slick-dots li.slick-active button{
    height: 13px;
    width: 13px;
    border-width: 2px;
    background-color: transparent;
}
.biolife-carousel.dots_ring_style_2 .slick-dots{
    position: absolute;
    bottom: 28px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 0;
    line-height: 0;
}
.biolife-carousel.dots_ring_style_2 .slick-dots li{
    margin: 0;
}
.biolife-carousel.dots_ring_style_2 .slick-dots li+li{
    margin-left: 9px;
}
.biolife-carousel.dots_ring_style_2 .slick-dots li button{
    width: 10px;
    height: 10px;
    background-color: #666666;
    border: none;
}
.biolife-carousel.dots_ring_style_2 .slick-dots li.slick-active button{
    background-color: transparent;
    border: 2px solid #ffffff;
    width: 12px;
    height: 12px;
}
.biolife-service__type01{
    display: block;
    font-size: 0;
    line-height: 0;
    position: relative;
    overflow: hidden;
    padding-top: 14px;
}
.biolife-service__type01 .txt-show-01{
    font-size: 220px;
    color: #eeeeee;
    font-weight: 700;
    display: block;
    opacity: 0.55;
    filter: alpha(opacity=55);
    text-align: center;
    line-height: 1;
}
.biolife-service__type01 .txt-show-02{
    font-size: 100px;
    line-height: 1;
    font-family: 'Playfair Display', sans-serif;
    display: block;
    text-align: center;
    position: absolute;
    top: 19px;
    left: 0;
    right: 0;
    z-index: 2;
}
.biolife-service__type01 .services-list{
    margin-top: -9px;
    position: relative;
    z-index: 1;
}
.services-list{
    padding: 0;
    line-height: 0;
    font-size: 0;
    margin: 0;
    display: inline-block;
    width: 100%;
    border: 2px solid #e6e6e6;
    border-radius: 3px;
    padding-top: 19px;
    padding-bottom: 18px;
}
.services-list li{
    list-style: none;
    display: inline-block;
    width: 33.333333%;
    padding-top: 10px;
    padding-bottom: 22px;
}
.services-list li+li{
    border-left: 1px solid #e6e6e6;
}
.service-inner{
    display: inline-block;
    width: 100%;
    text-align: center;
}
.service-inner .number{
    font-size: 16px;
    line-height: 1;
    background-color: #cccccc;
    display: inline-block;
    width: 31px;
    text-align: center;
    color: #ffffff;
    margin: 0;
    padding: 7px 5px 8px;
    border-radius: 50%;
    vertical-align: top;
}
.service-inner .biolife-icon{
    font-size: 35px;
    display: inline-block;
    padding-top: 1px;
    margin-left: 38px;
}
.service-inner .srv-name{
    display: inline-block;
    font-size: 16px;
    color: #222222;
    text-align: center;
    margin: 9px 0 0;
    width: 100%;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 700;
}
.service-inner:not(.style-02) .srv-name{
    margin-top: 15px;
}
.service-inner.color-reverse .biolife-icon{
    color: #888888;
}
.biolife-service__type02{
    border: 2px solid #e6e6e6;
    border-radius: 3px;
    overflow: hidden;
    padding: 0;
}
.biolife-service__type02 .services-list{
    border: none;
    padding: 0 18px;
}
.biolife-service__type02 .services-list li+li{
    border-left: none;
    border-top: 1px solid #e6e6e6;
}
.biolife-service__type02 .services-list li{
    width: 100%;
    padding: 0 0 0 11px;
}
.biolife-service__type02 .foot-area{
    display: table;
    width: 100%;
    padding-left: 30px;
    padding-top: 18px;
    padding-bottom: 16px;
}
.biolife-service__type02 .foot-area .txt,
.biolife-service__type02 .foot-area .biolife-icon{
    display: table-cell;
    vertical-align: middle;
    text-align: left;
}
.biolife-service__type02 .foot-area .biolife-icon{
    color: #222222;
    line-height: 1;
    font-size: 32px;
    width: 39px;
}
.biolife-service__type02 .foot-area .txt p{
    font-size: 14px;
    line-height: 17px;
    color: #222222;
    display: block;
    margin: 0;
    font-weight: 400;
}
.service-inner.style-02 .biolife-icon{
    font-size: 36px;
}
.service-inner.style-02{
    text-align: left;
    padding: 20px 0;
}
.service-inner.style-02 .biolife-icon{
    color: #888888;
}
.service-inner.style-02 .number{
    color: #222222;
    font-weight: 600;
}
.service-inner.style-02 .srv-name{
    text-transform: uppercase;
    text-align: left;
    margin-top: 12px;
}
.biolife-tab .tab-head .tabs{
    padding: 0;
    margin: 0 0 23px 0;
    display: block;
    text-align: center;
}
.biolife-tab .tab-head .tabs .tab-element{
    display: inline-block;
    list-style: none;
}
.biolife-tab .tab-head .tabs .tab-element + .tab-element{
    margin-left: 45px;
}
.biolife-tab .tab-content{
    display: block;
    margin: 0;
    position: relative;
}
.biolife-tab .tab-content .tab-contain{
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    z-index: 2;
}
.biolife-tab .tab-content>.active{
    display: block;
    position: static;
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
    z-index: 5;
    -webkit-transition: all 1500ms ease;
    -moz-transition: all 1500ms ease;
    -o-transition: all 1500ms ease;
    transition: all 1500ms ease;
}
.tab-head__icon-top-layout a{
    display: inline-block;
    text-align: center;
    font-size: 24px;
    color: #888888;
    font-weight: 600;
    position: relative;
    padding-bottom: 4px;
    padding-left: 5px;
    padding-right: 5px;
}
.tab-head__icon-top-layout:not(.background-tab-include) a::after{
    content: '';
    height: 2px;
    display: block;
    width: 100%;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 350ms ease;
    -moz-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    transition: all 350ms ease;
}
.tab-head__icon-top-layout .active a::after{
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
.tab-head__icon-top-layout .biolife-icon{
    font-size: 44px;
    display: block;
    margin-bottom: 13px;
    text-align: center;
}
.tab-head__sample-layout .tabs{
    font-size: 0;
    margin-bottom: 8px !important;
}
.biolife-tab .tab-head__sample-layout .tabs .tab-element + .tab-element{
    margin-left: 0;
}
.biolife-tab .tab-head__default .tabs{
    font-size: 0;
    line-height: 0;
    margin: 0;
}
.tab-head__default{
    margin-top: 33px;
    margin-bottom: 24px;
}
.tab-head__default .tab-element .tab-link{
    display: inline-block;
    font-size: 16px;
    color: #666666;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    position: relative;
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 4px;
    -webkit-transition: color 750ms ease;
    -moz-transition: color 750ms ease;
    -ms-transition: color 750ms ease;
    -o-transition: color 750ms ease;
    transition: color 750ms ease;
}
.tab-head__default .tab-element .tab-link::before{
    content: '';
    display: block;
    height: 2px;
    background-color: #666666;
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    z-index: 1;
    -webkit-transition: transform 500ms ease;
    -moz-transition: transform 500ms ease;
    -ms-transition: transform 500ms ease;
    -o-transition: transform 500ms ease;
    transition: transform 500ms ease;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}
.tab-head__default .tab-element .tab-link:hover::before,
.tab-head__default .tab-element.active .tab-link::before{
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
.tab-head__sample-layout .tab-element .tab-link{
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    color: #666666;
    display: block;
    line-height: 1;
    background: transparent;
    min-width: 129px;
    text-align: center;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 10px;
    padding-bottom: 21px;
}
.tab-head__sample-layout .tab-element .tab-link:hover,
.tab-head__sample-layout .active .tab-link{
    background: url("../images/bg_tab-v4.png");
    color: #ffffff;
}
.tab-head__sample-layout.type-02 .tab-element .tab-link:hover,
.tab-head__sample-layout.type-02 .active .tab-link{
    background: url("../images/bg_tab-v1.png");
}
.biolife-banner__promotion{
    display: block;
    position: relative;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
}
.biolife-banner__promotion .text-content{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    line-height: 1;
    display: inline-block;
    padding-top: 217px;
}
.biolife-banner__promotion .text-content .text-wrap{
    padding-left: 70px;
}
.biolife-banner__promotion .text-content .first-line{
    font-size: 30px;
    line-height: 35px;
    font-family: 'Playfair Display', sans-serif;
    color: #ffffff;
    display: block;
    margin: 0;
    max-width: 370px;
}
.biolife-banner__promotion .text-content .second-line{
    display: block;
    margin: 6px 0 0;
    font-size: 60px;
    line-height: 55px;
    color: #ffffff;
    font-weight: 700;
    max-width: 370px;
}
.biolife-banner__promotion .text-content .third-line{
    font-size: 16px;
    line-height: 24px;
    display: block;
    margin: 22px 0 0;
    color: #ffffff;
    max-width: 370px;
}
.biolife-banner__promotion .product-detail{
    display: block;
    margin-top: 32px;
    max-width: 370px;
}
.biolife-banner__promotion .product-detail .txt-price{
    font-size: 40px;
    line-height: 1;
    font-weight: 700;
    color: #ffffff;
    display: inline-block;
    margin: 0 30px 0 0;
}
.biolife-banner__promotion .product-detail .txt-price span{
    font-size: 20px;
    font-weight: 400;
    margin-right: 3px;
}
.biolife-banner__promotion3 .product-detail .add-to-cart-btn,
.biolife-banner__promotion .product-detail .add-to-cart-btn{
    display: inline-block;
    font-size: 14px;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    border: none;
    border-radius: 999px;
    vertical-align: top;
    background: #87be00;
    min-width: 168px;
    padding: 13px;
}
.biolife-banner__promotion3 .product-detail .add-to-cart-btn:hover,
.biolife-banner__promotion .product-detail .add-to-cart-btn:hover{
    background-color: #444444;
}
.biolife-banner__promotion{
    display: inline-block;
    width: 100%;
    height: 710px;
    background-image: url("../images/home-03/bg_banner_pomotion_org.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center top;
}
.biolife-banner__promotion .media .img-moving{
    display: inline-block;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
}
.biolife-banner__promotion .media .position-1{
    top: 137px;
    left: 50%;
    margin-left: -21px;
}
.biolife-banner__promotion .media .position-2{
    left: auto;
    right: -21px;
    top: auto;
    bottom: 135px;
}
.biolife-banner__promotion .media .position-3{
    top: auto;
    bottom: 0;
    left: 42px;
}
.biolife-banner__promotion .media .position-4{
    top: 93px;
    left: -9px;
}
.biolife-banner__promotion4 .position-2 img,
.biolife-banner__promotion .media .position-1 img{
    -webkit-animation: bounceIn 9s linear infinite;
    animation: bounceIn 9s linear infinite;
}
.biolife-banner__promotion .media .position-2 img{
    -webkit-animation: movebounce 3.9s linear 0.4s infinite;
    animation: movebounce 3.9s linear 0.4s infinite;
}
.biolife-banner__promotion .media .position-3 img{
    -webkit-animation: movebounce-x 3.9s linear 0.2s infinite;
    animation: movebounce-x 3.9s linear 0.2s infinite;
}
.biolife-banner__promotion .media .position-4 img{
    -webkit-animation: movebounce 3.9s linear infinite;
     animation: movebounce 3.9s linear infinite;
}
.biolife-banner__promotion2{
    display: block;
    background-color: #ffffff;
    position: relative;
    font-size: 0;
    line-height: 0;
    border-bottom: 1px solid #e6e6e6;
}
.biolife-banner__promotion2 .container{
    position: relative;
}
.biolife-banner__promotion2 .media{
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 670px;
    height: 535px;
    background-image: url(../images/home-03/slomotion_02_bg.png);
    background-position: center center;
    background-repeat: no-repeat;
}
.biolife-banner__promotion2 .text-content{
    display: inline-block;
    line-height: 1;
    float: right;
    max-width: 500px;
    margin-top: 117px;
    margin-bottom: 102px;
}
.biolife-banner__promotion2 .text-content .second-line,
.biolife-banner__promotion2 .text-content .first-line{
    display: block;
    margin: 0;
    font-size: 35px;
    line-height: 1;
    color: #333333;
    font-weight: 600;
}
.biolife-banner__promotion2 .text-content .second-line{
    margin-top: -1px;
}
.biolife-banner__promotion2 .text-content .second-line i{
    font-family: 'Playfair Display', sans-serif;
    font-size: 40px;
    font-weight: 700;
}
.biolife-banner__promotion2 .text-content .third-line{
    font-size: 15px;
    line-height: 24px;
    color: #666666;
    display: block;
    margin: 9px 0 0;
}
.biolife-banner__promotion2 .text-content .buttons{
    margin: 28px 0 0;
}
.biolife-banner__promotion2 .text-content .btn{
    min-width: 147px;
    padding: 14px 14px 13px;
}
.biolife-banner__promotion2 .text-content .btn-thin{
    min-width: 177px;
    border-color: #ededed;
    padding: 12px 12px 11px;
    margin-left: 19px;
    vertical-align: top;
}
.biolife-banner__promotion2.advance .text-content .second-line i{
    font-weight: 600;
    font-style: normal;
    font-family: 'Cairo', sans-serif;
    text-transform: uppercase;
}
.biolife-banner__promotion3{
    display: block;
    clear: both;
    height: 451px;
    background: url("../images/home-03/promotion_bg_green-version.jpg");
    border-bottom: 1px solid #e6e6e6;
    background-position: center center;
    background-size: cover;
    position: relative;
}
.biolife-banner__promotion3 .media .position-1{
    position: absolute;
    left: 41%;
    top: 17px;
    z-index: 5;
    display: inline-block;
}
.biolife-banner__promotion3 .text-content{
    padding-top: 92px;
}
.biolife-banner__promotion3 .text-content .first-line{
    font-size: 30px;
    line-height: 1;
    display: block;
    margin: 0;
    max-width: 300px;
}
.biolife-banner__promotion3 .text-content .second-line{
    display: block;
    margin: 6px 0 0;
    font-size: 40px;
    line-height: 1;
    color: #222222;
    font-weight: 600;
    max-width: 300px;
    text-transform: uppercase;
}
.biolife-banner__promotion3 .text-content .third-line{
    display: block;
    margin: 18px 0 0;
    color: #666666;
    font-size: 15px;
    line-height: 24px;
    max-width: 400px;
}
.biolife-banner__promotion3 .product-detail{
    display: block;
    font-size: 0;
    line-height: 0;
    margin-top: 30px;
}
.biolife-banner__promotion3 .product-detail .txt-price{
    display: inline-block;
    line-height: 1;
    margin: 0 29px 0 0;
    font-size: 30px;
    color: #222222;
    font-weight: 700;
    vertical-align: middle;
}
.biolife-banner__promotion3 .product-detail .txt-price span{
    color: #888888;
    font-size: 14px;
    font-weight: 400;
    margin-right: 3px;
}
.biolife-banner__promotion3 .product-detail .add-to-cart-btn{
    display: inline-block;
    vertical-align: middle;
}
.biolife-banner__promotion4{
    display: block;
    clear: both;
    background: url('../images/home-04/banner-promotion01.jpg');
    background-position: center center;
    background-size: cover;
    height: 650px;
    font-size: 0;
    line-height: 0;
}
.biolife-banner__promotion4 .banner-contain{
    display: block;
    position: relative;
}
.biolife-banner__promotion4 .img-moving{
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}
.biolife-banner__promotion4 .position-1{
    left: 37%;
    top: 68px;
}
.biolife-banner__promotion4 .position-2{
    left: 64%;
    top: 17px;
    z-index: 5;
    margin-left: 3px;
}
.biolife-banner__promotion4 .text-content{
    display: inline-block;
    text-align: center;
    max-width: 430px;
    position: absolute;
    top: 189px;
    left: 0;
    z-index: 10;
}
.biolife-banner__promotion4 .text-content .first-line{
    font-size: 50px;
    line-height: 50px;
    color: #222222;
    font-weight: 600;
    display: block;
    margin: 0;
}
.biolife-banner__promotion4 .biolife-countdown{
    margin-top: 23px;
}
.biolife-banner__promotion4:not(.v2) .biolife-countdown>span{
    border: none;
}
.biolife-banner__promotion4 .biolife-countdown>span{
    padding: 10px 5px 12px;
}
.biolife-banner__promotion4 .buttons{
    margin: 0;
}
.biolife-banner__promotion4 .buttons .btn.btn-bold{
    padding: 13px;
    min-width: 188px;
    margin-top: 35px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.biolife-banner__promotion4 .buttons .btn.green-btn:not(:hover){
    background-color: #87be00;
}
.biolife-banner__promotion4.v2{
    height: 450px;
    background: #fafafa;
}
.biolife-banner__promotion4.v2 .sub-line{
    font-size: 24px;
    color: #f79822;
    line-height: 1;
    display: block;
    margin: 0;
}
.biolife-banner__promotion4.v2 .text-content .first-line{
    margin-top: 9px;
}
.biolife-banner__promotion4.v2 .position-1{
    left: auto;
    right: -58px;
    top: 0;
    overflow: hidden;
}
.biolife-banner__promotion4.v2 .position-2{
    left: auto;
    right: 0;
    top: 35px;
}
.biolife-banner__promotion6,
.biolife-banner__promotion5{
    display: block;
    clear: both;
    font-size: 0;
    line-height: 0;
}
.biolife-banner__promotion6 .banner-contain,
.biolife-banner__promotion5 .banner-contain{
    position: relative;
    display: block;
}
.biolife-banner__promotion5 .banner-contain .media{
    display: block;
    height: 630px;
    background-image: url('../images/home-02/banner-promotion-5.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.biolife-banner__promotion6 .media .img-moving,
.biolife-banner__promotion5 .media .img-moving{
    position: absolute;
    top: -70px;
    right: 4%;
    margin-right: -7px;
    z-index: 1;
}
.biolife-banner__promotion5 .media .img-moving img{
    -webkit-animation: move-around-reverse 9s linear 0.2s infinite;
    animation: move-around-reverse 9s linear 0.2s infinite;
}
.biolife-banner__promotion5 .media .position-2{
    top: 43px;
    right: 50%;
    margin-right: 1px;
    z-index: 2;
}
.biolife-banner__promotion5 .media .position-2 img{
    -webkit-animation: move-around 8.5s linear infinite;
    animation: move-around 8.5s linear infinite;
}
.biolife-banner__promotion5 .text-content{
    display: inline-block;
    position: absolute;
    right: 64%;
    text-align: center;
    max-width: 310px;
    top: 155px;
    margin-right: 6px;
    z-index: 15;
}
.biolife-banner__promotion5 .text-content .text1{
    font-size: 30px;
    font-family: 'Playfair Display', sans-serif;
    color: #ffffff;
    display: block;
    margin: 0;
    line-height: 1;
}
.biolife-banner__promotion5 .text-content .text2{
    display: block;
    font-size: 60px;
    line-height: 55px;
    margin: 26px 0 0;
    color: #ffffff;
}
.biolife-banner__promotion5 .text-content .buttons .btn{
    min-width: 177px;
    margin-top: 24px;
    padding: 14px 10px 13px;
}
.biolife-banner__promotion6 .banner-contain .media{
    display: block;
    height: 769px;
    background-image: url('../images/home-01/bn-promotion-6.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.biolife-banner__promotion6 .banner-contain .text-content{
    position: absolute;
    top: 30%;
    left: 20%;
    display: inline-block;
    max-width: 400px;
    text-align: center;
    margin-top: -5px;
    margin-left: 13px;
    z-index: 10;
}
.biolife-banner__promotion6 .text-content .text1{
    color: #ffffff;
    font-size: 30px;
    display: block;
    margin: 0;
    line-height: 1;
    font-family: 'Playfair Display', sans-serif;
    font-weight: 400;
}
.biolife-banner__promotion6 .text-content .text2{
    font-size: 60px;
    color: #ffffff;
    font-weight: 700;
    display: block;
    margin: 27px 0 0;
    line-height: 55px;
    text-transform: uppercase;
}
.biolife-banner__promotion6 .text-content .buttons{
    display: block;
    margin: 39px 0 0;
}
.biolife-banner__promotion6 .text-content .btn-thin{
    color: #ffffff;
    min-width: 148px;
    padding: 11px 10px 10px;
    font-size: 15px;
}
.biolife-banner__promotion6 .media .img-moving.position-1{
    right: 20%;
    top: auto;
    bottom: -15px;
    margin-right: -12px;
}
.biolife-banner__promotion6 .media .img-moving.position-1 img{
    -webkit-animation: move-around-reverse 9s linear 0.2s infinite;
    animation: move-around-reverse 9s linear 0.2s infinite;
}
.biolife-banner__promotion6 .media .img-moving.position-2{
    right: 18%;
    bottom: 40px;
    top: auto;
    margin-right: -13px;
}
.biolife-banner__promotion6 .media .img-moving.position-2 img{
    -webkit-animation: move-around 8.5s linear infinite;
    animation: move-around 8.5s linear infinite;
}
.biolife-title-box__bold-style{
    display: block;
    margin: 0 0 38px;
    text-align: left;
    font-size: 0;
    line-height: 0;
    padding-right: 75px;
}
.biolife-title-box__bold-style-02 .title,
.biolife-title-box__bold-style .title{
    font-size: 30px;
    line-height: 1;
    display: inline-block;
    color: #000000;
    font-weight: 700;
    margin: 0;
}
.biolife-title-box__bold-style-02{
    display: block;
    margin: 0 0 27px;
    text-align: left;
    font-size: 0;
    line-height: 0;
}
.biolife-title-box__bold-style-02 .title{
    font-size: 22px;
}
.biolife-title-box__under-line{
    display: inline-block;
    width: 100%;
    text-align: left;
    border-bottom: 2px solid #e6e6e6;
    line-height: 0;
    font-size: 0;
    padding-bottom: 16px;
    margin-bottom: 20px;
}
.biolife-title-box__under-line .title{
    font-size: 24px;
    line-height: 1;
    display: inline-block;
    margin: 0;
    color: #222222;
    font-weight: 700;
}
.biolife-title-box__bgrd-bold{
    display: block;
    text-align: left;
    margin-top: 73px;
    margin-bottom: 18px;
}
.biolife-title-box__bgrd-bold .title{
    display: inline-block;
    margin: 0;
    font-size: 40px;
    color: #ffffff;
    line-height: 1;
    background-image: url(../images/home-01/bg_bold-title.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 314px;
    padding: 14px 35px 25px 43px;
    font-weight: 700;
    background-position: center;
}
.biolife-countdown{
    display: block;
    font-size: 0;
    line-height: 0;
    margin: 10px 0 0;
    text-align: center;
}
.biolife-countdown>span{
    display: inline-block;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    background-color: #ffffff;
    text-align: center;
    min-width: 57px;
    font-size: 0;
    line-height: 0;
    padding: 9px 5px 11px;
}
.biolife-countdown>span+span{
    margin-left: 9px;
}
.biolife-countdown .number{
    display: block;
    font-size: 20px;
    line-height: 1;
    font-weight: 700;
    color: #222222;
}
.biolife-countdown .text{
    font-size: 13px;
    line-height: 1;
    color: #666666;
    font-weight: 400;
    display: block;
    text-transform: uppercase;
    margin-top: 2px;
}
.contain-product__deal-layout{
    border: 2px solid #ff810f;
    padding: 17px 18px 29px;
    background-color: #fafafa;
}
.contain-product.contain-product__deal-layout .product-thumb .labels{
    left: 0;
    top: 0;
}
.contain-product.contain-product__deal-layout .product-thumb{
    padding: 0;
}
.contain-product__deal-layout .info .categories{
    margin-top: 19px;
}
.contain-product__deal-layout .info .price{
    margin-bottom: 11px;
}
.contain-product__deal-layout .slide-down-box .message{
    padding: 0 25px;
}
.contain-product__deal-layout .slide-down-box .buttons .add-to-cart-btn{
    padding: 16px 10px 17px;
}
.biolife-carousel.nav-center-03 .slick-arrow,
.biolife-carousel.nav-top-right .slick-arrow{
    font-size: 33px;
    display: inline-block;
    position: absolute;
    top: -64px;
    right: 0;
    z-index: 3;
    color: rgba(102, 102, 102, 0.20);
}
.biolife-carousel.nav-center-03 .slick-arrow:hover,
.biolife-carousel.nav-top-right .slick-arrow:hover{
    color: #7faf51;
}
.biolife-carousel.nav-top-right .slick-arrow.prev{
    right: 41px;
}
.biolife-carousel.nav-center-03 .slick-arrow{
    top: 50%;
    margin-top: -17px;
    right: 0;
    left: auto;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    -webkit-transition: all 350ms ease;
    -moz-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    transition: all 350ms ease;
}
.biolife-carousel.nav-center-03 .slick-arrow.prev{
    right: auto;
    left: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
}
.biolife-carousel.nav-center-03:hover .slick-arrow.prev,
.biolife-carousel.nav-center-03:hover .slick-arrow{
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}
.biolife-carousel.nav-top-right-02{
    position: relative;
}
.biolife-carousel.nav-top-right-02 .slick-arrow{
    display: block;
    position: absolute;
    font-size: 0;
    line-height: 0;
    top: -45px;
    right: 0;
    cursor: pointer;
}
.biolife-carousel.nav-top-right-02 .slick-arrow::before{
    font-family: 'FontAwesome', sans-serif;
    content: "\f105";
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-size: 20px;
    color: #666666;
    line-height: 1;
}
.biolife-carousel.nav-top-right-02 .slick-arrow:hover::before{
    color: #aea705;
}
.biolife-carousel.nav-top-right-02 .slick-arrow.prev{
    right: 21px;
}
.biolife-carousel.nav-top-right-02 .slick-arrow.prev::before{
    content: "\f104";
}
.contain-product__right-info-layout.contain-product .product-thumb{
    padding: 0;
}
.contain-product__right-info-layout{
    padding: 9px 10px 10px;
    display: table;
    table-layout: fixed;
    width: 100%;
    border: 1px solid #e6e6e6;
}
.contain-product__right-info-layout .info,
.contain-product__right-info-layout .product-thumb{
    display: table-cell;
    vertical-align: top;
}
.contain-product__right-info-layout .product-thumb{
    width: 170px;
}
.contain-product__right-info-layout .info{
    padding-left: 10px;
    padding-top: 4px;
}
.contain-product__right-info-layout .info .price,
.contain-product__right-info-layout .info .product-title,
.contain-product__right-info-layout .info .categories{
    text-align: left;
}
.contain-product__right-info-layout .info .price{
    margin-bottom: 3px;
}
.contain-product__right-info-layout .info .rating{
    font-size: 14px;
}
.contain-product__right-info-layout .info .rating .star-rating{
    vertical-align: middle;
}
.contain-product__right-info-layout2{
    display: table;
    width: 100%;
}
.contain-product__right-info-layout2 .product-thumb,
.contain-product__right-info-layout2 .info{
    display: table-cell;
    vertical-align: top;
}
.contain-product__right-info-layout2 .product-thumb{
    width: 100px;
    padding: 0 !important;
}
.contain-product__right-info-layout2 .info{
    padding-left: 10px;
    text-align: left;
}
.contain-product__right-info-layout2 .info .product-title{
    text-align: left;
}
.contain-product__right-info-layout2 .info .price{
    text-align: left;
    margin-bottom: 3px;
}
.contain-product__right-info-layout2 .info .star-rating{
    vertical-align: middle;
}
.contain-product__right-info-layout2 .info .review-count{
    font-size: 14px;
    line-height: 24px;
    display: inline-block;
    vertical-align: middle;
}
.contain-product__right-info-layout2.wgt-style .rating .star-rating > span::before,
.contain-product__right-info-layout2.wgt-style .rating .star-rating::before{
    font-size: 14px;
}
.contain-product__right-info-layout2.cate .info .cat-info{
    display: block;
    font-size: 0;
    line-height: 0;
    margin-top: 7px;
}
.contain-product__right-info-layout2.cate .cat-info .cat-item{
    font-size: 15px;
    color: #888888;
    line-height: 1;
    text-transform: capitalize;
    display: inline-block;
    margin: 0 10px 0 0;
}
.contain-product__right-info-layout3{
    display: table;
    margin: 0;
    width: 100%;
    padding: 10px 18px 18px 10px;
    position: relative;
    background-color: #ffffff;
}
.contain-product__right-info-layout3 .info,
.contain-product__right-info-layout3 .product-thumb{
    display: table-cell;
    vertical-align: top;
}
.contain-product__right-info-layout3 .product-thumb{
    width: 130px !important;
    padding: 0 !important;
}
.contain-product__right-info-layout3 .info{
    padding-left: 10px;
    padding-top: 6px;
}
.contain-product__right-info-layout3 .info .product-title{
    margin: 0;
    font-size: 18px;
    color: #222222;
    line-height: 1;
    font-weight: 600;
    display: inline-block;
    max-width: calc(100% - 150px);
    padding-top: 2px;
}
.contain-product__right-info-layout3 .info .product-title a{
    color: inherit;
    line-height: inherit;
    font-size: inherit;
    display: inline-block;
}
.contain-product__right-info-layout3 .info .price{
    display: inline-block;
    max-width: 150px;
    text-align: right;
    float: right;
    margin: 0;
}
.contain-product__right-info-layout3 .info .price ins .price-amount{
    font-size: 26px;
}
.contain-product__right-info-layout3 .info .price del{
    margin-left: 13px;
}
.contain-product__right-info-layout3 .info .price del .price-amount{
    font-size: 16px;
}
.contain-product__right-info-layout3 .info .rating{
    display: block;
    margin: 0;
    text-align: left;
}
.contain-product__right-info-layout3 .info .rating{
    margin-top: 7px;
    margin-bottom: 9px;
}
.contain-product__right-info-layout3 .info .rating .review-count{
    display: inline-block;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    vertical-align: top;
}
.contain-product__right-info-layout3 .info .desc{
    display: block;
    margin: 0;
    color: #666666;
    font-size: 14px;
    line-height: 20px;
    border-top: 1px dashed #666666;
    padding-top: 10px;
}
.contain-product__right-info-layout3 .info .buttons{
    display: block;
    margin: 0;
    font-size: 0;
    line-height: 0;
    padding: 0 0 20px 150px;
    background-color: #ffffff;
}
.contain-product__right-info-layout3 .buttons .btn{
    display: inline-block;
    font-size: 15px;
    line-height: 1px;
    color: #888888;
    text-align: center;
    vertical-align: middle;
    padding: 12px 7px;
    border-radius: 0;
    box-shadow: none;
    outline: none;
}
.contain-product__right-info-layout3 .buttons .btn:hover{
    color: #444444;
}
.contain-product__right-info-layout3 .buttons .add-to-cart-btn{
    font-size: 14px;
    line-height: 1;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 9999px;
    font-weight: 700;
    min-width: 168px;
    padding: 16px 15px 15px;
    margin-right: 11px;
}
.contain-product__right-info-layout3 .buttons .add-to-cart-btn:hover{
    color: #ffffff;
    background-color: #444444;
}
.biolife-banner__style-15,
.biolife-banner__style-01{
    display: block;
    height: 207px;
    background-image: url(../images/home-03/biolife-banner__style-01.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    margin-top: 30px;
}
.biolife-banner__style-15 .bn-link,
.biolife-banner__style-01 .bn-link{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
}
.biolife-banner__style-15 .text-content,
.biolife-banner__style-01 .text-content{
    display: inline-block;
    position: absolute;
    top: 39px;
    right: 0;
    z-index: 5;
    font-size: 0;
    line-height: 1;
    width: 268px;
}
.biolife-banner__style-15 .text-content .first-line,
.biolife-banner__style-01 .text-content .first-line{
    display: block;
    margin: 0;
    font-size: 15px;
    line-height: 1;
    color: #666666;
    text-transform: uppercase;
}
.biolife-banner__style-15 .text-content .third-line,
.biolife-banner__style-15 .text-content .second-line,
.biolife-banner__style-01 .text-content .third-line,
.biolife-banner__style-01 .text-content .second-line{
    font-size: 40px;
    color: #222222;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    margin: 7px 0 0;
}
.biolife-banner__style-15 .text-content .third-line,
.biolife-banner__style-01 .text-content .third-line{
    font-family: 'Playfair Display', sans-serif;
    text-transform: unset;
    margin-top: -2px;
}
.biolife-banner__style-15 .text-content .fourth-line,
.biolife-banner__style-01 .text-content .fourth-line{
    font-size: 14px;
    color: #666666;
    line-height: 1;
    display: block;
    text-transform: uppercase;
    margin: 14px 0 0;
}
.biolife-banner__style-15{
    background: url("../images/home-05/bn_style-15.jpg");
    height: 200px;
    background-size: cover;
}
.biolife-banner__style-15 .text-content{
    right: 33px;
}
.biolife-stretch-the-right-background{
    position: relative;
    height: 450px;
}
.biolife-stretch-the-right-background>*{
    position: relative;
    z-index: 5;
}
.biolife-stretch-the-right-background>.bg_underground{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: cover;
}
.biolife-brd-container{
    text-align: center;
}
.biolife-brd-container img{
    display: inline-block;
}
.style-bottom-info.layout-02 .thumbnail{
    border: none;
    padding: 0;
    margin: 0;
    font-size: 0;
    line-height: 0;
    border-radius: 0;
    position: relative;
}
.style-bottom-info.layout-02 .thumbnail .post-date{
    display: inline-block;
    position: absolute;
    left: 10px;
    top: 9px;
    background-color: #ededed;
    border-radius: 50%;
    min-width: 53px;
    padding: 8px 5px 9px;
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.style-bottom-info.layout-02 .thumbnail .post-date span{
    display: block;
    font-size: 14px;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
}
.style-bottom-info.layout-02 .post-content .post-name{
    font-weight: 700;
    color: #222222;
    font-size: 22px;
}
.style-bottom-info.layout-02 .post-meta{
    display: block;
    font-size: 0;
    line-height: 0;
    margin: 16px 0 0;
    text-align: center;
}
.style-bottom-info.layout-02 .post-meta__item{
    display: inline-block;
    line-height: 24px;
    font-size: 15px;
}
.style-bottom-info.layout-02 .post-meta__item.author{
    color: #222222;
    font-weight: 600;
    margin-right: 36px;
}
.style-bottom-info.layout-02 .post-meta__item.btn{
    font-size: 14px;
    line-height: 24px;
    color: #666666;
    padding: 0;
    vertical-align: inherit;
    margin-right: 8px;
}
.style-bottom-info.layout-02 .post-meta__item.author img{
    display: inline-block;
    font-size: 0;
    line-height: 0;
    margin-right: 8px;
}
.style-bottom-info.layout-02 .post-meta__item>.btn{
    font-size: 14px;
    color: #666666;
    padding: 0;
    font-weight: 600;
}
.style-bottom-info.layout-02 .post-meta__item .biolife-icon{
    font-size: 16px;
    margin-left: 6px;
    vertical-align: middle;
}
.style-bottom-info.layout-02 .post-meta__item-social-box{
    font-size: 17px;
    color: #666666;
    position: relative;
}
.style-bottom-info.layout-02 .post-meta__item-social-box .tbn{
    display: inline-block;
}
.style-bottom-info.layout-02 .post-meta__item-social-box ul{
    padding: 0;
    margin: 0;
}
.style-bottom-info.layout-02 .post-meta__item-social-box li{
    list-style: none;
    display: inline-block;
    color: #666666;
    line-height: 1;
}
.style-bottom-info.layout-02 .post-meta__item-social-box li+li{
    margin-left: 5px;
}
.style-bottom-info.layout-02 .post-meta__item-social-box li a{
    color: inherit;
    display: inline-block;
}
.style-bottom-info.layout-02 .post-meta__item-social-box .inner-content{
    display: inline-block;
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 124px;
    margin-left: -62px;
    background-color: #f0f0f0;
    padding: 5px;
    z-index: 5;
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transition: all 350ms ease;
    -moz-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    transition: all 350ms ease;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
}
.style-bottom-info.layout-02 .post-meta__item-social-box:hover .inner-content{
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
}
.style-bottom-info.layout-02 .post-content .excerpt{
    margin-top: 8px;
}
.style-bottom-info.layout-02 .post-content{
    padding-left: 3px;
    padding-right: 3px;
}
.style-bottom-info.layout-02 .group-buttons .btn.readmore{
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    border: none;
}
.style-bottom-info.layout-02 .group-buttons .btn.readmore:hover{
    color: #444444;
    background-color: transparent;
}
.style-bottom-info.layout-02 .post-content .group-buttons{
    margin-top: 16px;
    margin-bottom: 20px;
}
.style-bottom-info.layout-02.align-left .post-content .post-name{
    margin-top: 13px;
    font-size: 17px;
    line-height: 24px;
}
.style-bottom-info.layout-02.align-left .post-meta{
    text-align: right;
    margin-top: 21px;
}
.style-bottom-info.layout-02.align-left .post-meta__item.author{
    float: left;
}
.style-bottom-info.layout-02.align-left .post-meta__item-social-box .inner-content{
    left: auto;
    right: 1px;
    margin: 0;
}
.style-bottom-info.layout-02.align-left{
    text-align: left;
    padding-bottom: 20px;
}
.style-bottom-info.layout-03 .thumbnail{
    padding: 0;
    display: block;
    margin: 0;
    font-size: 0;
    line-height: 0;
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 0;
}
.style-bottom-info.layout-03 .thumbnail .post-date{
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 10px;
    min-width: 64px;
    background-color: #444444;
    border-radius: 50%;
    padding: 13px 5px 12px;
}
.style-bottom-info.layout-03 .thumbnail .post-date span{
    display: block;
    margin: 0;
    color: #f3f3f3;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
}
.style-bottom-info.layout-03 .thumbnail .post-date .month{
    font-size: 13px;
    font-weight: 600;
    margin-top: 6px;
}
.style-bottom-info.layout-03 .post-content{
    background-color: #ffffff;
    display: inline-block;
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 38px;
}
.style-bottom-info.layout-03 .post-content .post-name{
    margin-top: 17px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
}
.style-bottom-info.layout-03 .post-content .post-meta{
    display: inline-block;
    width: 100%;
    margin-top: 9px;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 13px;
}
.style-bottom-info.layout-03 .post-meta .count-number:hover{
    color: #333333;
}
.style-bottom-info.layout-03 .post-meta .count-number:hover .number{
    background-color: #333333 !important;
}
.style-bottom-info.layout-03 .post-meta__item{
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    color: #666666;
    margin: 0;
}
.style-bottom-info.layout-03 .post-meta__item a{
    color: #222222;
}
.style-bottom-info.layout-03 .post-meta .count-number{
    font-size: 15px;
}
.style-bottom-info.layout-03 .post-content .excerpt{
    font-size: 14px;
    margin: 23px 0 0;
}
.style-bottom-info.layout-03 .post-content .group-buttons{
    margin-top: 20px;
}
.style-bottom-info.layout-03 .group-buttons .btn.readmore{
    border: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    border-radius: 0;
    background-color: transparent;
}
.style-bottom-info.layout-03 .group-buttons .btn.readmore:hover{
    border: none;
    background: transparent;
    color: #333333;
}


.biolife-vertical-menu .vertical-category-block>.wrap-menu>.menu{
    padding: 8px 0 15px;
    margin: 0;
}
.biolife-banner__style-14,
.biolife-banner__style-13,
.biolife-banner__style-10,
.biolife-banner__style-09,
.biolife-banner__style-08,
.biolife-banner__style-04,
.biolife-banner__style-03,
.biolife-banner__style-02{
    display: block;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    position: relative;
    height: 250px;
    background-color: #c4a8d5;
    background-position: top left;
    background-size: initial;
}
.biolife-banner__style-14 .media,
.biolife-banner__style-13 .media,
.biolife-banner__style-10 .media,
.biolife-banner__style-09 .media,
.biolife-banner__style-08 .media,
.biolife-banner__style-04 .media,
.biolife-banner__style-03 .media,
.biolife-banner__style-02 .media{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    display: inline-block;
    -webkit-transition: transform 350ms ease;
    -moz-transition: transform 350ms ease;
    -ms-transition: transform 350ms ease;
    -o-transition: transform 350ms ease;
    transition: transform 350ms ease;
}
.biolife-banner__style-14:hover .media,
.biolife-banner__style-13:hover .media,
.biolife-banner__style-10:hover .media,
.biolife-banner__style-09:hover .media,
.biolife-banner__style-08:hover .media,
.biolife-banner__style-04:hover .media,
.biolife-banner__style-03:hover .media,
.biolife-banner__style-02:hover .media{
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}
.biolife-banner__style-14::before,
.biolife-banner__style-13::before,
.biolife-banner__style-04::before,
.biolife-banner__style-03::before,
.biolife-banner__style-02::before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.2);
    z-index: 2;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    -webkit-transition: all 350ms ease;
    -moz-transition: all 350ms ease;
    -ms-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    transition: all 350ms ease;
}
.biolife-banner__style-14:hover::before,
.biolife-banner__style-13:hover::before,
.biolife-banner__style-04:hover::before,
.biolife-banner__style-03:hover::before,
.biolife-banner__style-02:hover::before{
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
}
.biolife-banner__style-14 .text-content,
.biolife-banner__style-13 .text-content,
.biolife-banner__style-10 .text-content,
.biolife-banner__style-09 .text-content,
.biolife-banner__style-08 .text-content,
.biolife-banner__style-04 .text-content,
.biolife-banner__style-03 .text-content,
.biolife-banner__style-02 .text-content{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 8;
    display: inline-block;
    text-align: center;
}
.biolife-banner__style-02 .media{
    top: 13px;
    left: -8px;
}
.biolife-banner__style-13 .text-content,
.biolife-banner__style-08 .text-content,
.biolife-banner__style-02 .text-content{
    width: 187px;
    top: 58px;
}
.biolife-banner__style-13 .text1,
.biolife-banner__style-08 .text1,
.biolife-banner__style-02 .text1{
    color: #eeeeee;
    font-family: 'Playfair Display', sans-serif;
    font-size: 20px;
    line-height: 1;
    display: block;
    margin: 0;
}
.biolife-banner__style-13 .text2,
.biolife-banner__style-08 .text2,
.biolife-banner__style-02 .text2{
    font-size: 30px;
    line-height: 30px;
    display: block;
    margin: 12px 0 0;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 700;
}
.biolife-banner__style-13 .text-content{
    right: 20px;
}
.biolife-banner__style-13 .media{
    top: 16px;
    left: 20px;
}
.biolife-banner__style-14,
.biolife-banner__style-03{
    background-color: #f6919c;
}
.biolife-banner__style-03 .media{
    top: 25px;
    left: -9px;
}
.biolife-banner__style-14 .text-content,
.biolife-banner__style-09 .text-content,
.biolife-banner__style-03 .text-content{
    top: 93px;
    width: 190px;
}
.biolife-banner__style-14 .text3,
.biolife-banner__style-14 .text1,
.biolife-banner__style-09 .text3,
.biolife-banner__style-09 .text1,
.biolife-banner__style-03 .text3,
.biolife-banner__style-03 .text1{
    font-size: 21px;
    color: #ffffff;
    text-transform: uppercase;
    display: block;
    margin: 0;
    font-weight: 600;
    text-align: center;
    line-height: 1;
    letter-spacing: 1px;
}
.biolife-banner__style-14 .text2,
.biolife-banner__style-09 .text2,
.biolife-banner__style-03 .text2{
    font-size: 30px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    display: block;
    margin: 10px 0 0;
}
.biolife-banner__style-14 .text3,
.biolife-banner__style-09 .text3,
.biolife-banner__style-03 .text3{
    margin-top: 7px;
}
.biolife-banner__style-14 .media{
    top: 25px;
    left: 4px;
}
.biolife-banner__style-04{
    background-color: #f6f1de;
}
.biolife-banner__style-10 .media,
.biolife-banner__style-04 .media{
    top: 22px;
    left: 28px;
}
.biolife-banner__style-10 .text-content,
.biolife-banner__style-04 .text-content{
    width: 207px;
    top: 70px;
}
.biolife-banner__style-10 .text1,
.biolife-banner__style-04 .text1{
    display: block;
    margin: 0;
    font-size: 28px;
    line-height: 30px;
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
}
.biolife-banner__style-10 .text2,
.biolife-banner__style-04 .text2{
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    display: block;
    margin: 2px 0 0;
    text-transform: uppercase;
    font-weight: 600;
}
.biolife-banner__style-10 .text2 span,
.biolife-banner__style-04 .text2 span{
    font-weight: 700;
}
.biolife-banner__style-08,
.biolife-banner__style-09,
.biolife-banner__style-10{
    background-color: #f0f8eb;
    height: 185px;
}
.biolife-banner__style-10 .media,
.biolife-banner__style-09 .media,
.biolife-banner__style-08 .media{
    top: 0;
    left: 0;
}
.biolife-banner__style-10 .text-content,
.biolife-banner__style-09 .text-content,
.biolife-banner__style-08 .text-content{
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
.biolife-banner__style-08 .text1,
.biolife-banner__style-09 .text1,
.biolife-banner__style-09 .text3{
    color: #666666;
}
.biolife-banner__style-08 .text2,
.biolife-banner__style-09 .text2{
    color: #222222;
}
.biolife-banner__style-10 .text2 span{
    color: #508d00;
}
.biolife-banner__style-05,
.biolife-banner__style-06,
.biolife-banner__style-07{
    display: block;
    font-size: 0;
    line-height: 0;
}
.biolife-banner__style-05 .banner-contain,
.biolife-banner__style-06 .banner-contain,
.biolife-banner__style-07 .banner-contain{
    position: relative;
    height: 230px;
    background-color: #f0f8eb;
    display: block;
}
.biolife-banner__style-06 .banner-contain{
    background-color: #f6f1de;
}
.biolife-banner__style-07 .banner-contain{
    background-color: #edf5fd;
}
.biolife-banner__style-05 .media,
.biolife-banner__style-06 .media,
.biolife-banner__style-07 .media{
    font-size: 0;
    display: inline-block;
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}
.biolife-banner__style-05 .btn-shopnow,
.biolife-banner__style-06 .btn-shopnow,
.biolife-banner__style-07 .btn-shopnow{
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    color: #ffffff;
    min-width: 125px;
    text-align: center;
    border-radius: 999px;
    border: none;
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 10px 11px;
    margin: 12px 0 0;
}
.biolife-banner__style-05 .btn-shopnow:hover,
.biolife-banner__style-06 .btn-shopnow:hover,
.biolife-banner__style-07 .btn-shopnow:hover{
    background-color: #444444;
}
.biolife-banner__style-05 .text-content,
.biolife-banner__style-06 .text-content,
.biolife-banner__style-07 .text-content{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    text-align: left;
}
.biolife-banner__style-05 .text-content{
    max-width: 156px;
    padding-top: 50px;
}
.biolife-banner__style-05 .text-content .text1{
    font-size: 20px;
    line-height: 24px;
    color: #222222;
    font-weight: 700;
    text-align: left;
    display: block;
    margin: 0;
    text-transform: uppercase;
}
.biolife-banner__style-07 .text-content .text-pr,
.biolife-banner__style-06 .text-content .text-pr,
.biolife-banner__style-05 .text-content .text-pr{
    display: block;
    margin: 6px 0 0;
    font-size: 24px;
    line-height: 24px;
    color: #222222;
    font-weight: 700;
}
.biolife-banner__style-07 .text-content .text-pr span,
.biolife-banner__style-06 .text-content .text-pr span,
.biolife-banner__style-05 .text-content .text-pr span{
    font-size: 14px;
    color: #666666;
    display: inline-block;
    font-weight: 400;
    margin-right: 4px;
}
.biolife-banner__style-05.layout-02 .text1{
    font-size: 16px;
    line-height: 22px;
}
.biolife-banner__style-05.layout-02 .text-content{
    max-width: 129px;
}
.biolife-banner__style-06.layout-02 .btn-shopnow,
.biolife-banner__style-05.layout-02 .btn-shopnow{
    color: #222222;
    min-width: 118px;
}
.biolife-banner__style-06.layout-02 .btn-shopnow:hover,
.biolife-banner__style-05.layout-02 .btn-shopnow:hover{
    color: #ffffff;
}
.biolife-banner__style-06 .text-content{
    width: 154px;
    padding-top: 45px;
}
.biolife-banner__style-06 .text-content .text2,
.biolife-banner__style-06 .text-content .text1{
    display: block;
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
}
.biolife-banner__style-06 .text-content .text2{
    font-size: 24px;
    margin-top: -3px;
    margin-bottom: 7px;
}
.biolife-banner__style-06.layout-02 .text-content{
    width: 124px;
}
.biolife-banner__style-07 .text-content{
    width: 154px;
    padding-top: 49px;
}
.biolife-banner__style-07 .text-content .text1{
    font-size: 26px;
    line-height: 24px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    margin: 0;
    letter-spacing: 3px;
}
.biolife-banner__style-07 .text-content .text2{
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    text-transform: uppercase;
    font-weight: 400;
    display: block;
    margin: 0;
}
.biolife-cat-box-item{
    display: block;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    text-align: center;
}
.biolife-cat-box-item .cat-thumb img{
    width: 100%;
    display: block;
}
.biolife-cat-box-item .cat-info{
    display: inline-block;
    border: 2px solid #e6e6e6;
    position: relative;
    margin-bottom: 10px;
    margin-top: -13px;
    background-color: #ffffff;
}
.biolife-cat-box-item .cat-name{
    font-size: 20px;
    line-height: 1;
    color: #222222;
    text-transform: uppercase;
    font-weight: 700;
    min-width: 164px;
    margin: 0;
    padding: 11px 10px 19px;
}
.biolife-cat-box-item .cat-number{
    font-size: 14px;
    color: #666666;
    font-family: 'Playfair Display', sans-serif;
    font-style: italic;
    background-color: #ffffff;
    display: inline-block;
    min-width: 90px;
    line-height: 1;
    position: absolute;
    left: 50%;
    bottom: -6px;
    margin-left: -45px;
}
.products-list__vertical-layout{
    padding: 0;
    margin: 0;
    display: block;
    font-size: 0;
    line-height: 0;
}
.products-list__vertical-layout li{
    list-style: none;
    display: block;
    padding-bottom: 14px;
}
.products-list__vertical-layout li+li{
    padding-top: 15px;
    border-top: 1px solid #e6e6e6;
}
.products-list.vertical-layout-02{
    display: block;
    clear: both;
    padding: 0;
    margin: 0;
    font-size: 0;
    line-height: 0;
    max-width: 600px;
    background-color: #ffffff;
}
.products-list.vertical-layout-02 li{
    display: block;
    list-style: none;
}
.products-category-box{
    position: relative;
    display: block;
    padding: 43px 100px 31px 40px;
}
.products-category-box .title{
    font-size: 30px;
    color: #222222;
    font-weight: 700;
    display: block;
    margin: 0 0 14px;
}
.grid-twice-item{
    display: block;
    clear: both;
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
}
.grid-twice-item>li{
    list-style: none;
    display: inline-block;
    width: calc( 50% - 8px);
    float: left;
    margin-left: 16px;
}
.grid-twice-item>li:nth-child(odd){
    clear: left;
    margin-left: 0;
}
.wrap-category{
    display: block;
    clear: both;
}
.biolife-banner__style-12 .banner-contain,
.biolife-banner__style-11 .banner-contain{
    display: table;
    width: 100%;
}
.biolife-banner__style-12 .banner-contain .media,
.biolife-banner__style-12 .banner-contain .text-content,
.biolife-banner__style-11 .banner-contain .media,
.biolife-banner__style-11 .banner-contain .text-content{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.biolife-banner__style-11 .banner-contain .text-content,
.biolife-banner__style-12 .banner-contain .text-content{
    position: relative;
    background-color: #ff4f63;
    border-color: #ff4f63;
}
.biolife-banner__style-11 .banner-contain .text-content::before,
.biolife-banner__style-12 .banner-contain .text-content::before{
    display: block;
    position: absolute;
    left: -15px;
    top: 50%;
    margin-top: -16px;
    border-right: 16px solid #ff4f63;
    border-color: inherit;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 0;
    content: '';
}
.biolife-banner__style-11 .banner-contain .text-content{
    background-color: #ffbc53;
    border-color: #ffbc53;
}
.biolife-banner__style-12 .banner-contain .media,
.biolife-banner__style-11 .banner-contain .media{
    width: 296px;
    padding-right: 15px;
}
.biolife-banner__style-12 .banner-contain .media .bn-link,
.biolife-banner__style-11 .banner-contain .media .bn-link{
    display: block;
    overflow: hidden;
}
.biolife-banner__style-11 .text3,
.biolife-banner__style-11 .text1{
    font-size: 26px;
    font-weight: 600;
    color: #eeeeee;
    display: block;
    margin: 0;
    line-height: 1;
    text-transform: uppercase;
}
.biolife-banner__style-11 .text2{
    font-size: 35px;
    display: block;
    line-height: 1;
    margin: 4px 0 3px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
}
.biolife-banner__style-11 .text4{
    color: #ffffff;
    font-size: 37px;
    text-transform: uppercase;
    display: block;
    margin: 17px 0 0;
    line-height: 1;
    font-weight: 700;
}
.biolife-banner__style-11 .text4 span{
    font-size: 50px;
}
.biolife-banner__style-12 .btn,
.biolife-banner__style-11 .btn{
    min-width: 148px;
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    outline: none;
    margin-top: 24px;
}
.biolife-banner__style-11 .btn.btn-bold:not(:hover),
.biolife-banner__style-12 .btn.btn-bold:not(:hover){
    background-color: #7faf51;
}
.biolife-banner__style-11 .btn.btn-bold,
.biolife-banner__style-12 .btn.btn-bold{
    margin-top: 26px;
}
.biolife-banner__style-12 .btn:hover,
.biolife-banner__style-11 .btn:hover{
    background-color: #444444 !important;
    border-color: #444444 !important;
}
.biolife-banner__style-12 .text1{
    font-size: 20px;
    color: #ffffff;
    display: block;
    margin: 0;
    line-height: 1;
    font-family: 'Playfair Display', sans-serif;
}
.biolife-banner__style-12 .text2{
    font-size: 30px;
    color: #ffffff;
    display: block;
    margin: 11px 0 0;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0 40px;
}
#sidebar.mobile-version .sidebar-contain>div{
    clear: both;
}
#sidebar.mobile-version .sidebar-contain{
    max-width: 100%;
}
.instagram-block{
    display: block;
    position: relative;
    margin-top: 35px;
}
.instagram-block ul{
    padding: 0;
    display: block;
    margin: -6px;
    font-size: 0;
    line-height: 0;
}
.instagram-block ul li{
    list-style: none;
    display: inline-block;
    width: 33.333333%;
    padding: 6px;
}
.instagram-block ul li a,
.instagram-block ul li img{
    display: block;
    width: 100%;
}
.instagram-block .txt-infront{
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: rgba(255,255,255,0.88);
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 149px;
    padding-top: 24px;
    padding-bottom: 29px;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
}
.instagram-block .txt-infront .title{
    font-size: 18px;
    line-height: 1;
    color: #222222;
    text-transform: uppercase;
    display: block;
    margin: 0;
    text-align: center;
    font-weight: 600;
}
.instagram-block .txt-infront .ac-name{
    display: block;
    margin: 3px 0 0;
    font-size: 14px;
    line-height: 1;
    color: #888888;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}
.instagram-block:hover .txt-infront{
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
}
.footer-midle-pst{
    display: block;
    clear: both;
    border-top: 1px solid #E6E6E6;
    padding-top: 38px;
    margin-top: 50px;
    padding-bottom: 35px;
}
.footer-midle-pst .announce-text{
    display: block;
    margin: 15px 0 0;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    text-align: center;
}
.footer.layout-01 .footer-midle-pst{
    padding-top: 26px;
    padding-bottom: 26px;
    margin-top: 31px;
}
.biolife-gird-banners{
    display: block;
    font-size: 0;
    line-height: 0;
    clear: both;
}
.biolife-gird-banners .grid-panel{
    display: table;
    width: 100%;
}
.biolife-gird-banners .grid-panel .grid-panel-item{
    display: table-cell;
    vertical-align: top;
}
.biolife-gird-banners .grid-panel-item.left-content{
    width: 33.59375%;
}
.biolife-gird-banners .grid-panel-item.right-content{
    width: 24.7395833333%;
}
.biolife-gird-banners .grid-panel .list-media{
    padding: 0;
    display: block;
    margin: 0;
}
.biolife-gird-banners .grid-panel .list-media li{
    list-style: none;
    display: inline-block;
    width: 50%;
}
.biolife-banner__grid{
    display: inline-block;
    width: 100%;
    position: relative;
}
.biolife-banner__grid .banner-contain{
    position: absolute;
    z-index: 1;
    bottom: 40px;
    display: block;
    left: 0;
    right: 0;
    text-align: center;
}
.biolife-banner__grid .banner-contain .cat-name{
    font-size: 26px;
    font-weight: 600;
    line-height: 1;
    color: #222222;
    letter-spacing: 1px;
    display: inline-block;
    margin: 0;
    position: relative;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.biolife-banner__grid:not(.type-02) .banner-contain .cat-name::before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    height: 1px;
    background-color: red;
    -webkit-transition: transform 500ms ease;
    -moz-transition: transform 500ms ease;
    -ms-transition: transform 500ms ease;
    -o-transition: transform 500ms ease;
    transition: transform 500ms ease;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}
.biolife-banner__grid:not(.type-02):hover .banner-contain .cat-name::before{
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
.biolife-banner__grid .media-contain{
    display: inline-block;
    height: 375px;
    width: 100%;
    background-image: url('../images/home-02/banner-grid-02.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}
.biolife-banner__grid .media-contain.media-01{
    background-image: url('../images/home-02/banner-grid-01.jpg');
    height: 750px;
}
.biolife-banner__grid .media-contain.media-03{
    background-image: url('../images/home-02/banner-grid-03.jpg');
}
.biolife-banner__grid .media-contain.media-04{
    background-image: url('../images/home-02/banner-grid-04.jpg');
}
.biolife-banner__grid .media-contain.media-05{
    background-image: url('../images/home-02/banner-grid-05.jpg');
}
.biolife-banner__grid .media-contain.media-06{
    background-image: url('../images/home-02/banner-grid-06.jpg');
    height: 750px;
    background-position: center center;
}
.biolife-banner__grid.type-02 .banner-contain{
    bottom: 8px;
}
.biolife-banner__grid.type-02 .banner-contain .cat-name{
    width: 100%;
    color: #ffffff;
    max-width: 314px;
    padding: 21px 15px 32px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../images/home-01/bn_grid_01.png);
}
.biolife-banner__grid.type-02.bn-elm-02 .banner-contain .cat-name{
    background-image: url(../images/home-01/bn_grid_02.png);
}
.biolife-banner__grid.type-02.bn-elm-03 .banner-contain .cat-name{
    background-image: url(../images/home-01/bn_grid_03.png);
}
.biolife-banner__grid.type-02.bn-elm-04 .banner-contain .cat-name{
    background-image: url(../images/home-01/bn_grid_04.png);
}
.biolife-banner__grid.type-02.bn-elm-05 .banner-contain .cat-name{
    background-image: url(../images/home-01/bn_grid_05.png);
}
.biolife-banner__grid.type-02.bn-elm-06 .banner-contain .cat-name{
    background-image: url(../images/home-01/bn_grid_06.png);
}

.biolife-banner__detail-product .text-content{
    padding-top: 26.9%;
}
.biolife-banner__detail-product .head-info h3{
    display: block;
    margin: 0;
    font-size: 35px;
    line-height: 1;
    color: #222222;
    font-weight: 600;
}
.biolife-banner__detail-product .head-info .text2{
    margin-top: -1px;
}
.biolife-banner__detail-product .head-info h3 i{
    font-size: 40px;
    font-family: 'Playfair Display', sans-serif;
    font-weight: 700;
}
.biolife-banner__detail-product .midle-info{
    padding-top: 21px;
}
.biolife-banner__detail-product .midle-info .pr-desc{
    font-size: 15px;
    line-height: 24px;
    color: #666666;
    display: block;
    margin: 0;
    max-width: 400px;
}
.biolife-banner__detail-product .midle-info .pr-atts{
    display: block;
    padding: 0;
    font-size: 0;
    line-height: 0;
    max-width: 372px;
    margin: 30px 0 0;
}
.biolife-banner__detail-product .midle-info .pr-atts li{
    display: inline-block;
    list-style: none;
    width: calc( 50% - 15px );
    margin-bottom: 24px;
}
.biolife-banner__detail-product .midle-info .pr-atts li:nth-child(odd){
    margin-right: 30px;
    clear: both;
}
.biolife-banner__detail-product .bottom-info{
    margin-top: 14px;
    font-size: 0;
}
.biolife-banner__detail-product .bottom-info .btn{
    min-width: 197px;
    padding: 14px 10px 13px;
    margin-right: 15px;
}
.biolife-banner__detail-product .bottom-info .btn-thin{
    min-width: 157px;
    border: 2px solid #e6e6e6;
    padding: 12px 10px 11px;
    margin-right: 0;
}
.biolife-banner__detail-product .bottom-info .btn-thin:hover{
    background-color: #444444;
    border-color: #444444;
}
.bn-atts-item{
    display: inline-block;
    width: 100%;
    font-size: 0;
    line-height: 0;
    max-width: 158px;
}
.bn-atts-item .title{
    display: block;
    font-size: 16px;
    line-height: 1;
    color: #333333;
    margin: 0 0 8px;
    font-weight: 400;
    border-bottom: 1px dashed rgba(102,102,102,0.5);
    text-transform: capitalize;
    padding-bottom: 7px;
}
.bn-atts-item .content{
    font-size: 14px;
    color: #666666;
    line-height: 1;
    display: block;
    margin: 0;
}
.biolife-twitter-wrap{
    margin-top: 2px;
}
.biolife-twitter-wrap .tweet-list{
    padding: 0;
    font-size: 0;
    line-height: 0;
    margin: 0;
}
.biolife-twitter-wrap .tweet-list li{
    list-style: none;
    display: block;
}
.biolife-twitter-wrap .slick-slide .row-item+.row-item,
.biolife-twitter-wrap .tweet-list li+li{
    border-top: 1px solid #e6e6e6;
}
.biolife-twitter-wrap .slick-slide .row-item+.row-item .biolife-tweet-item,
.biolife-twitter-wrap .tweet-list li+li .biolife-tweet-item{
    padding-top: 29px;
}
.biolife-tweet-item{
    display: block;
    padding-right: 15px;
    padding-bottom: 17px;
}
.biolife-tweet-item .tw-head{
    display: table;
    width: 100%;
}
.biolife-tweet-item .tw-head .acc-info,
.biolife-tweet-item .tw-head .icon{
    display: table-cell;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    border: none;
}
.biolife-tweet-item .tw-head .icon{
    width: 44px;
    padding-top: 1px;
}
.biolife-tweet-item .tw-head .icon i{
    font-size: 20px;
    color: #ffffff;
    line-height: 1;
    background-color: #aaaaaa;
    padding: 12px 10px;
    width: 44px;
    text-align: center;
    border-radius: 50%;
}
.biolife-tweet-item .tw-head .acc-info p{
    margin: 0;
    font-size: 18px;
    color: #222222;
    display: block;
    line-height: 20px;
}
.biolife-tweet-item .tw-head .acc-info p.tag{
    font-size: 16px;
    color: #666666;
}
.biolife-tweet-item .tw-head .acc-info{
    padding-left: 10px;
}
.biolife-tweet-item .tw-content .message{
    color: #666666;
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin: 10px 0 0;
}
.biolife-tweet-item .tw-content .message .link-bold{
    display: block;
    font-size: 16px;
}
.biolife-tweet-item .tw-content .message .link-bold:hover{
    color: #444444;
}
.biolife-tweet-item .tw-content .time{
    display: block;
    margin: 0;
    font-size: 15px;
    line-height: 24px;
    color: #444444;
}
.biolife-instagram-block .wrap-title{
    display: block;
    text-align: center;
    margin-bottom: 35px;
}
.biolife-instagram-block .wrap-title .subtitle{
    display: block;
    margin: 0;
    font-size: 15px;
    color: #666666;
    font-family: 'Playfair Display', sans-serif;
    line-height: 1;
}
.biolife-instagram-block .wrap-title .title{
    display: block;
    margin: 6px 0 0;
    font-size: 40px;
    line-height: 1;
    font-weight: 700;
    color: #222222;
}
.instagram-ltl-item{
    display: inline-block;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    position: relative;
}
.instagram-ltl-item .link-to{
    display: inline-block;
}
.instagram-ltl-item .link-to .show-on-hover{
    display: inline-block;
    font-size: 28px;
    line-height: 50px;
    color: #fafafa;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -25px;
    margin-left: -25px;
    -webkit-transform: scale(3);
    -moz-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
    z-index: 2;
    width: 50px;
    background-color: rgba(255,255,255,0.2);
    vertical-align: middle;
    text-align: center;
    border-radius: 50%;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.instagram-ltl-item:hover .link-to .show-on-hover{
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
}
.instagram-ltl-item .link-to::after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.instagram-ltl-item:hover .link-to::after{
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
}
.instagram-inline-wrap{
    font-size: 0;
    line-height: 0;
}
.biolife-popup .modal-content{
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    border: 0;
}
.biolife-popup .modal-body{
    padding: 0;
}
.mobile-search-content .form-search>.btn-close,
.biolife-popup .modal-body>.close{
    position: absolute;
    top: 10px;
    right: 10px;
    display: inline-block;
    line-height: 0;
    font-size: 17px;
    color: #666666;
    z-index: 2;
    opacity: 1;
    filter: alpha(opacity=100);
}
.mobile-search-content .form-search>.btn-close{
    top: -38px;
    right: -37px;
    font-size: 14px;
    padding: 6px;
}
.biolife-popup .modal-body>.close:hover{
    color: #D00023;
    opacity: 1;
    filter: alpha(opacity=100);
}
.biolife-quickview-block{
    display: inline-block;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-top: 30px;
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 450ms ease;
    -moz-transition: all 450ms ease;
    -ms-transition: all 450ms ease;
    -o-transition: all 450ms ease;
    transition: all 450ms ease;
    z-index: 1000;
    font-size: 0;
    line-height: 0;
}
body.open-quickview-block .biolife-quickview-block{
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    margin-top: 0;
}
.biolife-quickview-block .quickview-container{
    display: inline-block;
    font-size: 0;
    line-height: 0;
    width: 830px;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    position: relative;
}
.biolife-quickview-block .quickview-container .btn-close-quickview{
    position: absolute;
    top: 10px;
    right: 10px;
    display: inline-block;
    line-height: 0;
    font-size: 17px;
    color: #666666;
    z-index: 2;
    opacity: 1;
    filter: alpha(opacity=100);
}
.quickview-container .biolife-quickview-inner{
    display: table;
    width: 100%;
    padding: 10px 30px 20px 10px;
    table-layout: fixed;
}
.biolife-quickview-inner .media,
.biolife-quickview-inner .product-attribute{
    display: table-cell;
    vertical-align: top;
}
.biolife-quickview-inner .product-attribute{
    padding-left: 20px;
}
.biolife-quickview-inner .media{
    width: 45.6790123457%;
}
.biolife-quickview-inner .product-attribute .title{
    font-size: 20px;
    display: block;
    margin: 19px 0 6px;
    color: #222222;
    font-weight: 600;
}
.biolife-quickview-inner .product-attribute .title a{
    color: inherit;
    display: inline-block;
}
.biolife-quickview-inner .product-attribute .rating .star-rating > span::before,
.biolife-quickview-inner .product-attribute .rating .star-rating::before{
    font-size: 14px;
}
.biolife-quickview-inner .price-contain{
    margin-top: 10px;
}
.biolife-quickview-inner .price-contain .price-amount{
    font-size: 16px;
}
.biolife-quickview-inner .price-contain ins .price-amount{
    font-size: 24px;
}
.biolife-quickview-inner .product-attribute .excerpt{
    font-size: 15px;
    line-height: 24px;
    color: #666666;
    display: block;
    margin: 18px 0 0;
}
.biolife-quickview-inner .from-cart{
    margin-top: 33px;
    display: block;
    clear: both;
}
.biolife-quickview-inner .from-cart .buttons,
.biolife-quickview-inner .from-cart .qty-input{
    display: inline-block;
    font-size: 0;
    line-height: 0;
    vertical-align: top;
}
.biolife-quickview-inner .qty-input input{
    display: inline-block;
    width: 44px;
    text-align: center;
    border-top: 2px solid #e6e6e6;
    border-bottom: 2px solid #e6e6e6;
    border-left: 0;
    border-right: 0;
    background-color: #ffffff;
    color: #222222;
    font-size: 18px;
    font-weight: 600;
    padding: 5px;
    line-height: 1;
    height: 41px;
}
.biolife-quickview-inner .qty-input .qty-btn{
    display: inline-block;
    text-align: center;
    background-color: #ffffff;
    border: 2px solid #e6e6e6;
    position: relative;
    width: 27px;
    height: 41px;
    vertical-align: top;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
}
.biolife-quickview-inner .qty-input .btn-down{
    float: left;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
}
.biolife-quickview-inner .qty-input .qty-btn::after,
.biolife-quickview-inner .qty-input .qty-btn::before{
    content: '';
    display: block;
    background-color: #7f7f7f;
    position: absolute;
    top: 50%;
    left: 8px;
    width: 10px;
    height: 2px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
.biolife-quickview-inner .qty-input .btn-up::before{
    left: 6px;
}
.biolife-quickview-inner .qty-input .btn-up::after{
    height: 10px;
    width: 2px;
    left: 10px;
}
.biolife-quickview-inner .qty-input .qty-btn:hover::after,
.biolife-quickview-inner .qty-input .qty-btn:hover::before{
    background-color: #000000;
}
.biolife-quickview-inner .buttons .add-to-cart-btn{
    padding: 11px 15px 10px;
    min-width: 218px;
    margin-left: 20px;
}
.biolife-quickview-inner .product-meta .product-atts{
    display: block;
    padding: 20px 0 23px;
    margin: 0 0 17px;
    border-bottom: 1px solid #e6e6e6;
}
.biolife-quickview-inner .product-atts .product-atts-item{
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #222222;
}
.biolife-quickview-inner .product-atts-item .meta-title{
    display: inline-block;
    font-weight: 400;
}
.biolife-quickview-inner .product-atts-item .meta-list{
    display: inline-block;
    margin: 0;
    padding: 0;
}
.biolife-quickview-inner .product-atts-item .meta-list li{
    list-style: none;
    display: inline-block;
}
.biolife-quickview-inner .product-atts-item .meta-list li:not(:last-child) a::after{
    content: ',';
}
.biolife-quickview-inner .product-atts-item .meta-list li a{
    color: #666666;
}
.biolife-quickview-inner .product-meta .sku{
    font-size: 14px;
    color: #333333;
    display: block;
    margin: 0;
    line-height: 1;
}
.biolife-quickview-block .quickview-nav img{
    display: inline-block;
    border-width: 0;
}
.biolife-quickview-block .quickview-nav li.slick-current img{
    border: 2px solid #ededed;
}
.biolife-quickview-inner .media .quickview-nav{
    width: 230px;
    margin-left: 10px;
    margin-top: 10px;
    position: relative;
}
.biolife-quickview-inner .quickview-nav .slick-arrow{
    font-size: 0;
    line-height: 0;
    display: inline-block;
    padding: 0;
    margin: 0;
    position: absolute;
    left: -10px;
    top: 50%;
    margin-top: -8px;
}
.biolife-quickview-inner .quickview-nav .slick-arrow.next{
    left: auto;
    right: -11px;
}
.biolife-quickview-inner .quickview-nav .slick-arrow::before{
    content: "\f104";
    font-family: 'FontAwesome', sans-serif;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-size: 18px;
    line-height: 14px;
    color: #666666;
    width: 11px;
    text-align: left;
}
.biolife-quickview-inner .quickview-nav .slick-arrow.next::before{
    text-align: right;
    content: "\f105";
}
.contain-product.layout-default .slide-down-box .buttons .add-to-cart-btn i{
    display: none;
}
/*-------------------------------*/
.background-fafafa{
    background-color: #fafafa;
}
.z-index-20{
    z-index: 20;
    position: relative;
}
.biolife-wrap-map{
    width: 100%;
    display: block;
    clear: both;
}
.biolife-wrap-map iframe{
    width: 100%;
}
.banner-promotion-01{
    line-height: 0;
    font-size: 0;
    display: inline-block;
    width: 100%;
}
.top-banner-menu{
    text-align: center;
    margin: 8px 0 30px;
}
.mobile-menu-toggle{
    line-height: 0;
    display: none !important;
}
.mobile-menu-toggle span{
    display: inline-block;
    width: 30px;
    height: 2px;
    background-color: #333333;
    float: left;
    clear: left;
}
.header-area.layout-02 .mobile-menu-toggle span{
    background-color: #ffffff;
}
.mobile-menu-toggle span+span{
    margin-top: 5px;
}
.mobile-menu-toggle>a{
    display: inline-block;
}
.header-area.layout-01 .mobile-menu-toggle>a{
    padding: 15px 0;
}
.minicart-block.layout-02 .span-index .qty{
    display: none;
}
/*===============================
* 404 Page                      *
 ===============================*/
.page-404{
    background-image: url(../images/404-bg.jpg);
    background-position: center center;
    margin-top: 15px;
}
.content-404{
    max-width: 570px;
    padding: 100px 0 130px;
    display: block;
    text-align: center;
}
.content-404 .heading{
    font-size:90px;
    font-weight:700;
    margin-bottom:13px;
    color:#e73918
}
.content-404 .title{
    font-size:36px;
    font-weight:700;
    margin-bottom:20px;
    color: #222222;
}
.content-404 p{
    color:#666;
    margin-bottom:45px;
    font-size:16px
}
.content-404 .button{
    display: inline-block;
    font-size: 15px;
    color: #fff;
    font-weight: 700;
    padding: 10px 25px;
    border: none;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    border-radius: 30px;
}
.content-404 .button:before {
    font-family: "FontAwesome";
    content: "\f104";
    display: inline-block;
    width: 0;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
    opacity: 0;
    text-align: left;
}
.sumary-product .product-attribute .price+div:not(.product-atts):not(.no-border-top){
    border-top: 1px solid #e6e6e6;
    margin-top: 20px;
    padding-top: 20px;
}
.sumary-product .product-attribute .price ins + ins::before{
    content: '-';
    display: inline-block;
    font-size: 22px;
    font-weight: 700;
    color: #111111;
    padding-left: 5px;
    padding-right: 5px;
}
.grouped-product-list{
    margin-top: 30px;
    margin-bottom: 30px;
}
.grouped-product-list-item__label a:not(:hover){
    color: #333333;
}
.grouped-product-list-item__label a{
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    display: inline-block;
}
.quantity-box.type2 .qty-input input{
    width: 85px;
    font-size: 16px;
    background-color: #fafafa;
    border: 1px solid #f0f0f0;
    font-weight: 600;
}
.sumary-product .product-attribute .grouped-product-list-item__price .price{
    margin-top: 0;
}
.sumary-product .product-attribute .grouped-product-list-item__price .price ins .price-amount{
    font-size: 17px;
    font-weight: 700;
    color: #333333;
}
.sumary-product .product-attribute .grouped-product-list-item__price .price del{
    display: block;
    margin: 0;
}
.sumary-product .product-attribute .grouped-product-list-item__price .price del .price-amount{
    font-size: 16px;
}
.sumary-product .action-form .buttons.external-btn{
    border-top: 0;
    padding-top: 0;
}
.sumary-product .biolife-countdown{
    text-align: left;
    margin-bottom: 25px;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 25px;
    padding-top: 25px;
}
/*===============================
* Responsive Area               *
 ===============================*/
@media ( max-width: 1699px) {
    .biolife-banner__promotion6 .media .img-moving.position-2,
    .biolife-banner__promotion5 .media .position-2{
        display: none;
    }
    .biolife-banner__promotion6 .banner-contain .text-content{
        padding: 15px;
        margin-left: 0;
        background-color: rgba(0,0,0,0.2);
    }
}

@media ( max-width: 1199px) {
    .biolife-banner__special .text-content .first-line{
        font-size: 36px;
    }
    .biolife-banner__special .text-content .second-line{
        font-size: 32px;
        margin-bottom: 15px;
    }
    .biolife-banner__special .text-content .third-line i,
    .biolife-banner__special .text-content .third-line{
        font-size: 32px;
    }
    .biolife-banner__special .product-detail .price-contain ins .price-amount{
        font-size: 22px;
    }
    .header-area.layout-03 .live-info{
        display: none;
    }
    .biolife-gird-banners .grid-panel-item.left-content,
    .biolife-gird-banners .grid-panel-item.right-content{
        width: 25%;
    }
    .contain-product__right-info-layout3{
        padding: 0;
    }
    .contain-product__right-info-layout3 .info .buttons{
        padding: 0;
        padding-top: 20px;
    }
    .products-list.vertical-layout-02{
        max-width: 100%;
    }
    .biolife-banner__special-03 .banner-contain{
        background: none;
    }
    .biolife-banner__special-02 .banner-contain{
        display: table;
    }
    .biolife-banner__special-02 .banner-contain .thumb,
    .biolife-banner__special-02 .banner-contain .text-content{
        display: table-cell;
        vertical-align: middle;
    }
    .biolife-banner__special-02 .text-content{
        position: static;
        width: 355px;
        margin: 0;
    }
    .biolife-banner__special-02 .text-content .text01{
        font-size: 45px;
        margin-bottom: 5px;
        color: #888888;
        opacity: 1;
        filter: alpha(opacity=100);
    }
    .biolife-banner__special-02 .text-content .text02{
        font-size: 35px;
    }
    .biolife-banner__special-02 .text-content .text04,
    .biolife-banner__special-02 .text-content .text03{
        font-size: 28px;
    }
    .biolife-banner__special-02 .text-content .text04 i{
        font-size: 32px;
    }
    .biolife-banner__special-02 .product-detail{
        margin-top: 30px;
    }
    .header-area.layout-01 .header-top{
        margin-top: 18px;
    }
    .newsletter-block.layout-04 .wrap-title .texts{
        text-align: left;
    }
    .newsletter-block.layout-04{
        padding-left: 0;
        text-align: center;
        padding-bottom: 15px;
    }
    .newsletter-block.layout-04 .wrap-title{
        margin-right: 0;
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
    }
    .header-area.layout-02 .biolife-cart-info .minicart-contain a>.sub-total,
    .header-area.layout-02 .biolife-cart-info .minicart-contain a>.title{
        display: none;
    }
    .header-area .biolife-cart-info .login-item{
        vertical-align: top;
    }
    .header-area .biolife-cart-info .login-item .login-link{
        font-size: 0;
    }
    .products-category-box{
        padding: 0;
        height: auto;
    }
    .products-category-box .title{
        margin-bottom: 40px;
    }
    .biolife-stretch-the-right-background>.bg_underground{
        display: none;
    }
    .header-top .top-bar .social-list{
        display: none;
    }
    .biolife-title-box__bold-style.mobile-tiny .title{
        font-size: 22px;
    }
    .biolife-title-box.link-all .main-title{
        font-size: 26px;
    }
    .biolife-title-box.link-all .blog-link{
        display: none;
    }
    .biolife-banner__style-04 .text-content,
    .biolife-banner__style-03 .text-content,
    .biolife-banner__style-02 .text-content{
        width: calc(100% - 30px);
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        display: inline-block;
        margin-left: 15px;
        background-color: rgba(255,255,255,0.8);
        padding: 20px 10px;
        min-height: 162px;
        vertical-align: middle;
    }
    .biolife-banner__style-02 .text1,
    .biolife-banner__style-02 .text2,
    .biolife-banner__style-03 .text1,
    .biolife-banner__style-03 .text2,
    .biolife-banner__style-03 .text3{
        color: #000000;
    }
    .biolife-banner__promotion2 .text-content .btn-thin{
        border-color: #d6d6d6;
    }
    .biolife-banner__promotion2 .text-content{
        z-index: 15;
        position: relative;
        padding: 20px 15px;
        background-color: rgba(255,255,255,0.6);
        box-shadow: 0 0 7px 0 rgba(130,130,130,0.2);
        border-radius: 2px;
    }
    .biolife-banner__promotion3 .media,
    .biolife-banner__promotion .media{
        display: none;
    }
    .biolife-service__type01 .txt-show-01{
        font-size: 100px;
        line-height: 220px;
    }
    .biolife-service__type01 .txt-show-02{
        font-size: 50px;
        top: 30px;
    }
    .biolife-carousel.dots_ring_style .slick-dots{
        bottom: 0;
        right: 50%;
        -webkit-transform: translateX(50%);
        -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%);
    }
    .post-item.style-bottom-info .post-content{
        padding-left: 0;
        padding-right: 0;
    }
    .welcome-us-block{
        text-align: center;
        height: auto;
        padding-bottom: 150px;
    }
    .welcome-us-block .text-wraper{
        display: inline-block;
        max-width: 503px;
        margin: 90px 0 0;
        padding: 10px;
        background-color: rgba( 250, 250, 250, 0.8);
        float: none;
    }
    .welcome-us-block .text-info{
        margin-top: 0;
    }
    .welcome-us-block .qt-text,
    .welcome-us-block .text-info{
        width: 100%;
    }
    .login-on-checkout .form-row button{
        margin-top: 10px;
        margin-bottom: 25px;
    }
    .shpcart-subtotal-block{
        margin-top: 70px;
        border: 1px solid #e6e6e6;
    }
    .single-layout.product-tabs{
        margin-top: 80px;
    }
    .sumary-product .product-attribute{
        padding-right: 20px;
    }
    .mobile-block-global{
        display: block;
    }
    body.global-panel-opened .mobile-block-global{
        left: 0;
    }
    .top-functions-area .flt-item.group-on-mobile .wrap-selectors{
        position: fixed;
        top: 50%;
        left: 50%;
        width: 450px;
        margin-left: -225px;
        margin-top: -162px;
        background-color: #ffffff;
        z-index: 999;
        padding: 30px;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all 320ms ease;
        -moz-transition: all 320ms ease;
        -o-transition: all 320ms ease;
        transition: all 320ms ease;
        visibility: hidden;
        border-top: 3px solid red;
    }
    .top-functions-area .flt-item.group-on-mobile .selector-item{
        width: 100%;
        margin: 15px 0;
        border-bottom: 1px solid #e6e6e6;
    }
    .top-functions-area .flt-item.group-on-mobile .selector-item::before{
        content: attr(data-title);
        display: inline-block;
        font-size: 15px;
        float: left;
    }
    .top-functions-area .flt-item.group-on-mobile .nice-select{
        border-radius: 0;
        border: none;
        padding: 0 20px 0 10px;
        clear: none;
    }
    .top-functions-area .flt-item.group-on-mobile .nice-select:after{
        right: 10px;
    }
    body.top-refine-opened .top-functions-area .flt-item.group-on-mobile .wrap-selectors{
        visibility: visible;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    .top-functions-area .flt-item.group-on-mobile .flt-title{
        display: none;
    }
    .top-functions-area .flt-item .btn-for-mobile,
    .top-functions-area .flt-item .title-for-mobile,
    .top-functions-area .flt-item .icon-for-mobile{
        display: inline-block;
    }
}

@media ( max-width: 991px){
    .page-404{
        background: #f4f4f4;
        margin-top: 0;
    }
    .content-404{
        margin: 0 auto;
    }
    .biolife-cat-box-item{
        border: 1px solid #e6e6e6;
    }
    .biolife-cat-box-item .cat-info{
        border: none;
        margin: 20px 0 20px;
        background-color: transparent;
    }
    .biolife-cat-box-item .cat-name{
        padding: 0;
        margin-bottom: 10px;
        font-size: 18px;
        min-width: initial;
    }
    .biolife-cat-box-item .cat-number{
        position: static;
        display: block;
        text-align: center;
        margin: 0;
    }
    .biolife-banner__style-14 .text-content,
    .biolife-banner__style-13 .text-content{
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 15px;
        right: 15px;
        width: auto;
        background: rgba(255,255,255,0.8);
        padding: 30px 5px;
    }
    .biolife-banner__style-13 .text1,
    .biolife-banner__style-14 .text1{
        color: #000000;
    }
    .biolife-banner__style-14 .text2,
    .biolife-banner__style-14 .text3,
    .biolife-banner__style-13 .text2{
        color: #333333;
    }
    .biolife-cart-info .icon-qty-combine .biolife-icon,
    .mobile-search .open-searchbox .biolife-icon{
        font-size: 24px;
    }
    .mobile-menu-toggle{
        display: inline-block !important;
    }
    .minicart-block.layout-02 .icon-contain .biolife-icon{
        color: #333333 !important;
        font-size: 24px;
    }
    .minicart-block.layout-02 .icon-contain .span-index{
        padding: 2px;
        border: none;
        position: relative;
    }
    .minicart-block.layout-02 .span-index .qty{
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        display: inline-block;
        margin: 0;
        min-width: 19px;
        padding: 1px 5px 2px;
        color: #ffffff;
        border-radius: 50%;
        position: absolute;
        right: -8px;
        bottom: 7px;
    }
    .minicart-block.layout-02 .icon-contain .btn-to-cart,
    .minicart-block.layout-02 .icon-contain .sub-total{
        display: none;
    }
    .biolife-banner__special-02 .banner-contain{
        display: block;
    }
    .biolife-banner__special-02 .banner-contain .thumb,
    .biolife-banner__special-02 .banner-contain .text-content{
        display: block;
    }
    .biolife-banner__special-02 .text-content{
        width: 100%;
        border: 1px solid #eeeeee;
        padding: 15px 15px 40px;
    }
    .mega-content .biolife-brand ul.brands{
        margin-left: -10px;
        margin-right: -10px;
    }
    .mega-content .biolife-brand ul.brands li{
        width: 50%;
        padding: 10px;
    }
    .mega-content .biolife-brand.vertical ul.brands{
        width: 100%;
        padding: 0;
    }
    .mega-content .biolife-brand.vertical ul.brands li{
        margin: 0;
        clear: none;
        float: left;
    }
    .mega-content .biolife-brand.vertical ul.brands li:nth-child(2n+1){
        clear: left;
    }
    .biolife-banner__grid:hover .banner-contain .cat-name{
        color: #222222;
    }
    .biolife-banner__grid:not(.type-02) .banner-contain .cat-name::before{
        content: none;
    }
    .biolife-title-box_in-countdown{
        padding: 16px 18px 17px;
    }
    .biolife-quickview-block,
    .contain-product .product-thumb .lookup{
        display: none;
    }
    .header-area.layout-01 .header-bottom:not(.pre-sticky){
        padding-top: 30px;
        padding-bottom: 45px;
    }
    .header-area.layout-01{
        background-position: center bottom;
    }
    .header-area.layout-01 .header-bottom .logo-for-mobile{
        display: inline-block;
        float: left;
        margin-top: 5px;
    }
    .header-area.layout-01 .header-bottom .biolife-cart-info{
        display: inline-block;
        margin-top: 0;
        float: right;
    }
    .footer.layout-01 .payment-methods ul,
    .footer.layout-01 .copy-right-text{
        text-align: center;
    }
    .biolife-banner__promotion5 .banner-contain{
        overflow: hidden;
    }
    .biolife-banner__promotion5 .text-content{
        margin: 0;
        padding: 40px 15px;
        right: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .biolife-banner__promotion5 .media .img-moving{
        width: 900px;
        top: 0;
        right: 0;
        margin-right: 0;
    }
    .biolife-banner__detail-product .midle-info .pr-desc,
    .biolife-banner__detail-product .midle-info .pr-atts{
        max-width: 100%;
    }
    .biolife-banner__detail-product .midle-info{
        display: inline-block;
    }
    .biolife-banner__detail-product .text-content{
        text-align: center;
        padding: 30px 15px;
        border: 1px dashed #e6e6e6;
        background: #fcfcfc;
    }
    .biolife-gird-banners .grid-panel,
    .biolife-gird-banners .grid-panel .grid-panel-item,
    .biolife-gird-banners .grid-panel-item.left-content,
    .biolife-gird-banners .grid-panel-item.right-content{
        display: block;
        width: 100%;
    }
    .biolife-banner__grid:not(.type-02) .banner-contain .cat-name{
        min-width: 150px;
        padding: 20px 30px;
        background-color: rgba(255,255,255,0.8);
    }
    .biolife-banner__grid .banner-contain .cat-name::before{
        content: none;
    }
    .wrap-custom-menu.horizontal-menu-v2 ul.menu>li{
        margin-bottom: 15px;
    }
    .footer-midle-pst .announce-text{
        margin-top: 20px;
    }
    .header-area .biolife-cart-info .login-item{
        display: none;
    }
    .top-banner-menu{
        padding: 0;
        margin-top: 0;
    }
    .top-banner-menu img{
        width: 100%;
    }
    .grid-twice-item>li{
        width: 100%;
        margin-left: 0;
    }
    .grid-twice-item>li+li{
        margin-top: 50px;
    }
    .biolife-banner__promotion4.v2 .position-1{
        right: 0;
    }
    .wrap-megamenu{
        background: none;
    }
    .mobile-inline.biolife-brand ul li{
     width: 100%;
    }
    .mega-content .row{
        margin: 0;
    }
    .biolife-banner__special .media{
        width: 60%;
        padding: 0;
    }
    .biolife-banner__special .text-content{
        width: 40%;
        padding-top: 20px;
    }
    .biolife-banner__special .text-content .first-line {
        font-size: 28px;
    }
    .biolife-banner__special .text-content .second-line {
        margin-bottom: 14px;
    }
    .biolife-banner__special .text-content .third-line,
    .biolife-banner__special .text-content .second-line {
        color: #333333;
        font-size: 25px;
    }
    .biolife-banner__special .text-content .third-line i{
        font-size: 25px;
    }
    .biolife-banner__special .product-detail{
        margin-top: 35px;
    }
    .why-choose-us-block .showcase{
        padding-bottom: 85px;
        overflow: hidden;
    }
    .showcase .sc-left-position,
    .showcase .sc-right-position{
        max-width: 50%;
        position: relative;
        z-index: 2;
    }
    .showcase .sc-center-position{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        opacity: 0.2;
        filter: alpha(opacity=20);
    }
    .sumary-product .action-form .buttons{
        border-top: 0;
        padding-top: 0;
    }
    .sumary-product .action-form .buttons .pull-row{
        display: none;
    }
    .sumary-product .action-form .total-price-contain{
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .sumary-product .action-form .qty-input input,
    .sumary-product .action-form .qty-input{
        width: 100%;
    }
    .review-tab #comments .comment-content .comment-text{
        line-height: inherit;
    }
    .biolife-panigations-block.version-2{
        padding-top: 20px;
    }
    .product-tabs.single-layout .tab-head .tabs{
        border-bottom: none;
    }
    .product-tabs.single-layout .tab-content .tab-contain{
        border: 1px solid #e6e6e6;
        padding: 10px 10px 20px;
    }
    .product-tabs.single-layout .tab-content .tab-contain .container{
        width: 100%;
        padding: 0;
    }
    .product-tabs.single-layout .tab-head .tabs li.active a::before{
        width: 100%;
    }
    .product-tabs.single-layout .tab-head .tabs li a::before{
        height: 2px;
    }
    .product-tabs.single-layout .tab-head .tabs li a{
        width: 100%;
        display: inline-block;
        padding: 8px 0;
    }
    .product-tabs.single-layout .tab-head .tabs li:not(:last-child){
        margin-right: 0;
        border-bottom: none;
    }
    .product-tabs.single-layout .tab-head .tabs li{
        width: 100%;
        text-align: center;
        border: 1px solid #e6e6e6;
        padding: 3px 0;
    }
    .sumary-product .product-attribute{
        width: 50%;
        padding-right: 0;
    }
    .sumary-product .media{
        width: 50%;
    }
    .sumary-product .action-form{
        width: 100%;
        margin-top: 33px;
        padding-top: 30px;
    }
    .sidebar .wgt-content .products li{
        display: inline-block;
        width: 100%;
    }
    .contain-product .info .product-title,
    .contain-product.layout-default .info .price ins .price-amount{
        font-size: 16px;
    }
    .contain-product.layout-default .info .product-title{
        font-size: 15px;
        margin-top: 15px;
    }
    .contain-product.layout-default .info .product-title a{
        color: #444444;
    }
    .contain-product.layout-default{
        position: relative;
        padding-bottom: 30px;
    }
    .contain-product.layout-default .slide-down-box .buttons .wishlist-btn{
        position: absolute;
        top: 5px;
        left: 5px;
        display: block;
        padding: 4px 0;
        color: #999999;
        border-radius: 50%;
        background: rgba(255,255,255,0.8);
    }
    .contain-product.layout-default .slide-down-box .buttons .btn{
        display: block;
    }
    .contain-product.layout-default .slide-down-box .buttons .add-to-cart-btn{
        padding: 10px 5px;
        color: #444444;
        font-weight: 700;
        font-size: 15px;
        text-transform: capitalize;
        -webkit-box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
        -moz-box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
        background-color: #fafafa !important;
        width: calc(100% - 4px);
        margin-left: 2px;
    }
    .contain-product.layout-default .slide-down-box .buttons .add-to-cart-btn:hover{
        background-color: #fafafa;
    }
    .contain-product.layout-default .slide-down-box .buttons{
        padding: 0;
        display: block;
    }
    .contain-product.layout-default .slide-down-box .buttons .compare-btn,
    .contain-product .info .categories,
    .contain-product.layout-default .slide-down-box .message,
    .contain-product.layout-default .shipping-info{
        display: none;
    }
    #sidebar .biolife-mobile-panels{
        display: block;
    }
    #sidebar{
        position: fixed;
        top: 0;
        left: -320px;
        width: 300px;
        height: 100vh;
        background-color: #ffffff;
        -webkit-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        z-index: 100000;
        -webkit-box-shadow: 3px 5px 14px -3px rgba(0,0,0,0.11);
        -moz-box-shadow: 3px 5px 14px -3px rgba(0,0,0,0.11);
        box-shadow: 3px 5px 14px -3px rgba(0,0,0,0.11);
        overflow: hidden;
        padding: 0;
    }
    #sidebar .sidebar-contain{
        display: inline-block;
        padding: 30px 15px 0;
        height: calc( 100% - 50px );
        overflow-y: auto;
    }
    body.open-mobile-filter #sidebar,
    body.open-mobile-menu .biolife-clone-wrap{
        left:0;
    }
    .mobile-search .open-searchbox{
        display: block;
    }
    .mobile-search-content{
        display: block;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all 350ms ease;
        -moz-transition: all 350ms ease;
        -o-transition: all 350ms ease;
        transition: all 350ms ease;
    }
    .mobile-search .mobile-search-content{
        display: block;
    }
    body.open-mobile-search .mobile-search-content{
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    body.open-mobile-search .mobile-search-content .nice-select .list{
        max-height: 200px;
        overflow-y: scroll;
        margin: 0;
        border-radius: 0;
    }
    .biolife-cart-info .minicart-contain a>.sub-total,
    .biolife-cart-info .minicart-contain a>.title{
        display: none;
    }
    .mobile-footer{
        display: block;
    }
    body{
        margin-bottom: 44px;
    }
    .header-top .top-bar{
        max-width: 100%;
    }
    .header-area.layout-02 .header-top .right .horizontal-menu>li.item-link,
    .header-top .right .horizontal-menu>.currency,
    .header-top .right .horizontal-menu>.lang,
    .header-top .top-bar .social-list{
        display: none;
    }
    .header-area.layout-01 .mobile-search .open-searchbox .biolife-icon{
        color: #333333;
        font-size: 24px;
    }
    .header-area.layout-01 .mobile-search .open-searchbox{
        line-height: 1;
        text-align: center;
        padding: 10px 0 10px 10px;
        vertical-align: top;
        background: transparent !important;
    }
    .header-area.layout-01 .biolife-cart-info>div{
        vertical-align: top;
    }
}

@media ( max-width: 767px){
    .grid-twice-item>li+li{
        margin-top: 80px;
    }
    .biolife-banner__special{
        padding-top: 80px;
    }
    .main-content>.posts-list li{
        margin-bottom: 0;
    }
    .main-content>.posts-list li+li{
        margin-top: 10px;
    }
    .hero-section{
        margin-top: 0;
    }
    .main-content .main-post-list{
        margin-top: 30px;
    }
    .main-content .main-post-list li:not(:last-child){
        margin-bottom: 70px;
    }
    .post-item.style-bottom-info .post-content .post-name a{
        font-size: 20px;
        line-height: 24px;
    }
    .blog-page .posts-list:not(.biolife-carousel) .post-item.style-bottom-info .thumbnail img{
        width: 100%;
    }
    .biolife-banner__special .banner-contain{
        border: 1px solid #eeeeee;
        text-align: center;
        padding: 15px 5px 30px
    }
    .biolife-banner__special .text-content,
    .biolife-banner__special .media{
        width: 100%;
    }
    .biolife-banner__special .media img{
        display: inline-block;
    }
    .biolife-banner__special .product-detail{
        margin-top: 0;
        text-align: center;
    }
    .biolife-banner__special .product-detail .buttons{
        margin-top: 20px;
    }
    .biolife-banner__special .product-detail .add-to-cart-btn{
        font-weight: 700;
        min-width: auto;
        padding: 12px 25px;
        font-size: 14px;
        text-transform: capitalize;
    }
    .biolife-banner__special .text-content .first-line,
    .biolife-banner__special .text-content .second-line,
    .biolife-banner__special .text-content .third-line{
        display: none;
    }
    .biolife-banner__special .product-detail .price-contain .price-amount,
    .biolife-banner__special .product-detail .price-contain ins .price-amount{
        font-size: 16px;
    }
    .slider-opt05 .media{
        bottom: 50px;
        top: auto;
    }
    .biolife-banner__style-01 .text-content .fourth-line,
    .biolife-banner__style-01 .text-content .first-line{
        color: #333333;
        font-weight: 600;
    }
    .biolife-banner__style-15 .text-content,
    .biolife-banner__style-01 .text-content{
        right: 10px;
        left: 10px;
        padding: 20px 10px;
        background-color: rgba(255,255,255,0.8);
        text-align: center;
        top: 10px;
        bottom: 10px;
        width: auto;
    }
    .header-area.layout-02 .biolife-logo,
    .header-area.layout-03 .biolife-logo,
    .header-area.layout-04 .biolife-logo,
    .header-area.layout-05 .biolife-logo{
        display: block;
        margin-top: 5px;
    }
    .header-area .header-middle{
        padding: 15px 0 !important;
    }
    .slider-opt04__layout01 .text-content .third-line{
        line-height: 24px;
    }
    .wrap-custom-menu.horizontal-menu-v2 ul.menu>li+li{
        border-left: none;
    }
    .style-bottom-info.layout-02 .post-meta__item-social-box .inner-content{
        left: auto;
        right: 0;
        margin-left: 0;
        margin-right: -10px;
    }
    .mobile-search-content .form-search>.btn-close{
        top: -17px;
        right: -12px;
    }
    .main-slide .biolife-carousel.nav-none-on-mobile .slick-arrow.prev,
    .main-slide .biolife-carousel.nav-none-on-mobile .slick-arrow.next{
        display: none !important;
    }
    .sumary-product .action-form .total-price-contain{
        margin-top: 35px;
        margin-bottom: 35px;
    }
    .sumary-product .action-form .location-shipping-to,
    .sumary-product .action-form .social-media{
        margin-top: 35px;
    }
    .sumary-product .action-form .title{
        text-transform: uppercase;
        font-weight: 700;
    }
    .order-summary .title-block .title{
        font-size: 23px;
        font-weight: 700;
    }
    .checkout-progress-wrap .checkout-act .title-box{
        font-size: 19px;
    }
    .contact-info-container .addr-info{
        margin-top: 35px;
    }
    .contact-info-container .frst-desc{
        line-height: 25px;
        font-size: 16px;
    }
    .register-in-container .box-title,
    .contact-info-container .box-title{
        font-size: 25px;
    }
    .post-item.style-wide .post-content .post-name{
        font-size: 22px;
        line-height: 24px;
    }
    .post-item .group-buttons .btn.readmore{
        padding: 6px 10px;
        min-width: 134px;
    }
    .search-widget input[type=text]{
        border-width: 1px;
    }
    .single-post-contain .post-content blockquote p{
        font-size: 15px;
    }
    .single-post-contain .post-head .post-archive{
        font-size: 16px;
        margin-top: 13px;
    }
    .post-comments .wrap-post-comment .cmt-inner{
        margin-top: 20px;
    }
    .post-comments.lever-0>li:first-child>.wrap-post-comment>.cmt-inner{
        margin-top: 5px;
    }
    .post-comments .comment-form .form-row textarea{
        padding: 55px 10px 10px 15px;
        height: 220px;
    }
    .post-comments .comment-form .form-row .current-author{
        top: 10px;
        left: 10px;
    }
    .single-post-contain .post-foot .auth-info .avata{
        margin-right: 5px;
    }
    .single-post-contain .post-content blockquote{
        padding: 10px;
    }
    .single-post-contain .post-head .post-name{
        font-weight: 700;
        font-size: 23px;
        line-height: 24px;
    }
    .contain-product__deal-layout .info .price{
        margin-top: 12px;
    }
    .contain-product__deal-layout .slide-down-box .buttons .btn:not(.add-to-cart-btn){
        display: none;
    }
    .contain-product .info .product-title{
        margin-top: 13px;
    }
    .biolife-countdown .number{
        font-size: 17px;
    }
    .contain-product__deal-layout{
        padding: 10px 10px 30px;
        text-align: center;
    }
    .contain-product__deal-layout img{
        display: inline-block;
    }
    .biolife-banner__style-01 .text-content .third-line, 
    .biolife-banner__style-01 .text-content .second-line{
        font-size: 30px;
        line-height: 40px;
    }
    .tab-head__icon-top-layout:not(.background-tab-include) a::after{
        content: none;
    }
    .instagram-ltl-item,
    .instagram-ltl-item .link-to img,
    .instagram-ltl-item .link-to{
        width: 100%;
    }
    .contain-product__right-info-layout3{
        display: block;
    }
    .contain-product__right-info-layout3 .buttons .add-to-cart-btn{
        width: 100%;
        border-radius: 0;
        padding: 13px;
        min-width: auto;
    }
    .contain-product__right-info-layout3 .buttons .btn:not(.add-to-cart-btn){
        display: none;
    }
    .products-list.vertical-layout-02 li{
        padding: 0 0 20px;
    }
    .biolife-banner__grid.type-02 .banner-contain .cat-name{
        max-width: 250px;
    }
    .biolife-banner__grid .media-contain.media-06,
    .biolife-banner__grid .media-contain.media-01,
    .biolife-banner__grid .media-contain{
        height: 300px;
    }
    .biolife-banner__grid .banner-contain .cat-name{
        font-size: 22px;
    }
    .sumary-product .action-form .total-price-contain .price{
        font-size: 21px;
    }
    .sumary-product .product-attribute .title{
        font-weight: 700;
    }
    .hero-section .page-title{
        font-size: 28px;
    }
    .biolife-banner__style-12 .text2{
        padding: 0;
        font-size: 22px;
    }
    .biolife-carousel.row-space-29px .row-item+.row-item{
        margin-top: 20px;
    }
    .slider-opt05 .text-content .second-line{
        font-size: 32px;
        line-height: 43px;
    }
    .advance-product-box_countdown{
        border: none;
    }
    .advance-product-box_countdown .contain-product .info .product-title{
        margin-top: 20px;
    }
    .biolife-title-box_in-countdown{
        padding: 0;
    }
    .biolife-title-box_in-countdown .title{
        font-size: 24px;
        margin-bottom: 30px;
    }
    .advance-product-box_countdown .biolife-carousel{
        border: 1px solid #e6e6e6;
        padding-top: 10px;
    }
    .biolife-title-box.style-02 .desc,
    .biolife-title-box.style-02 .subtitle,
    .biolife-title-box.slim-item .subtitle{
        display: none;
    }
    .contain-product.scale-effect{
        padding-bottom: 20px;
    }
    .contain-product.layout-default .slide-down-box .buttons .wishlist-btn{
        display: none;
    }
    .contain-product.layout-default .slide-down-box .buttons{
        text-align: center;
    }
    .tab-head__sample-layout.type-02 .tab-element .tab-link:hover,
    .tab-head__sample-layout.type-02 .active .tab-link{
        background: #fafafa;
    }
    .biolife-banner__special-03 .banner-contain{
        padding-bottom: 0;
    }
    .biolife-title-box__bgrd-bold{
        margin-top: 70px;
        margin-bottom: 40px;
        text-align: center;
    }
    .biolife-title-box__bgrd-bold .title{
        font-size: 24px;
        width: auto;
        display: inline-block;
        text-align: center;
        padding: 10px 30px 17px;
        background-size: 100%;
        min-width: 210px;
    }
    .contain-product__right-info-layout3 .product-thumb img{
        display: block;
        width: 100%;
    }
    .contain-product__right-info-layout3 .product-thumb{
        width: 100% !important;
    }
    .contain-product__right-info-layout3 .info .price ins .price-amount{
        font-size: 18px;
    }
    .contain-product__right-info-layout3 .info .rating .star-rating{
        margin-top: 0;
    }
    .contain-product__right-info-layout3 .info .rating{
        display: none;
    }
    .contain-product__right-info-layout3 .info .price{
        display: block;
        text-align: left;
        float: none;
        max-width: 100%;
    }
    .contain-product__right-info-layout3 .info .product-title{
        display: block;
        max-width: initial;
        text-align: left;
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 15px;
    }
    .contain-product__right-info-layout3 .info{
        padding: 0;
    }
    .contain-product__right-info-layout3 .info .desc{
        display: none;
    }
    .contain-product__right-info-layout3 .info,
    .contain-product__right-info-layout3 .product-thumb{
        display: block;
    }
    .biolife-banner__promotion6 .media .img-moving.position-1{
        position: static;
    }
    .biolife-banner__promotion6 .banner-contain .media{
        height: auto;
        padding-top: 10px;
    }
    .biolife-banner__promotion6 .text-content .text2{
        font-size: 28px;
        line-height: 38px;
    }
    .biolife-banner__promotion6 .banner-contain .text-content{
        top: 50%;
        left: 50%;
        right: auto;
        max-width: 290px;
        margin-left: -145px;
        padding: 25px 10px 35px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .biolife-banner__special-02 .text-content .text04 i,
    .biolife-banner__special-02 .text-content .text04,
    .biolife-banner__special-02 .text-content .text03{
        font-size: 22px;
    }
    .biolife-banner__special-02 .product-detail .price-contain .price-amount,
    .biolife-banner__special-02 .text-content .text02{
        font-size: 24px;
    }
    .biolife-banner__special-02 .text-content .text01{
        font-size: 32px;
        margin-bottom: 5px;
        color: #888;
        opacity: 1;
        filter: alpha(opacity=100);
    }
    .biolife-banner__special-02 .text-content .text03{
        margin: 17px 0 10px;
        color: #444444;
    }
    .biolife-banner__special-02 .text-content .text04{
        color: #444444;
    }
    .biolife-newsletter-popup-contain .modal-dialog{
        width: 290px;
        margin: 100px auto;
    }
    .newsletter-block_popup-layout{
        padding: 40px 15px 40px;
    }
    .newsletter-block_popup-layout .wrap-title .newslt-title{
        font-size: 32px;
        margin-top: 12px;
    }
    .newsletter-block_popup-layout .form-content .input-text{
        padding: 8px;
    }
    .newsletter-block_popup-layout .form-content .bnt-submit{
        padding: 11px;
        margin-top: 25px;
    }
    .newsletter-block_popup-layout .form-content .dismiss-newsletter{
        margin-top: 35px;
    }
    .header-area.layout-01{
        position: static;
    }
    .header-area.layout-01 .header-bottom:not(.pre-sticky){
        padding: 15px 0;
    }
    .minicart-block.layout-02 .icon-contain .btn-to-cart{
        padding: 12px 10px;
    }
    .minicart-block.layout-02 .icon-contain .biolife-icon{
        line-height: 35px;
    }
    .newsletter-block.layout-04 .wrap-title .biolife-icon{
        display: none;
    }
    .newsletter-block.layout-04 .wrap-title .texts{
        padding: 0;
        text-align: center;
    }
    .newsletter-block.layout-04 .wrap-title .sub-title{
        margin: 15px 0 0;
    }
    .newsletter-block.layout-04 .wrap-title{
        margin-bottom: 30px;
        margin-top: 15px;
    }
    .biolife-brand-block .link-brand-item{
        display: block;
        text-align: center;
    }
    .biolife-brand-block .link-brand-item img{
        display: inline-block;
    }
    .newsletter-block.layout-03{
        padding-top: 90px;
        background-position: center left;
    }
    .newsletter-block.layout-03 .form-content .newslt-title{
        font-size: 22px;
    }
    .newsletter-block.layout-03 .form-content .sub-title{
        margin: 20px 0 0;
    }
    .biolife-title-box__under-line{
        margin-bottom: 30px;
        border-bottom: 0;
        padding-bottom: 10px;
    }
    .biolife-banner__detail-product .head-info .text2{
        margin-top: 5px;
    }
    .biolife-banner__detail-product .head-info h3 i{
        font-size: 26px;
    }
    .biolife-banner__detail-product .head-info h3{
        font-size: 22px;
    }
    .hidden-icon-on-mobile.biolife-title-box .biolife-icon{
        display: none;
    }
    .footer.layout-02 .footer-content{
        padding-top: 50px;
    }
    .products-list__vertical-layout li:last-child{
        padding-bottom: 0;
    }
    .products-category-box .title{
        font-size: 24px;
        margin-bottom: 35px;
    }
    .products-category-box{
        padding: 0;
    }
    .biolife-stretch-the-right-background>.bg_underground{
        display: none;
    }
    .payment-methods.layout-02 .payments li{
        margin-bottom: 10px;
    }
    .payment-methods.layout-02 .payments li:not(:last-child){
        margin-right: 10px;
    }
    .biolife-social.bigger-on-mobile ul li+li{
        margin-left: 40px;
    }
    .biolife-social.bigger-on-mobile ul li a{
        font-size: 22px;
    }
    .newsletter-block.layout-02{
        padding-top: 10px;
    }
    .biolife-banner__promotion4.v2 .container{
        position: initial;
    }
    .biolife-banner__promotion4.v2 .position-2{
        margin: 0;
        max-width: 107px;
    }
    .biolife-banner__promotion4.v2 .position-1{
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .biolife-title-box.link-all .main-title{
        font-size: 22px;
    }
    .post-item.style-bottom-info.layout-02 .thumbnail a>img{
        width: 100%;
    }
    .biolife-banner__promotion4 .position-1{
        left: 0;
    }
    .biolife-banner__promotion4 .text-content .first-line{
        font-size: 35px;
        line-height: 35px;
    }
    .biolife-banner__promotion4 .position-2 img{
        -webkit-animation: unset;
        animation: unset;
    }
    .biolife-banner__promotion4 .banner-contain{
        position: initial;
    }
    .biolife-banner__promotion4{
        position: relative;
        overflow: hidden;
    }
    .biolife-banner__promotion4 .text-content{
        padding: 23px 0;
        top: 50%;
        left: 0;
        right: 0;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        max-width: none;
    }
    .biolife-banner__promotion4.v2 .text-content{
        background: none;
    }
    .biolife-banner__promotion4.v2::before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 7;
    }
    .biolife-title-box.style-02 .main-title{
        font-size: 24px;
    }
    .slider-opt03__layout02 .text-content{
        margin: 0 0 0 -200px;
        left: 50%;
        padding: 15px 15px 25px;
    }
    .contain-product__right-info-layout .info .rating .review-count{
        display: none;
    }
    .contain-product__right-info-layout .product-thumb{
        width: 40%;
    }
    .contain-product__deal-layout .biolife-countdown>span{
        min-width: 50px;
        padding: 6px;
    }
    .contain-product__deal-layout .biolife-countdown .text{
        font-size: 11px;
        font-weight: 700;
    }
    .biolife-title-box__bold-style.mobile-tiny .title,
    .biolife-title-box__bold-style .title{
        font-size: 24px;
    }
    .biolife-title-box__bold-style{
        margin-top: 80px;
        padding-right: 0;
    }
    .biolife-banner__promotion2 .text-content{
        position: absolute;
        top: 50%;
        left: 15px;
        margin-top: -150px;
    }
    .biolife-banner__promotion2 .media{
        position: static;
        width: 100%;
        margin-top: -91px;
    }
    .biolife-tab .tab-content{
        clear: both;
    }
    .biolife-tab .tab-head:not(.tab-head__default) .tabs{
        display: inline-block;
        margin: 26px 0 30px !important;
        width: 100%;
    }
    .tab-head__icon-top-layout a::after{
        content: none;
    }
    .tab-head__icon-top-layout a{
        font-size: 16px;
        vertical-align: middle;
        background-color: #f6f6f6;
        display: inline-block;
        width: 100%;
        text-align: left;
        padding: 7px 5px;
    }
    .tab-head__icon-top-layout .biolife-icon.icon-blueberry{
        margin-left: 7px;
    }
    .tab-head__icon-top-layout .biolife-icon{
        display: inline-block;
        font-size: 28px;
        margin: 0 10px 0 0;
        vertical-align: middle;
    }
    .biolife-tab .tab-head:not(.tab-head__default):not(.tab-head__sample-layout) .tab-element.active .tab-link{
        background-color: #888;
        color: #f6f6f6;
    }
    .biolife-tab .tab-head:not(.tab-head__default):not(.tab-head__sample-layout) .tabs .tab-element{
        width: 50%;
        text-align: left;
        float: left;
        padding: 0 1px;
        margin-bottom: 1px;
    }
    .biolife-tab .tab-head:not(.tab-head__default):not(.tab-head__sample-layout) .tabs .tab-element + .tab-element{
        margin-left: 0;
    }
    .biolife-tab .tab-head__sample-layout .tabs .tab-element{
        width: 100%;
        border-left: 3px solid #fafafa;
        background: #fafafa;
        margin-bottom: 1px;
    }
    .tab-head__sample-layout .tabs .tab-element.active{
        border-left-color: #444444;
    }
    .tab-head__sample-layout .tab-element .tab-link{
        color: #aaaaaa;
        padding: 11px;
        background: #fafafa;
        font-size: 15px;
    }
    .tab-head__sample-layout .tab-element .tab-link:hover,
    .tab-head__sample-layout .active .tab-link{
        color: #444444;
        background: #efefef;
    }
    .services-list{
        border: none;
    }
    .services-list li{
        width: 100%;
        border: 1px solid #e6e6e6;
        margin-bottom: 30px;
        padding-top: 30px;
        padding-bottom: 40px;
    }
    .biolife-service__type02 .services-list li{
        border: none;
        padding: 0;
        margin: 0;
    }
    .slider-opt03__layout01 .text-content{
        right: 50%;
        margin: 0 -270px 0 0;
        min-width: 540px;
    }
    .slider-opt03__layout01.mode-02 .text-content{
        margin-top: 10px;
    }
    .slider-opt03__layout01.mode-03 .media,
    .slider-opt03__layout01.mode-02 .media,
    .slider-opt03__layout01 .media{
        height: 480px;
    }
    .slider-opt03__layout01 .media .child-elememt{
        top: 0;
        right: 0;
        left: 0;
        text-align: center;
        margin: 0;
    }
    .slider-opt03__layout01 .media .child-elememt>*{
        height: 440px;
        display: inline-block;
        margin-top: 40px;
    }
    .slider-opt03__layout01 .media .child-elememt img{
        width: initial;
        height: 100%;
        max-width: initial;
    }
    .slick-slide.slick-active .slider-opt03__layout01 .media .child-elememt,
    .slick-slide .slider-opt03__layout01 .media .child-elememt{
        left: 50%;
        right: auto;
        -webkit-transform: scale(1) translateX(-50%);
        -moz-transform: scale(1) translateX(-50%);
        -ms-transform: scale(1) translateX(-50%);
        -o-transform: scale(1) translateX(-50%);
        transform: scale(1) translateX(-50%);
    }
    .post-item.style-left-info .thumbnail img,
    .post-item.style-left-info .thumbnail{
        width: 100%;
    }
    .post-item.style-left-info .thumbnail,
    .post-item.style-left-info .post-content,
    .post-item.style-left-info{
        display: block;
        text-align: center;
        padding-left: 0;
    }
    .post-item.style-left-info .post-content>*{
        max-width: 100%;
    }
    .post-item .post-content .post-archive{
        font-size: 16px;
    }
    .post-item.style-left-info .post-content{
        padding-top: 15px;
    }
    .post-item.style-left-info .post-content .post-name{
        font-size: 22px;
        line-height: 24px;
    }
    .contact-info-block.footer-layout ul li{
        margin-bottom: 15px;
    }
    .post-comments .wrap-post-comment .cmt-fooot .btn{
        color: #888888;
    }
    .post-comments .wrap-post-comment .cmt-content p{
        color: #444444;
    }
    .post-comments .comment-list ol.lever-1{
        padding-left: 25px;
    }
    .post-comments .comment-list ol{
        background: #fcfcfc;
        padding: 10px;
    }
    .post-comments .form-row.last-btns .btn-sumit{
        width: 100%;
        margin-bottom: 20px;
        margin-top: 15px;
    }
    .post-comments .cmt-title{
        font-size: 25px;
    }
    .single-post-contain .post-head{
        margin-bottom: 30px;
    }
    .single-post-contain .post-foot .post-tags .tags li{
        margin-bottom: 10px;
    }
    .single-post-contain .post-foot .post-tags .tag-title{
        display: none;
    }
    .testimonial-block .testml-elem .desc{
        text-align: justify;
    }
    .testimonial-block .testimonial-list{
        padding: 0 0 55px;
    }
    .testimonial-block .box-title{
        margin: 50px 0 40px;
    }
    .why-choose-us-block .showcase{
        padding-bottom: 50px;
    }
    .welcome-us-block{
        height: auto;
        background: none;
        padding-bottom: 20px;
    }
    .why-choose-us-block .subtitle{
        margin: 29px 0 0;
    }
    .welcome-us-block .text-wraper{
        margin: 43px 0 0;
        padding: 0 0 40px;
        background: none;
        width: 100%;
        max-width: 100%;
        text-align: center;
    }
    .welcome-us-block .qt-text,
    .welcome-us-block .text-info{
        float: none;
    }
    .testimonial-block .box-title::after,
    .testimonial-block .box-title::before{
        display: none;
    }
    .why-choose-us-block .box-title,
    .testimonial-block .box-title,
    .welcome-us-block .title{
        font-size: 28px;
    }
    .showcase .sc-center-position{
        display: none;
    }
    .showcase .sc-right-position{
        padding-top: 42px;
        direction: ltr;
    }
    .sc-left-position,
    .sc-right-position{
        padding-top: 30px;
    }
    .showcase .sc-left-position,
    .showcase .sc-right-position{
        max-width: 100%;
        text-align: center;
        width: 100%;
    }
    .why-choose-us-block .sc-element .txt-content{
        width: 100%;
    }
    .why-choose-us-block .sc-element .txt-content .number{
        font-size: 37px;
    }
    .why-choose-us-block .sc-element .txt-content .desc{
        font-size: 15px;
        line-height: 24px;
    }
    .register-in-container{
        margin-top: 50px;
    }
    .signin-container .form-row.wrap-btn{
        text-align: center;
    }
    .signin-container .form-row .link-to-help{
        float: none;
        margin: 24px 0 0;
    }
    .signin-container .form-row .btn-submit{
        width: 100%;
        margin-top: 20px;
    }
    .signin-container{
        margin-top: 20px;
    }
    .top-banner ul{
        width: 100%;
    }
    .top-banner ul li{
        padding: 0 15px;
    }
    .top-banner{
        padding: 57px 10px 35px;
    }
    .shopping-cart-container th{
        display: none;
    }
    .shopping-cart-container table.cart-form tbody,
    .shopping-cart-container table.cart-form tr,
    .shopping-cart-container table.cart-form td,
    .shopping-cart-container table.cart-form{
        display: block;
        padding: 0;
    }
    .shopping-cart-container table.cart-form tr{
        margin-bottom: 30px;
        border: 1px solid #e6e6e6;
    }
    .shopping-cart-container table.cart-form td{
        padding: 15px 5px;
    }
    .shopping-cart-container table.cart-form td + td{
        border-top: 1px solid #e6e6e6;
    }
    .shopping-cart-container table.cart-form td.product-thumbnail{
        text-align: center;
        padding-top: 26px;
    }
    .shopping-cart-container table.cart-form td.product-thumbnail .prd-name{
        padding: 0;
        width: 100%;
        margin-top: 16px;
    }
    .shopping-cart-container table.cart-form td.product-thumbnail .action{
        padding: 5px;
        top: 0;
        right: 0;
        left: auto;
        bottom: auto;
    }
    .shopping-cart-container table.cart-form td.product-thumbnail .action a{
        padding: 3px 5px;
        margin-left: 10px;
    }
    .shopping-cart-container table.cart-form tr.wrap-buttons{
        border: none;
    }
    .shopping-cart-container table.cart-form td.wrap-btn-control{
        padding: 0;
    }
    .shopping-cart-container table.cart-form td.wrap-btn-control button.btn{
        width: calc( 50% - 5px);
        min-width: auto;
        float: left;
    }
    .shopping-cart-container table.cart-form td.wrap-btn-control .btn-clear{
        margin-left: 10px;
    }
    .shopping-cart-container table.cart-form td.wrap-btn-control a.back-to-shop{
        width: 100%;
        margin-bottom: 20px;
    }
    .single-layout.product-tabs{
        margin-top: 45px;
    }
    .review-tab #comments .comment-content .comment-in .post-date{
        display: none;
    }
    .review-tab #comments .comment-review-form{
        margin-top: 25px;
    }
    .review-tab #comments .comment-review-form .actions{
        display: table;
        width: 100%;
        margin-top: 0;
    }
    .review-tab #comments .comment-review-form .actions li{
        display: table-cell;
        width: 33.33333%;
    }
    .product-tabs.single-layout .tab-content .row{
        margin: 0;
    }
    .product-tabs.single-layout .tab-content .col-xs-12{
        padding: 0;
    }
    .product-category .products-list{
        padding-left: 7px;
        padding-right: 7px;
    }
    .product-category .products-list .product-item{
        padding: 0 7px;
    }
    .footer-phone-info .r-info{
        font-size: 16px;
        padding-left: 15px;
    }
    .newsletter-block .title{
        font-size: 17px;
    }
    .payment-methods.layout-02 .title{
        font-size: 19px;
        font-weight: 700;
        margin: 0 0 30px;
    }
    .footer-item .section-title{
        font-size: 19px;
    }
    .biolife-cart-info .minicart-block .cart-content{
        width: auto;
        right: -52px;
    }
    .biolife-cart-info .minicart-block .cart-inner{
        width: 290px;
        padding-left: 10px;
    }
    .biolife-cart-info .minicart-item .thumb{
        width: 80px;
    }
    .biolife-cart-info .minicart-item .left-info{
        width: calc(100% - 80px);
        padding-right: 12px;
    }
    .biolife-cart-info .minicart-block ul.products li:first-child .minicart-item .action{
        margin-top: -32px;
    }
    .minicart-item .action{
        top: 50%;
    }
    .minicart-item .action a{
        float: left;
        clear: left;
        width: 12px;
        font-size: 14px;
        text-align: center;
    }
    .minicart-item .action a+a{
        margin-left: 0;
        margin-top: 16px;
    }
    .biolife-cart-info .minicart-block .btn-control .btn{
        width: calc( 50% - 5px );
        padding: 12px;
        font-size: 12px;
        border-radius: 0;
    }
    .biolife-cart-info .minicart-block .btn-control .view-cart{
        margin-right: 10px;
    }
    .minicart-item .left-info .product-title{
        font-size: 15px;
        font-weight: 600;
    }
    .payment-methods ul,
    .copy-right-text{
        text-align: center;
    }
    .payment-methods ul li+li{
        margin-left: 5px;
    }
    .biolife-cart-info>div+div{
        margin-left: 22px;
    }
    .mobile-search .mobile-search-content{
        padding: 20px 15px;
        margin-left: -145px;
        width: 290px;
        margin-top: -101px;
        top: 50%;
    }
    .mobile-search .mobile-search-content .nice-select{
        width: 100%;
        border-top: none;
        border-left: 0;
        border-right: 0;
    }
    .mobile-search .mobile-search-content .input-text{
        width: 100%;
        margin-bottom: 15px;
        border-top: none;
        border-left: 0;
        border-right: 0;
    }
    .mobile-search .mobile-search-content .btn-submit{
        margin-top: 15px;
        width: 100%;
        position: static;
    }
}

@media ( max-width: 600px){
    .content-404{
        margin: 0 auto;
        padding: 50px 15px 80px;
    }
    .content-404 .heading{
        font-size: 60px;
    }
    .content-404 .title{
        font-size: 28px;
    }
    .content-404 p{
        margin-bottom: 35px;
        font-size: 15px;
    }
    .slider-opt05 .text-content .first-line{
        color: #333333;
    }
    .slider-opt05 .text-content .third-line{
        color: #000000;
        font-weight: 600;
    }
    .slider-opt03__layout01 .text-content{
        right: 10px;
        left: 10px;
        margin: 0;
        text-align: center;
        padding: 20px 10px;
        min-width: auto;
    }
    .slider-opt03__layout01.mode-02 .text-content{
        margin-top: 45px;
    }
    .slider-opt03__layout01 .text-content .first-line{
        text-align: center;
        font-size: 28px;
    }
    .slider-opt03__layout01 .text-content .second-line{
        text-align: center;
        display: inline-block;
        font-size: 40px;
        line-height: 40px;
        max-width: 280px;
        margin-top: 10px;
    }
    .slider-opt03__layout01 .text-content .third-line{
        font-size: 18px;
        line-height: 24px;
    }
    .slider-opt03__layout01 .buttons .btn{
        padding: 14px 10px;
        min-width: 120px;
    }
    .slider-opt03__layout01 .buttons .btn+.btn{
        padding: 12px 10px;
        min-width: 120px;
        margin-left: 15px;
    }
    .single-post-contain .post-head .post-archive .author{
        display: block;
        margin-left: 0;
    }
    .biolife-banner__style-15 .text-content .second-line{
        font-size: 28px;
    }
    .biolife-banner__style-15 .text-content .third-line{
        font-size: 28px;
        margin-top: 8px;
    }
    .biolife-banner__style-15 .text-content .fourth-line,
    .biolife-banner__style-15 .text-content .first-line{
        color: #444444;
        font-weight: 600;
        font-size: 16px;
    }
    .biolife-banner__promotion5 .banner-contain .media{
        height: 400px;
    }
    .biolife-banner__promotion5 .text-content .text1{
        font-size: 25px;
    }
    .biolife-banner__promotion5 .text-content .text2{
        font-size: 30px;
        line-height: 36px;
        margin: 10px 0 0;
    }
    .biolife-banner__style-11 .text4 span{
        font-size: 32px;
    }
    .biolife-banner__style-11 .text4{
        font-size: 23px;
    }
    .biolife-banner__style-11 .text3,
    .biolife-banner__style-11 .text1{
        font-size: 17px;
    }
    .biolife-banner__style-11 .text2{
        font-size: 26px;
    }
    .biolife-banner__style-11 .banner-contain .text-content::before,
    .biolife-banner__style-12 .banner-contain .text-content::before{
        top: -18px;
        margin: 0;
        left: 50%;
        margin-left: -16px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    .biolife-banner__style-12 .banner-contain,
    .biolife-banner__style-11 .banner-contain{
        display: inline-block;
    }
    .biolife-banner__style-12 .banner-contain .media,
    .biolife-banner__style-12 .banner-contain .text-content,
    .biolife-banner__style-11 .banner-contain .media,
    .biolife-banner__style-11 .banner-contain .text-content{
        display: block;
        width: 100%;
    }
    .biolife-banner__style-12 .banner-contain .text-content,
    .biolife-banner__style-11 .banner-contain .text-content{
        padding: 20px 15px 30px;
        margin-top: 10px;
    }
    .biolife-banner__style-12 .banner-contain .media,
    .biolife-banner__style-11 .banner-contain .media{
        padding-right: 0;
    }
    .biolife-banner__style-12 .banner-contain .media img,
    .biolife-banner__style-11 .banner-contain .media img{
        display: inline-block;
        width: 100%;
    }
    .slider-opt03__layout02 .media{
        height: 400px;
    }
    .main-slide.nav-change2 .slick-arrow,
    .main-slide.nav-change .slick-arrow{
        display: none !important;
    }
    .slider-opt03__layout02 .text-content{
        width: 290px;
        margin-left: -145px;
        top: 51px;
    }
    .slider-opt03__layout02.type_02 .text-content{
        margin-left: 0;
        top: 70px;
    }
    .slider-opt03__layout02 .buttons .btn{
        padding: 9px 15px;
        min-width: 100px;
    }
    .slider-opt03__layout02 .buttons .btn-thin{
        padding: 7px 15px;
    }
    .slider-opt03__layout02 .text-content .third-line{
        margin: 22px 0 0;
        line-height: 24px;
        color: #000000;
    }
    .slider-opt03__layout02 .text-content .second-line{
        line-height: 1;
        margin-top: 15px;
        font-size: 32px;
    }
    .biolife-banner__promotion3 .text-content .second-line{
        font-size: 28px;
        max-width: 100%;
    }
    .biolife-banner__promotion3 .product-detail .txt-price{
        font-size: 23px;
    }
    .biolife-banner__promotion3 .product-detail .add-to-cart-btn{
        padding: 9px 10px;
        min-width: 135px;
    }
    .biolife-banner__promotion3 .text-content{
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;
        text-align: center;
    }
    .biolife-banner__promotion3 .text-wrap{
        background-color: rgba(255,255,255,0.8);
        box-shadow: 0 0 7px 0 rgba(130,130,130,0.2);
        border-radius: 2px;
        margin: 0;
        padding: 15px;
        display: inline-block;
        text-align: left;
    }
    .style-bottom-info.layout-02 .post-content .post-name{
        font-size: 20px;
        line-height: 24px;
    }
    .biolife-banner__promotion2{
        overflow: hidden;
        margin-top: 75px;
    }
    .biolife-banner__promotion2 .banner-contain{
        text-align: center;
    }
    .biolife-banner__promotion2 .media{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
        padding: 20px 15px 30px;
        background-position: center top;
        margin-top: 0;
        border-top: 1px solid #eeeeee;
    }
    .biolife-banner__promotion2 .text-content{
        margin: 70px 0;
        position: relative;
        left: 0;
        top: 0;
        right: 0;
        float: initial;
        text-align: left;
    }
    .biolife-banner__promotion2 .text-content .btn-thin{
        padding: 8px 15px !important;
        margin-left: 5px;
    }
    .biolife-banner__promotion2 .text-content .btn{
        padding: 10px 22px;
        font-size: 13px;
        min-width: auto;
    }
    .biolife-banner__promotion2 .text-content .second-line,
    .biolife-banner__promotion2 .text-content .first-line{
        font-size: 22px;
        line-height: 27px;
    }
    .biolife-banner__promotion2 .text-content .second-line i{
        font-size: 22px;
    }
    .biolife-banner__promotion2.advance .text-content .second-line i{
        font-family: 'Playfair Display', sans-serif;
        font-weight: 700;
        font-style: italic;
        text-transform: unset;
    }
    .biolife-banner__promotion3{
        border-bottom: none;
    }
    .biolife-banner__promotion .product-detail .add-to-cart-btn{
        padding: 10px 30px;
        min-width: auto;
        margin-top: 35px;
    }
    .biolife-banner__promotion{
        height: auto;
        background-size: auto;
        background-position: center bottom;
    }
    .biolife-banner__promotion .text-content{
        position: static;
        padding: 50px 15px;
    }
    .biolife-banner__promotion .text-content .first-line{
        font-size: 25px;
    }
    .biolife-banner__promotion .text-content .second-line{
        font-size: 25px;
        line-height: 1;
    }
    .biolife-banner__promotion .product-detail .txt-price{
        font-size: 23px;
        margin: 0;
        display: block;
    }
    .biolife-banner__promotion .text-content .text-wrap{
        padding-left: 15px;
    }
    .biolife-service__type01 .txt-show-01{
        font-size: 50px;
        line-height: 100px;
        opacity: 1;
        filter: alpha(opacity=100);
    }
    .biolife-service__type01 .txt-show-02{
        font-size: 30px;
    }
    .biolife-carousel.dots_ring_style .slick-dots{
        display: none !important;
    }
    .single-post-contain .post-foot .auth-info{
        padding: 25px 0 0;
        border-bottom: 0;
    }
    .single-post-contain .post-foot .socials-connection{
        width: 100%;
        margin-top: 25px;
    }
    .single-post-contain .post-foot .socials-connection .social-list{
        width: 100%;
        margin: 0;
    }
    .single-post-contain .post-foot .socials-connection .title{
        display: none;
    }
    .order-summary .cart-list .cart-item .info,
    .order-summary .cart-list .cart-item .price,
    .order-summary .cart-list .cart-item .product-thumb{
        display: inline-block;
    }
    .order-summary .cart-list .cart-item .info,
    .order-summary .cart-list .cart-item .price{
        width: calc( 100% - 113px );
        display: inline-block;
        float: right;
        padding-top: 5px;
        padding-left: 10px;
        text-align: left;
    }
    .order-summary .cart-list .cart-item .product-thumb{
        float: left;
    }
    .order-summary .cart-list .cart-item .info *{
        font-size: 15px;
        font-weight: 600;
    }
    .checkout-progress-wrap .checkout-act .box-content{
        padding-left: 15px;
        padding-top: 15px;
    }
    .top-banner ul li .cost{
        margin-left: 15px;
    }
    .top-banner .btns .btn{
        width: 100%;
    }
    .top-banner .btns .btn + .btn{
        margin-left: 0;
        margin-top: 15px;
    }
    .biolife-instagram-block .wrap-title .title,
    .biolife-title-box .main-title{
        font-size: 24px;
    }
    .product-tabs .review-form-wrapper{
        padding-top: 40px;
    }
    .accodition-tab>ul>li>.content{
        padding: 10px 0 15px;
    }
    .product-tabs .tab-content p,
    .accodition-tab>ul>li>.title{
        font-size: 14px;
    }
    .review-form-wrapper .form-row button[type=submit],
    .review-form-wrapper .wide-half{
        width: 100%;
    }
    .review-form-wrapper .wide-half:nth-last-child(odd){
        margin-left: 0;
    }
    .biolife-panigations-block.version-2{
        text-align: center;
    }
    .biolife-panigations-block.version-2 ul li a.next{
        margin-left: 0;
    }
    .biolife-panigations-block.version-2 .result-count{
        width: 100%;
        margin-top: 15px;
    }
    .sumary-product .product-attribute .rating{
        margin-top: 0;
        padding-top: 8px;
    }
    .rating .star-rating{
        margin-top: 10px;
    }
    .sumary-product .product-attribute .review-count{
        margin-right: 17px;
        margin-top: 10px;
    }
    .sumary-product .product-attribute .qa-text,
    .sumary-product .product-attribute .category{
        margin-left: 0;
        margin-right: 17px;
        margin-top: 10px;
    }
    .sumary-product .product-attribute .product-atts .atts-item,
    .sumary-product .product-attribute .title,
    .sumary-product .product-attribute .excerpt{
        max-width: 100%;
    }
    .sumary-product .product-attribute{
        width: 100%;
        padding-left: 0;
        margin-top: 30px;
    }
    .sumary-product .product-attribute .product-atts,
    .sumary-product .media{
        width: 100%;
    }
    .sumary-product .action-form{
        width: 100%;
        margin-top: 33px;
    }
    .contain-product__right-info-layout2 .info .star-rating{
        margin-top: 0;
    }
    .product-category .products-list .product-item:not(:last-child) .pr-detail-layout{
        border-bottom: 1px solid #666;
        margin-bottom: 40px;
    }
    .pr-detail-layout .info .buttons .btn:not(.add-to-cart-btn){
        display: none;
    }
    .pr-detail-layout .product-thumb{
        width: 100%;
    }
    .pr-detail-layout .info{
        width: 100%;
        padding: 0;
    }
    .pr-detail-layout .info .categories{
        margin-top: 24px;
        width: 100%;
        font-size: 16px;
        color: #7faf51;
        font-weight: 700;
    }
    .pr-detail-layout .info .product-title{
        font-size: 20px;
        line-height: 24px;
        width: 100%;
        margin: 13px 0 0;
    }
    .pr-detail-layout .info .excerpt{
        margin: 20px 0 0;
        width: 100%;
    }
    .pr-detail-layout .info .buttons{
        margin-bottom: 23px;
    }
    .pr-detail-layout .info .buttons .add-to-cart-btn{
        min-width: 100%;
        padding: 13px;
    }
    .pr-detail-layout .advance-info{
        width: 100%;
        margin: 0;
        border: 1px dashed #aaa;
        padding: 13px;
        background: #f9f9f9;
    }
    .top-functions-area .flt-item.group-on-mobile .wrap-selectors{
        width: 290px;
        margin-left: -145px;
        padding: 15px;
    }
    .top-functions-area{
        display: inline-block;
        margin-top: 35px;
        background-color: #fafafa;
        border: 1px solid #efefef;
        padding: 9px 8px 1px;
        padding-bottom: 15px;
        padding-top: 24px;
    }
    .top-functions-area .viewmode-selector .viewmode{
        margin-left: 5px;
    }
    .top-functions-area .flt-item .flt-title{
        display: none;
    }
    .top-functions-area .flt-item.to-right .nice-select.orderby{
        border-radius: 0;
        border: 1px solid #e6e6e6;
        padding-left: 10px;
    }
    .top-functions-area .flt-item.to-right .nice-select.orderby .current{
        padding: 5px 0 7px;
        width: 118px;
    }
}

@media ( max-width: 479px){
    .biolife-banner__style-08 .text1,
    .biolife-banner__style-09 .text1,
    .biolife-banner__style-09 .text3{
        color: #000000;
    }
    .payment-methods.layout-02 .payments{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .payment-methods.layout-02 .payments li:not(:last-child){
        margin-right: 0;
    }
    .post-item .post-content .post-archive .author{
        margin-left: 0;
        display: block;
    }
    .add-border-on-mobile{
        border: 1px solid #e6e6e6;
    }
    .biolife-tab .tab-head.tab-head__default .tabs .tab-element.active{
        background-color: #f0f0f0;
    }
    .biolife-tab .tab-head.tab-head__default .tabs .tab-element{
        width: 100%;
        margin: 0;
        background-color: #fafafa;
    }
    .biolife-tab .tab-head.tab-head__default .tabs .tab-element + .tab-element{
        margin: 0;
        margin-top: 1px;
    }
    .tab-head__default .tab-element .tab-link{
        padding: 12px;
        width: 100%;
        margin: 0;
    }
    .tab-head__default .tab-element .tab-link::before{
        display: none;
    }
    .biolife-banner__detail-product .bottom-info .btn{
        min-width: 100%;
        padding: 12px 10px;
        margin-right: 0;
        margin-bottom: 20px;
    }
    .biolife-banner__detail-product .bottom-info .btn-thin{
        min-width: 100%;
        padding: 10px 10px;
        margin-right: 0;
        margin-bottom: 0;
    }
    .bn-atts-item{
        max-width: 100%;
        text-align: left;
    }
    .biolife-banner__detail-product .midle-info .pr-atts li:nth-child(odd){
        margin-right: 0;
    }
    .biolife-banner__detail-product .midle-info .pr-atts li{
        width: 100%;
    }
    .biolife-banner__promotion4{
        height: 400px;
    }
    .slider-opt04__layout01 .buttons,
    .slider-opt03__layout02 .buttons{
        margin-top: 20px;
    }
    .slider-opt04__layout01 .buttons .btn,
    .slider-opt03__layout02 .buttons .btn{
        padding: 10px 20px;
        min-width: auto;
    }
    .slider-opt04__layout01 .buttons .btn-thin,
    .slider-opt03__layout02 .buttons .btn-thin{
        padding: 8px 20px;
        margin-left: 5px;
    }
    .slider-opt04__layout01 .buttons .btn-thin:not(:hover),
    .slider-opt03__layout02 .buttons .btn-thin:not(:hover){
        background-color: #ffffff !important;
        color: #444444 !important;
        border-color: #ffffff !important;
    }
    .slider-opt04__layout01 .text-content .first-line,
    .slider-opt03__layout02 .text-content .first-line{
        font-size: 22px;
    }
    .slider-opt04__layout01.light-version .text-content .third-line,
    .slider-opt04__layout01.light-version .text-content .first-line{
        color: #000000;
        font-weight: 600;
    }
    .slider-opt04__layout01 .text-content .second-line,
    .slider-opt03__layout02 .text-content .second-line{
        font-size: 30px;
        line-height: 1;
        margin-top: 14px;
    }
    .slider-opt03__layout02 .text-content{
        left: 15px;
        top: 100px;
        padding-right: 5px;
    }
    .slider-opt04__layout01 .text-content{
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        right: auto;
        margin: 0;
        padding: 15px 10px;
        background-color: transparent;
        width: calc(100% - 10px);
        -webkit-transition: background-color 1.2s ease;
        -moz-transition: background-color 1.2s ease;
        -ms-transition: background-color 1.2s ease;
        -o-transition: background-color 1.2s ease;
        transition: background-color 1.2s ease;
    }
    .slick-active .slider-opt04__layout01.light-version .text-content{
        background-color: rgba(255,255,255,0.8);
    }
    .slider-opt04__layout01 .buttons .btn{
        font-size: 13px;
    }
    .slider-opt04__layout01 .buttons .btn-thin{
        -webkit-box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
        -moz-box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
        margin-left: 10px;
    }
    .slider-opt04__layout01 .media{
        height: 400px;
    }
    .biolife-banner__style-02 .text-content,
    .biolife-banner__style-03 .text-content,
    .biolife-banner__style-04 .text-content{
        min-height: auto;
    }
    .biolife-banner__style-14 .text2, 
    .biolife-banner__style-09 .text2, 
    .biolife-banner__style-03 .text2,
    .biolife-banner__style-13 .text2, 
    .biolife-banner__style-08 .text2, 
    .biolife-banner__style-02 .text2{
        font-size: 25px;
    }
    .main-slide .slick-arrow{
        display: none !important;
    }
}

@media ( max-width: 399px){
    .biolife-gird-banners .grid-panel .list-media .media-contain{
        height: 200px;
    }
    .biolife-gird-banners .grid-panel .list-media .cat-name{
        font-size: 18px;
        padding: 10px 10px 18px;
    }
    .biolife-gird-banners .grid-panel .list-media .biolife-banner__grid:not(.type-02) .banner-contain .cat-name{
        padding: 20px 15px;
        min-width: auto;
        width: 100%;
    }
    .biolife-gird-banners .grid-panel .list-media .biolife-banner__grid:not(.type-02) .banner-contain{
        bottom: 0;
    }
}

@media ( max-width: 380px){
    .biolife-banner__style-05 .banner-contain,
    .biolife-banner__style-06 .banner-contain,
    .biolife-banner__style-07 .banner-contain{
        overflow: hidden;
    }
    .biolife-banner__style-05 .media,
    .biolife-banner__style-06 .media,
    .biolife-banner__style-07 .media{
        left: -50px;
    }
}

/*==============================*/
.xs-margin-top-0{
    margin-top: 0;
}
.xs-margin-top-10px{
    margin-top: 10px;
}
.xs-margin-top-19px{
    margin-top: 19px;
}
.xs-margin-top-20px{
    margin-top: 20px;
}
.xs-margin-top-25px{
    margin-top: 25px;
}
.xs-margin-top-30px{
    margin-top: 30px;
}
.xs-margin-top-30px-im{
    margin-top: 30px !important;
}
.xs-margin-top-33px{
    margin-top: 33px;
}
.xs-margin-top-34px{
    margin-top: 34px;
}
.xs-margin-top-36px{
    margin-top: 36px;
}
.xs-margin-top-40px{
    margin-top: 40px;
}
.xs-margin-top-45px{
    margin-top: 45px;
}
.xs-margin-top-50px-im{
    margin-top: 50px !important;
}
.xs-margin-top-50px{
    margin-top: 50px;
}
.xs-margin-top-60px{
    margin-top: 60px;
}
.xs-margin-top-65px{
    margin-top: 65px;
}
.xs-margin-top-80px{
    margin-top: 80px;
}
.xs-margin-top-80px-im{
    margin-top: 80px !important;
}
.xs-margin-top-90px{
    margin-top: 90px;
}
.xs-margin-top-100{
    margin-top: 100px;
}
/*--------------------------*/
.xs-margin-bottom-15px{
    margin-bottom: 15px;
}
.xs-margin-bottom-16px{
    margin-bottom: 16px;
}
.xs-margin-bottom-25{
    margin-bottom: 25px;
}
.xs-margin-bottom-30px{
    margin-bottom: 30px;
}
.xs-margin-bottom-36px{
    margin-bottom: 36px;
}
.xs-margin-bottom-33px{
    margin-bottom: 33px;
}
.xs-margin-bottom-40-im{
    margin-bottom: 40px !important;
}
.xs-margin-bottom-46px{
    margin-bottom: 46px;
}
.xs-margin-bottom-60px{
    margin-bottom: 60px;
}
.xs-margin-bottom-66px{
    margin-bottom: 66px;
}
.xs-margin-bottom-50px{
    margin-bottom: 50px;
}
.xs-margin-bottom-50px-im{
    margin-bottom: 50px !important;
}
.xs-margin-bottom-60px-im{
    margin-bottom: 60px !important;
}
.xs-margin-bottom-80px{
    margin-bottom: 80px;
}
/*--------------------------*/
.xs-padding-top-10px{
    padding-top: 10px;
}
.xs-padding-top-30px{
    padding-top: 30px;
}
.xs-padding-bottom-50px{
    padding-bottom: 50px;
}
/*--------------------------*/
.width-100percent{ width: 100%;}
.width-90percent{ width: 90%;}
.width-80percent{ width: 80%;}
.width-40percent{ width: 40%;}
.width-30percent{ width: 30%;}
.width-20percent{ width: 20%;}
.width-10percent{ width: 10%;}
.min-height-346px{ min-height: 346px;}

@media ( min-width: 768px){
    .sm-margin-top_-1px{
        margin-top: -1px;
    }
    .sm-margin-top-0{
        margin-top: 0;
    }
    .sm-margin-top-0-im{
        margin-top: 0 !important;
    }
    .sm-margin-top-11px{
        margin-top: 11px;
    }
    .sm-margin-top-19px{
        margin-top: 19px;
    }
    .sm-margin-top-23px{
        margin-top: 23px;
    }
    .sm-margin-top-25px{
        margin-top: 25px;
    }
    .sm-margin-top-27px{
        margin-top: 27px;
    }
    .sm-margin-top-30px{
        margin-top: 30px;
    }
    .sm-margin-top-30px-im{
        margin-top: 30px !important;
    }
    .sm-margin-top-32px{
        margin-top: 32px;
    }
    .sm-margin-top-33px{
        margin-top: 33px;
    }
    .sm-margin-top-34px{
        margin-top: 34px;
    }
    .sm-margin-top-37px{
        margin-top: 37px;
    }
    .sm-margin-top-39px{
        margin-top: 39px;
    }
    .sm-margin-top-40px{
        margin-top: 40px;
    }
    .sm-margin-top-44px{
        margin-top: 44px;
    }
    .sm-margin-top-45px{
        margin-top: 45px;
    }
    .sm-margin-top-49px{
        margin-top: 49px;
    }
    .sm-margin-top-50px{
        margin-top: 50px;
    }
    .sm-margin-top-53px{
        margin-top: 53px;
    }
    .sm-margin-top-54px{
        margin-top: 54px;
    }
    .sm-margin-top-55px{
        margin-top: 55px;
    }
    .sm-margin-top-59px{
        margin-top: 59px;
    }
    .sm-margin-top-60px{
        margin-top: 60px;
    }
    .sm-margin-top-61px{
        margin-top: 61px;
    }
    .sm-margin-top-62px{
        margin: 62px 0 0;
    }
    .sm-margin-top-63px{
        margin: 63px 0 0;
    }
    .sm-margin-top-64px{
        margin-top: 64px;
    }
    .sm-margin-top-70px{
        margin-top: 70px;
    }
    .sm-margin-top-71px{
        margin-top: 71px;
    }
    .sm-margin-top-74px{
        margin-top: 74px;
    }
    .sm-margin-top-76px{
        margin-top: 76px;
    }
    .sm-margin-top-80px{
        margin-top: 80px;
    }
    .sm-margin-top-84px{
        margin-top: 84px;
    }
    .sm-margin-top-93px{
        margin-top: 93px;
    }
    .sm-margin-top-96px{
        margin-top: 96px;
    }
    .sm-margin-top-100px{
        margin-top: 100px;
    }
    .sm-margin-top-112px{
        margin-top: 112px;
    }
    .sm-margin-top-193px{
        margin-top: 93px;
    }
    .sm-margin-top_58px{
        margin-top: -58px;
    }
    /*--------------------------*/
    .sm-margin-bottom-0{
        margin-bottom: 0;
    }
    .sm-margin-bottom-0-im{
        margin-bottom: 0 !important;
    }
    .sm-margin-bottom-24px{
        margin-bottom: 24px;
    }
    .sm-margin-bottom-26px{
        margin-bottom: 26px;
    }
    .sm-margin-bottom-35-im{
        margin-bottom: 35px !important;
    }
    .sm-margin-bottom-36px{
        margin-bottom: 36px;
    }
    .sm-margin-bottom-57px{
        margin-bottom: 57px;
    }
    .sm-margin-bottom-57-im{
        margin-bottom: 57px !important;
    }
    .sm-margin-bottom-70px{
        margin-bottom: 70px;
    }
    .sm-margin-bottom-73px{
        margin-bottom: 73px;
    }
    .sm-margin-bottom-76px{
        margin-bottom: 76px;
    }
    .sm-margin-bottom-77px{
        margin-bottom: 77px;
    }
    .sm-margin-bottom-80px{
        margin-bottom: 80px;
    }
    .sm-margin-bottom-89px{
        margin-bottom: 89px;
    }
    .sm-margin-bottom-120px{
        margin-bottom: 120px;
    }
    /*--------------------------*/
    .sm-padding-top-14px{
        padding-top: 14px;
    }
    .sm-padding-top-48px{
        padding-top: 48px;
    }
    .sm-padding-top-54px{
        padding-bottom: 54px;
    }
    .sm-padding-top-64px{
        padding-top: 66px;
    }
    .sm-padding-top-75px{
        padding-top: 75px;
    }
    .sm-padding-top-72px{
        padding-bottom: 72px;
    }
    /*--------------------------*/
    .sm-padding-bottom-80px{
        padding-bottom: 80px;
    }
    .sm-padding-bottom-39px{
        padding-bottom: 39px;
    }

    /*********************************************/
    .tab-head__sample-layout.bg-tab-v5 .tab-element .tab-link:hover,
    .tab-head__sample-layout.bg-tab-v5 .active .tab-link{
        background: url("../images/bg_tab-v5.png");
        color: #222222;
    }
    .header-area.layout-05 .header-middle{
        margin-bottom: 32px;
    }
    .slider-opt03__layout01.mode-03 .text-content{
        top: 51%;
        right: 19%;
        margin: 0 14px 0 0;
    }
    .tab-head__icon-top-layout.background-tab-include a{
        line-height: 0;
    }
    .tab-head__icon-top-layout.background-tab-include a>span:not(.biolife-icon){
        display: block;
        font-size: 24px;
        text-transform: capitalize;
        color: #888888;
        line-height: 1;
        font-weight: 600;
    }
    .tab-head__icon-top-layout.background-tab-include .active a>span:not(.biolife-icon){
        color: #dd6b0c;
    }
    .tab-head__icon-top-layout.background-tab-include .biolife-icon{
        background-image: url(../images/home-01/tab-bg-01.png);
        display: inline-block;
        width: 106px;
        text-align: center;
        padding: 30px;
        color: #ffffff;
        opacity: 0.5;
        filter: alpha(opacity=50);
        margin-bottom: 3px;
    }
    .tab-head__icon-top-layout.background-tab-include .elm-02 .biolife-icon{
        background-image: url(../images/home-01/tab-bg-02.png);
    }
    .tab-head__icon-top-layout.background-tab-include .elm-03 .biolife-icon{
        background-image: url(../images/home-01/tab-bg-03.png);
    }
    .tab-head__icon-top-layout.background-tab-include .elm-04 .biolife-icon{
        background-image: url(../images/home-01/tab-bg-04.png);
    }
    .tab-head__icon-top-layout.background-tab-include .active .biolife-icon{
        opacity: 1;
        filter: alpha(opacity=100);
    }
    .header-area.layout-01 .header-middle{
        padding: 20px 0 28px;
    }
    .header-area.layout-01 .header-middle .biolife-logo{
        margin-top: 7px;
        display: block;
    }
    .header-area.layout-01 .header-middle .header-search-bar{
        padding-top: 1px;
    }

    .slider-opt03__layout01.mode-02 .text-content{
        top: 39%;
        right: 19%;
        margin: 0 12px 0 0;
    }
    .tab-head__icon-top-layout.type-02 a{
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
        padding-bottom: 6px;
    }
    .biolife-banner__promotion4.v2 .text-content{
        margin-left: 7px;
        top: 76px;
    }
    .footer.layout-02 .footer-item .section-title{
        font-size: 22px;
    }
}

@media ( min-width: 992px){
    .md-full-width{
        width: 100%;
    }
    .md-max-width-270px{
        max-width: 270px;
    }
    .md-width-970{
        width: 970px;
    }
    .md-width-750{
        width: 750px;
    }
    .md-width-640{
        width: 640px;
    }
    .md-margin-top_10px{
        margin-top: -10px !important;
    }
    .md-margin-top-0{
        margin-top: 0;
    }
    .md-margin-top-6px{
        margin-top: 6px;
    }
    .md-margin-top-5px{
        margin-top: 5px;
    }
    .md-margin-top-9{
        margin-top: 9px;
    }
    .md-margin-top-50px{
        margin-top: 50px;
    }
    .md-margin-top-61px{
        margin-top: 61px;
    }
    .md-margin-top-74px{
        margin-top: 74px;
    }
    /*-------------------------------*/
    .md-margin-bottom-0{
        margin-bottom: 0;
    }
    .md-margin-bottom-30{
        margin-bottom: 30px;
    }
    .md-margin-bottom-35-im{
        margin-bottom: 35px !important;
    }
    .md-margin-bottom-39{
        margin-bottom: 39px;
    }
    .md-margin-bottom-46{
        margin-bottom: 46px;
    }
    .md-margin-bottom-50{
        margin-bottom: 50px;
    }
    .md-margin-bottom-83{
        margin-bottom: 83px;
    }
    .md-padding-top-55{
        padding-top: 55px;
    }
    .md-padding-left-23{
        padding-left: 23px;
    }
    .md-padding-left-25{
        padding-left: 25px;
    }
    .md-padding-left-50{
        padding-left: 50px;
    }
    .md-padding-left-57{
        padding-left: 57px;
    }
    /***************************************************/
    .sumary-product.single-layout{
        min-height: 430px;
    }
    .biolife-brd-container:not(.transparent-effect) figure{
        display: inline-block;
        font-size: 0;
        line-height: 0;
        overflow: hidden;
    }
    .biolife-brd-container:not(.transparent-effect) figure>img{
        -webkit-transition: transform 0.3s ease;
        -moz-transition: transform 0.3s ease;
        -ms-transition: transform 0.3s ease;
        -o-transition: transform 0.3s ease;
        transition: transform 0.3s ease;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    .biolife-brd-container:not(.transparent-effect):hover figure>img{
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1);
    }
    .post-item.effect-04 .thumbnail,
    .post-item.effect-03 .thumbnail,
    .post-item.effect-02 .thumbnail,
    .post-item.effect-01 .thumbnail{
        position: relative;
        overflow: hidden;
    }
    .post-item.effect-04 .thumbnail>a,
    .post-item.effect-02 .thumbnail>a,
    .post-item.effect-01 .thumbnail>a{
        display: block;
    }
    .post-item.effect-04 .thumbnail>a::before,
    .post-item.effect-01 .thumbnail>a::before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        border: 1px solid #e6e6e6;
        z-index: 5;
        visibility: hidden;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .post-item.effect-04 .thumbnail>a::after,
    .post-item.effect-01 .thumbnail>a::after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.3);
        opacity: 0;
        filter: alpha(opacity=0);
        visibility: hidden;
        z-index: 2;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .post-item.effect-04 .thumbnail:hover .post-date,
    .post-item.effect-01 .thumbnail:hover .post-date{
        opacity: 0;
        filter: alpha(opacity=0);
        visibility: hidden;
    }
    .post-item.effect-04 .thumbnail:hover>a::before,
    .post-item.effect-01 .thumbnail:hover>a::before{
        top: 20px;
        left: 20px;
        right: 20px;
        bottom: 20px;
        visibility: visible;
    }
    .post-item.effect-04 .thumbnail:hover>a::after,
    .post-item.effect-01 .thumbnail:hover>a::after{
        visibility: visible;
        opacity: 1;
        filter: alpha(opacity=100);
    }
    .post-item.effect-02 .thumbnail img{
        display: inline-block;
        -webkit-transition: all 0.5s cubic-bezier(0.34,1.61,.7,1);
        transition: all 0.5s cubic-bezier(0.34,1.61,.7,1);
    }
    .post-item.effect-02 .thumbnail:hover img{
        -webkit-transform: scale(1.1) rotate(5deg);
        transform: scale(1.1) rotate(5deg);
    }
    .post-item.effect-03 .thumbnail>a:before,
    .post-item.effect-02 .thumbnail>a:before{
        content: '';
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.2);
        z-index: 4;
        -webkit-transition: all 0.5s cubic-bezier(0.34,1.61,.7,1);
        transition: all 0.5s cubic-bezier(0.34,1.61,.7,1);
        opacity: 0;
        filter: alpha(opacity=0);
        visibility: hidden;
    }
    .post-item.effect-03 .thumbnail:hover>a:before,
    .post-item.effect-02 .thumbnail:hover>a:before{
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible;
    }
    .post-item.effect-04 .thumbnail img,
    .post-item.effect-03 .thumbnail img{
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
    .post-item.effect-04 .thumbnail:hover img,
    .post-item.effect-03 .thumbnail:hover img{
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    .product-category.grid-style .products-list li{
        padding-left: 5px;
        padding-right: 5px;
    }
    .biolife-banner__grid.type-02 .media-contain::before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 0;
        background-image: linear-gradient(to top, #dd6b0c , transparent);
        z-index: 1;
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transition: all 950ms ease;
        -moz-transition: all 950ms ease;
        -ms-transition: all 950ms ease;
        -o-transition: all 950ms ease;
        transition: all 950ms ease;
    }
    .biolife-banner__grid.type-02.bn-elm-02 .media-contain::before{
        background-image: linear-gradient(to top, #05a503 , transparent);
    }
    .biolife-banner__grid.type-02.bn-elm-03 .media-contain::before{
        background-image: linear-gradient(to top, #5d6a96 , transparent);
    }
    .biolife-banner__grid.type-02.bn-elm-04 .media-contain::before{
        background-image: linear-gradient(to top, #f81c56 , transparent);
    }
    .biolife-banner__grid.type-02.bn-elm-05 .media-contain::before{
        background-image: linear-gradient(to top, #433a55 , transparent);
    }
    .biolife-banner__grid.type-02.bn-elm-06 .media-contain::before{
        background-image: linear-gradient(to top, #fd2046 , transparent);
    }
    .biolife-banner__grid.type-02:hover .media-contain::before{
        opacity: 0.5;
        filter: alpha(opacity=50);
        height: 110px;
    }
    .header-area.layout-01 .biolife-sticky-object:not(.pre-sticky) .biolife-cart-info{
        margin-top: 6px;
    }
    .header-area.layout-01 .biolife-sticky-object .primary-menu>ul{
        position: initial;
    }
    .header-area.layout-01 .biolife-sticky-object .primary-menu{
        margin-top: 16px;
        position: relative;
        margin-left: -30px;
        padding-left: 30px;
    }
    .header-area:not(.layout-01):not(.layout-02) .primary-menu>ul .wrap-megamenu{
        margin-left: -30px;
    }
    .header-area.layout-01 .biolife-cart-info .minicart-block{
        margin-left: 0;
    }
    .biolife-popup .modal-dialog{
        min-width: 770px;
        margin-top: 200px;
    }
    .biolife-title-box__bold-center .subtitle{
        margin: 0;
    }
    .biolife-title-box__bold-center .main-title{
        font-size: 40px;
        margin-top: 6px;
    }
    .biolife-title-box__icon-at-top-style .main-title{
        font-size: 40px;
        margin-top: 4px;
    }
    .header-area.layout-02 .mobile-search-content{
        display: block;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all 350ms ease;
        -moz-transition: all 350ms ease;
        -o-transition: all 350ms ease;
        transition: all 350ms ease;
    }
    .header-area.layout-02 .mobile-search .mobile-search-content{
        display: block;
    }
    body.open-mobile-search .header-area.layout-02  .mobile-search-content{
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    #sidebar.mobile-version{
        position: fixed;
        top: 0;
        left: -320px;
        width: 300px;
        height: 100vh;
        overflow: hidden;
    }
    .scale-effect.contain-product .product-thumb{
        overflow: hidden;
    }
    .scale-effect.contain-product .product-thumb .link-to-product>img{
        display: inline-block;
        -webkit-transition: transform 350ms ease;
        -moz-transition: transform 350ms ease;
        -ms-transition: transform 350ms ease;
        -o-transition: transform 350ms ease;
        transition: transform 350ms ease;
    }
    .scale-effect.contain-product:hover .product-thumb .link-to-product>img{
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1);
    }
    .biolife-banner__style-12 .banner-contain .media .bn-link img,
    .biolife-banner__style-11 .banner-contain .media .bn-link img{
        display: inline-block;
        -webkit-transition: transform 0.8s ease;
        -moz-transition: transform 0.8s ease;
        -ms-transition: transform 0.8s ease;
        -o-transition: transform 0.8s ease;
        transition: transform 0.8s ease;
    }
    .biolife-banner__style-12:hover .banner-contain .media .bn-link img,
    .biolife-banner__style-11:hover .banner-contain .media .bn-link img{
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1);
    }
    .slider-opt04__layout01.light-version .text-content{
        left: 38px;
    }
    .slider-opt04__layout01.light-version .text-content .third-line{
        margin-left: 8px;
    }
    .biolife-banner__promotion4 .position-1 img,
    .biolife-cat-box-item .cat-thumb img{
        display: inline-block;
        -webkit-transition: all 350ms ease;
        -moz-transition: all 350ms ease;
        -ms-transition: all 350ms ease;
        -o-transition: all 350ms ease;
        transition: all 350ms ease;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    .biolife-banner__promotion4 .position-1:hover img,
    .biolife-cat-box-item:hover .cat-thumb img{
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1);
    }
    .contain-product:not(.pr-detail-layout):not(.contain-product__right-info-layout):not(.contain-product__deal-layout):not(.none-overlay):not(.style-widget) .product-thumb{
        padding: 10px 10px 0 10px;
    }
    .advance-product-box_countdown .contain-product .product-thumb{
        padding: 11px 8px 0 !important;
    }
    .page-contain{
        position: relative;
    }
    .page-contain:not(.category-page){
        z-index: 10;
    }
    .page-contain #sidebar:not(.blog-sidebar){
        margin-top: -6px;
    }
    .page-contain.right-sidebar.category-page #main-content,
    .page-contain.left-sidebar.category-page #main-content{
        margin-top: -10px;
    }
    .page-contain.left-sidebar #main-content{
        float: right;
    }
    .biolife-carousel.products-list:not(.default-product-style){
        margin: -5px -15px;
    }
    .biolife-carousel.products-list:not(.default-product-style) .slick-list{
        padding: 5px;
    }
    .biolife-carousel.products-list.vertical-layout-02{
        margin: -15px;
    }
    .biolife-carousel.products-list.vertical-layout-02 .slick-list{
        padding: 15px;
    }
    .biolife-carousel.products-list:not(.default-product-style):hover{
        margin-bottom: -150px;
    }
    .biolife-carousel.products-list:not(.default-product-style):hover .slick-list{
        padding-bottom: 150px;
    }
    .contain-product.layout-02:hover,
    .contain-product.layout-default:hover{
        -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
        -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    }
    .header-search-bar.layout-01 .input-text{
        max-width: 450px;
    }
    .biolife-sticky-object.pre-sticky{
        position: fixed;
        left: 0;
        right: 0;
        top: -110%;
        -webkit-transition: all 1200ms ease;
        -moz-transition: all 1200ms ease;
        -o-transition: all 1200ms ease;
        transition: all 1200ms ease;
        background-color: #ffffff;
        z-index: 50;
        -webkit-box-shadow: 0 0 9px 0 rgba(0,0,0,.2);
        -moz-box-shadow: 0 0 9px 0 rgba(0,0,0,.2);
        box-shadow: 0 0 9px 0 rgba(0,0,0,.2);
        padding: 10px 0;
    }
    .biolife-sticky-object.pre-sticky.is-sticky{
        top: 0;
    }
    .header-area.layout-01 .biolife-sticky-object.pre-sticky .primary-menu{
        margin-top: 15px;
    }
    .contain-product.layout-default .info{
        position: relative;
    }
    .contain-product.layout-default .slide-down-box{
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
        -webkit-box-shadow: 0 5px 5px 0 rgba(0,0,0,0.2);
        -moz-box-shadow: 0 5px 5px 0 rgba(0,0,0,0.2);
        box-shadow: 0 5px 5px 0 rgba(0,0,0,0.2);
        position: absolute;
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        top: 100%;
        left: 0;
        right: 0;
        visibility: hidden;
        opacity: 0;
        filter: alpha(opacity=0);
        z-index: 10;
        background-color: #ffffff;
        padding-top: 11px;
        padding-bottom: 30px;
    }
    .contain-product.layout-default:hover .slide-down-box{
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible;
    }
    .biolife-nav.nav-86px{
        margin-top: 38px;
        margin-bottom: 33px;
    }
}

@media ( min-width: 1200px) {
    .lg-width-1170{
        width: 1170px;
    }
    .lg-width-900{
        width: 900px;
    }
    .lg-width-800{
        width: 800px;
    }
    .lg-margin-top-0{
        margin-top: 0;
    }
    .lg-margin-top-14px{
        margin-top: 14px;
    }
    .lg-margin-top-23px{
        margin-top: 23px;
    }
    .lg-margin-bottom-0{
        margin-bottom: 0;
    }
    .lg-margin-bottom-26px-im{
        margin-bottom: 26px !important;
    }
    .lg-margin-top-30px{
        margin-top: 30px;
    }
    .lg-margin-bottom-72{
        margin-bottom: 72px;
    }
    .lg-padding-left-23{
        padding-left: 23px;
    }
    .lg-padding-left-25{
        padding-left: 25px;
    }
    .lg-padding-left-50{
        padding-left: 50px;
    }
/*--------------------------------*/
    .md-boder-left-30{
        position: relative;
    }
    .md-boder-left-30::before{
        content: '';
        width: 2px;
        display: block;
        position: absolute;
        top: 0;
        left: -31px;
        bottom: 0;
        background-color: #e6e6e6;
    }
    .col-lg-2>.contain-product.layout-default .slide-down-box .buttons{
        padding: 0;
    }
    .col-lg-2>.contain-product.layout-default .slide-down-box .buttons .add-to-cart-btn{
        padding: 10px;
    }
    .col-lg-12>.pr-detail-layout .info .categories,
    .col-lg-12>.pr-detail-layout .info .product-title,
    .col-lg-12>.pr-detail-layout .info .excerpt,
    .col-lg-12>.pr-detail-layout .info .price,
    .col-lg-12>.pr-detail-layout .info .buttons{
        max-width: 100%;
    }
    .sumary-product .biolife-countdown>span+span{
        margin-left: 20px;
    }
    .sumary-product .product-attribute .price+div:not(.product-atts){
        max-width: 368px;
    }
    .sumary-product .product-attribute .price+div.grouped-product-list{
        width: calc( 100% - 30px);
        max-width: inherit;
    }
    .content-404 .button:hover:before {
        width: 16px;
        opacity: 1;
    }
    .biolife-banner__style-15 .bn-link::before,
    .biolife-banner__style-01 .bn-link::before{
        content: '';
        background-color: rgba(0,0,0,0.2);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: block;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        visibility: hidden;
        opacity: 0;
        filter: alpha(opacity=0);
    }
    .biolife-banner__style-15:hover .bn-link::before,
    .biolife-banner__style-01:hover .bn-link::before{
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        visibility: visible;
        opacity: 1;
        filter: alpha(opacity=100);
    }
    .biolife-banner__style-15 .text-content .fourth-line,
    .biolife-banner__style-15 .text-content .third-line,
    .biolife-banner__style-15 .text-content .second-line,
    .biolife-banner__style-15 .text-content .first-line,
    .biolife-banner__style-01 .text-content .fourth-line,
    .biolife-banner__style-01 .text-content .third-line,
    .biolife-banner__style-01 .text-content .second-line,
    .biolife-banner__style-01 .text-content .first-line{
        -webkit-transition: all 0.3s ease 0.1s;
        -moz-transition: all 0.3s ease 0.1s;
        -ms-transition: all 0.3s ease 0.1s;
        -o-transition: all 0.3s ease 0.1s;
        transition: all 0.3s ease 0.1s;
    }
    .biolife-banner__style-15:hover .text-content .fourth-line,
    .biolife-banner__style-15:hover .text-content .third-line,
    .biolife-banner__style-15:hover .text-content .second-line,
    .biolife-banner__style-15:hover .text-content .first-line,
    .biolife-banner__style-01:hover .text-content .fourth-line,
    .biolife-banner__style-01:hover .text-content .third-line,
    .biolife-banner__style-01:hover .text-content .second-line,
    .biolife-banner__style-01:hover .text-content .first-line{
        color: #ffffff !important;
    }
    .contain-product__deal-layout .slide-down-box .buttons .btn:not(.add-to-cart-btn){
        padding: 6px 14px;
    }
    .biolife-service__type01 .services-list li{
        text-align: center;
        padding-bottom: 11px;
    }
    .biolife-service__type01 .services-list li:hover .biolife-icon{
        animation-duration: 2.5s;
        -webkit-animation-duration: 2.5s;
        animation-delay: 0.1s;
        -webkit-animation-delay: 0.1s;
        animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
        animation-name: bounce;
        -webkit-animation-name: bounce;
    }
    .service-inner:not(.style-02){
        width: auto;
        text-align: left;
    }
    .service-inner:not(.style-02) .srv-name{
        width: auto;
        display: block;
    }
    .header-area.layout-03 .header-search-bar.layout-01 .input-text{
        max-width: 500px;
    }
    .slider-opt03__layout01.mode-03.black-color .text-content .first-line{
        color: #666666;
    }
    .slider-opt03__layout01.mode-03.black-color .text-content .second-line{
        color: #222222;
    }
    .slider-opt03__layout01.mode-03.black-color .text-content .third-line{
        color: #444444;
    }
    .transparent-effect img{
        -webkit-transition: opacity 350ms ease;
        -moz-transition: opacity 350ms ease;
        -ms-transition: opacity 350ms ease;
        -o-transition: opacity 350ms ease;
        transition: opacity 350ms ease;
    }
    .transparent-effect:not(:hover) img{
        opacity: 0.5;
        filter: alpha(opacity=50);
    }
    .biolife-banner__promotion2.advance .media{
        left: -37px;
    }
    .contain-product__right-info-layout3{
        -webkit-transition: box-shadow 500ms ease;
        -moz-transition: box-shadow 500ms ease;
        -o-transition: box-shadow 500ms ease;
        transition: box-shadow 500ms ease;
        margin-left: -10px;
    }
    .contain-product__right-info-layout3 .info .buttons{
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
        -webkit-box-shadow: 0 5px 5px 0 rgba(0,0,0,0.2);
        -moz-box-shadow: 0 5px 5px 0 rgba(0,0,0,0.2);
        box-shadow: 0 5px 5px 0 rgba(0,0,0,0.2);
        position: absolute;
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        top: 100%;
        left: 0;
        right: 0;
        visibility: hidden;
        opacity: 0;
        filter: alpha(opacity=0);
        z-index: 10;
    }
    .contain-product__right-info-layout3:hover{
        -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
        -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    }
    .contain-product__right-info-layout3:hover .info .buttons{
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible;
    }
    .footer-midle-pst.v2{
        margin-top: 59px;
    }
}

/*==============================*/
@media ( min-width: 768px) and ( max-width: 1199px) {
    .tab-head__icon-top-layout .biolife-icon{
        font-size: 35px;
    }
    .tab-head__icon-top-layout a{
        font-size: 20px;
    }
    .contain-product__right-info-layout3 .buttons .add-to-cart-btn{
        min-width: auto;
        padding: 11px 23px;
    }
    .contain-product__right-info-layout3 .info{
        padding-left: 20px;
    }
    .contain-product__right-info-layout3 .product-thumb{
        width: 200px !important;
    }
    .products-list.vertical-layout-02 li{
        margin-top: 30px;
    }
}

@media ( min-width: 992px) and ( max-width: 1199px) {
    .biolife-banner__grid .media-contain{
        height: 256px;
    }
    .biolife-banner__grid .media-contain.media-01,
    .biolife-banner__grid .media-contain.media-06{
        height: 512px;
    }
    .md-possition-relative{
        position: relative;
    }
    .md-possition-initial{
        position: static;
    }
    .header-area.layout-01 .biolife-sticky-object .primary-menu{
        position: static;
    }
    .shpcart-subtotal-block .btn-checkout .btn{
        max-width: 175px;
    }
    .contain-product__right-info-layout .product-thumb{
        width: 40%;
    }
    .post-item.style-left-info .thumbnail{
        width: 320px;
    }
    .header-area.layout-01 .primary-menu>ul>li+li{
        margin-left: 25px;
    }
    .slider-opt05{
        height: 508px;
    }
    .biolife-banner__style-12 .banner-contain .media,
    .biolife-banner__style-11 .banner-contain .media{
        width: 200px;
    }
    .biolife-banner__style-11 .text4 span,
    .biolife-banner__style-11 .text4,
    .biolife-banner__style-11 .text2{
        font-size: 27px;
    }
    .minicart-block.layout-02 .icon-contain .sub-total,
    .minicart-block.layout-02 .icon-contain .btn-to-cart{
        display: none;
    }
    .minicart-block.layout-02 .icon-contain .span-index{
        border-right: 2px solid #e6e6e6;
        border-radius: 50%;
        padding: 0 10px;
        width: 45px;
    }
}

@media ( min-width: 768px) and ( max-width: 991px) {
    .sumary-product .biolife-countdown>span+span{
        margin-left: 20px;
    }
    .sumary-product .product-attribute{
        padding-top: 0;
    }
    .biolife-banner__style-01 .text-content{
        padding: 15px;
        background-color: rgba(255,255,255,0.8);
        margin-top: -15px;
        margin-right: 15px;
    }
    .biolife-banner__style-15 .text-content{
        right: 15px;
    }
    .slider-opt03__layout02 .text-content{
        left: 20%;
    }
    .contain-product__right-info-layout .product-thumb{
        width: 40%;
    }
    .top-functions-area{
        margin-top: 30px;
        padding-bottom: 20px;
    }
    .advance-product-box_countdown .biolife-countdown>span+span{
        margin-left: 4px;
    }
}

@media ( min-width: 480px) and ( max-width: 767px){
    .slider-opt04__layout01.light-version .text-content{
        background-color: transparent;
        padding: 20px 10px;
        left: 30px;
        right: auto;
    }
    .slick-slide.slick-active .slider-opt04__layout01.light-version .text-content{
        background-color: rgba(255, 255, 255, 0.8);
        -webkit-transition: background-color 1.2s ease;
        -moz-transition: background-color 1.2s ease;
        -ms-transition: background-color 1.2s ease;
        -o-transition: background-color 1.2s ease;
        transition: background-color 1.2s ease;
    }
}

@media ( max-width: 1199px) {
    .products-list.biolife-carousel.nav-none-after-1k2 .slick-arrow.biolife-icon{
        display: none !important;
    }
}

@media ( max-width: 1100px) {
    .biolife-carousel.nav-none-on-mobile.nav-center-bold .slick-arrow.prev,
    .biolife-carousel.nav-none-on-mobile.nav-center-bold .slick-arrow.next,
    .biolife-carousel.nav-none-on-mobile.nav-center .slick-arrow.prev,
    .biolife-carousel.nav-none-on-mobile.nav-center .slick-arrow.next,
    .biolife-carousel.nav-none-on-mobile.nav-top-right .slick-arrow.prev,
    .biolife-carousel.nav-none-on-mobile.nav-top-right .slick-arrow.next,
    .biolife-carousel.nav-none-on-mobile.nav-center-02 .slick-arrow.prev,
    .biolife-carousel.nav-none-on-mobile.nav-center-02 .slick-arrow.next,
    .biolife-carousel.nav-none-on-mobile.nav-center-03 .slick-arrow.prev,
    .biolife-carousel.nav-none-on-mobile.nav-center-03 .slick-arrow.next{
        display: none !important;
    }
}
