ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.block-title {
  border-radius: 0 !important;
}

.slider-opt04__layout01 .media {
  height: 420px !important;
}

.col-third {
  width: 33%;
}

.col-fourth {
  width: 25%;
}

.py-20 {
  padding:20px 0;
}

.py-40 {
  padding:40px 0;
}

.py-60 {
  padding:60px 0;
}

.my-2O {
  margin: 20px 0;
}

.my-4O {
  margin: 40px 0;
}

.col-sm-12 {
  display: inline-block;
}

ul.products-list {
  padding: 0;
  display: flex;
  flex-flow: row wrap;
}

.banner-contain {
  height: auto !important;
}

a.link-to-product img {
  object-fit: cover;
  object-position: center;
}

.nav-menu {
  display: flex;
  flex-flow: row wrap;
  padding: 10px 0;
}

.nav-menu .menu-item {
  margin-right: 15px;
}

.nav-menu .menu-item:hover a {
  color: orange;
}

.nav-menu .menu-item a {
  color: #222222; 
}

.none-box-shadow .vertical-category-block > .wrap-menu {
  display: none;
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 20px;
  cursor: pointer;
  font-size: 16px;
}

.single-product {
  padding: 30px 0;
}

.sumary-product .product-attribute .category {
  margin: 0 !important;
}

.sumary-product .media .slider-nav li img {
  object-fit: cover;
}

.media .biolife-carousel > li {
  display: inline-block !important;
}

#top-section .nav-menu .menu-item {
  position: relative;
}

#top-section .nav-menu .menu-item > button {
  background-color: transparent;
  color: initial;
}

#top-section .nav-menu .menu-item:hover .sub-menu {
  display: block;
}

#top-section .nav-menu .sub-menu {
  min-width: 200px;
  display: none;
  position: absolute;
  left: 0;
  background-color: white;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  z-index: 11;
}

#top-section .nav-menu .sub-menu ul {
  padding: 10px 0;
  width: 100%;
}

#top-section .nav-menu .sub-menu li > a {
  padding: 10px 20px;
}

.souscriptions .slide-down-box {
  visibility: visible !important;
  position: relative !important;
  transform: translateY(0) !important;
  opacity: 1 !important;
}

.souscriptions .contain-product.layout-default {
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
}

.modal-body {
  display: inline-block;
  width: 100%;
}

.modal .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 0;
  border: none;
  font-size: 20px;
  border-radius: 100%;
}

.d-flex {
  display: flex !important;
  flex-flow: row wrap;
  align-items: center;
}

.order-summary .cart-list .cart-item .info {
  padding-top: 10px !important;
}

#mobile-menu {
  display: none;
  position: fixed;
  width: 300px;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: orange;
  height: 100%;
}

#mobile-menu .nav-menu li {
  display: block;
}

#mobile-menu > .container {
  width: 100%;
}

#mobile-menu .nav-menu {
  padding: 0px 30px;
}

#mobile-menu > .container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

#mobile-menu .nav-menu > li {
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(225, 225, 225, 0.2);
}

#mobile-menu .nav-menu .menu-item li {
  margin: 3px 0;
}

#mobile-menu .nav-menu .menu-item li a,
#mobile-menu .nav-menu .menu-item .btn {
  color: white;
  padding: 1px 0 !important;
  display: inline-block;
  text-align: left;
  width: 100%;
}

#mobile-menu .nav-menu .menu-item .btn {
  background-color: transparent;
  padding: 0;
  opacity: 0.6;
}
